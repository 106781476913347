import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Select, MenuItem } from "@mui/material";
import "./examresult.css";
import { useTranslation } from "react-i18next";

function ExamResult() {
  const [rows, setRows] = useState([]);
  const [token, setToken] = useState("");
  const [studentid, setStudentId] = useState("");
  const [fromdate, setFromDate] = useState("");
  const [todate, setToDate] = useState("");
  const [allSubject, setAllSubject] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    const tok = sessionStorage.getItem("token");
    const userId = sessionStorage.getItem("userId");
    const sub = localStorage.getItem("sub");

    if(selectedSubjectId == 0){
      const currentDate = new Date();
      const fifteenDaysAgo = new Date(
        currentDate.getTime() - 5 * 24 * 60 * 60 * 1000 // Change from 30 to 15
      );
  
      // Format the dates as "YYYY-MM-DD"
      const currentFormatted = currentDate.toISOString().split("T")[0];
      const fifteenDaysAgoFormatted = fifteenDaysAgo.toISOString().split("T")[0];
  
      setFromDate(fifteenDaysAgoFormatted); // Change to use fifteenDaysAgoFormatted
      setToDate(currentFormatted);
      Tables(tok, fifteenDaysAgoFormatted, currentFormatted, selectedSubjectId,userId);
    }

    
    if (tok && userId) {
      setToken(tok);
      setStudentId(userId);
      // dateFormat(); // Call dateFormat to set default date range
      getSubject(tok);
    }
    // for media query
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // styles for responsive
  const isMobile = screenWidth <= 768;
  const columnStyle = {
    flexBasis: "33.33%", // Set each column to occupy one-third of the row (for the tab view)
    padding: "15px 15px", // Add some horizontal padding
  };

  const mobileColumnStyle = {
    flexBasis: "100%", // Set each column to occupy the full width (for the mobile view)
    padding: "15px 15px", // Add some horizontal padding
  };

  // Add selectedSubjectId to the dependency array
  // useEffect(() => {
  //   // Call Tables whenever token, fromdate, todate, or selectedSubjectId changes
  //   if (token && fromdate && todate && selectedSubjectId) {
  //     Tables(token, fromdate, todate, selectedSubjectId);
  //   }
  // }, [token, fromdate, todate, selectedSubjectId,rows]);

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const currentDate = new Date().toISOString().split("T")[0];



  const Tables = async (token, fromdate, todate, selectedSubjectId,u) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      SubjectId: parseInt(selectedSubjectId),
      StudentId: parseInt(u),
      FromDate: fromdate,
      ToDate: todate,
    };
    try {
      const { data } = await axios.post(
        `${window.EXAMURL}${window.PERFORMANCETABLE}`,
        d,
        { headers: h }
      );
      if (data) {
        console.log(data);
        setRows(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubject = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.SUBJECT}`);
      if (data) {
        setAllSubject(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dateFormat = () => {
    const currentDate = new Date();
    const fifteenDaysAgo = new Date(
      currentDate.getTime() - 5 * 24 * 60 * 60 * 1000 // Change from 30 to 15
    );

    // Format the dates as "YYYY-MM-DD"
    const currentFormatted = currentDate.toISOString().split("T")[0];
    const fifteenDaysAgoFormatted = fifteenDaysAgo.toISOString().split("T")[0];

    setFromDate(fifteenDaysAgoFormatted); // Change to use fifteenDaysAgoFormatted
    setToDate(currentFormatted);
  };
  const inputFieldStyle = {
    height: "58px",
  };

  return (
    <div
      className="container add-listings-box educational"
      style={{ height: "" }}
    >
      <h3>{translation("Exams.ExamResult")}</h3>

      <p>
        <span style={{ color: "red" }}>*</span>
        {translation("Exams.ExamResultpara")}
      </p>
      <div className="form-group d-flex row">
        <div
          className={`col-${isMobile ? "12" : "4"}`}
          style={isMobile ? mobileColumnStyle : columnStyle}
        >
          <p>{translation("Exams.Examdatefrom")}</p>
          <TextField
            type="date"
            value={fromdate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ ...inputFieldStyle, padding: 10 }} // Use the inputFieldStyle here
            fullWidth
          />
        </div>
        <div
          className={`col-${isMobile ? "12" : "4"}`}
          style={isMobile ? mobileColumnStyle : columnStyle}
        >
          <p>{translation("Exams.Examdateto")}</p>
          <TextField
            type="date"
            value={todate}
            style={{marginTop:10}}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: fromdate, // Set the minimum date to the current date
            }}
            fullWidth
          />
        </div>
       <div
      className={`col-${isMobile ? "12" : "4"}`}
      id="tableresponse"
      style={isMobile ? mobileColumnStyle : columnStyle}
    >
      <p>{translation("Exams.MainExamsub")}</p>
      <Select
        className="dashbaord-category-select"
        id="ExamSubject"
        style={{ height: "58px" }}
        fullWidth
        value={selectedSubjectId}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedSubjectId(value);
          Tables(token, fromdate, todate, value,studentid);
           // Update the state with the selected value
        }}
      >
        <MenuItem value="0">All Subject</MenuItem>

        {allSubject?.map((item, index) => (
          <MenuItem value={item.subjectId} key={index}>
            {item.subjectName}
          </MenuItem>
        ))}
      </Select>
    </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          Tables(token, fromdate, todate, selectedSubjectId);
        }}
      >
        <table>
          <thead>
            <tr>
              <th>{translation("Exams.ExamDate")}</th>
              <th>{translation("Exams.MainExamsub")}</th>
              <th>{translation("Exams.MainExamchap")}</th>
              <th>{translation("Exams.MainExamnoof")}</th>
              <th> {translation("Exams.ExambackWrong")}</th>
              <th>{translation("Exams.Exammarks")}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                <td data-label={translation("Exams.ExamDate")}>
                  {row.rptDate.substring(0, 10)}
                </td>
                <td data-label={translation("Exams.MainExamsub")}>
                  {row.subjectName}
                </td>
                <td data-label={translation("Exams.MainExamchap")}>
                  {row.chapterName}
                </td>
                <td data-label={translation("Exams.MainExamnoof")}>
                  {row.noOfQuestion}
                </td>
                <td data-label={translation("Exams.ExambackWrong")}>
                  {row.wrongAnswer}
                </td>
                <td data-label={translation("Exams.Exammarks")}>{row.mark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default ExamResult;
