import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import exmicon from "../../../../assets/Images/Icons/exam.png";
import percentage from "../../../../assets/Images/Icons/percentage.png";
import questions from "../../../../assets/Images/Icons/questions.png";
import LoadingScreen from "../../Loading/Loadingscreen";
import "./examresult.css";
import { useTranslation } from "react-i18next";
import QaCharts from "../../MyPerformance/ExamResult/QaCharts";


function Performance() {
  const [performance, setPerformance] = useState("");
  const [token, setToken] = useState();
  const [studentid, setStudentId] = useState("");
  const [isloading,setloading] =useState(false)

  useEffect(() => {
    const tok = sessionStorage.getItem("token");

    const userId = sessionStorage.getItem("userId");
    if (tok !== null || tok !== undefined) {
      setToken(tok);
      setStudentId(userId);
      getperformance(tok);
      //  setSubjectId()
      //  setFormDate()
      //  setToDate()
    }
  }, [token, studentid]);


  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  // my performance API
  const getperformance = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentid),
    };
    console.log(d);
    try {
      setloading(true)
      const { data } = await axios.post(
        `${window.EXAMURL}${window.MYPERFORMANCE}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data) {
        console.log(data);
        setPerformance(data);
      setloading(false)

      }
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <div>
      {isloading ? (
         <LoadingScreen />
      ):( <div style={{height:"100vh"}}>
      
        <div className="container add-listings-box educational mt-5 mb-3 ">
        <h3>{translation("navbar.myPerformance")}</h3>  
          <div className="row">
            <div className="col-lg-3">
              <div className="card p-3 mb-2 custom-card-shine custom-card-purple">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon">
                      <img src={exmicon} />
                    </div>
                    <div className="ms-2 c-details">
                      <h6 className="mb-0" style={{color:"#fff"}}>{translation("Exams.MainExamcount")}</h6>
                    </div>
                  </div>
                  <div className="badge">
                    {" "}
                    {/* <span>Exams</span>{" "} */}
                  </div>
                </div>
                <div className="" id="performtitle">
                  {performance &&
                    performance.map((item) => <h6>{item.totalNoOfExm}</h6>)}
                 
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3 mb-2 custom-card-shine custom-card-green">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon">
                      <img src={percentage} />
                    </div>
                    <div className="ms-2 c-details">
                      <h6 className="mb-0" style={{color:"#fff"}}> {translation("Exams.MainExampercentage")} </h6>
                    </div>
                  </div>
                  <div className="badge"> {/* <span>Product</span>{" "} */}</div>
                </div>
                <div className="" id="performtitle">
                  {performance &&
                    performance.map((item) => <h6>{item.percentage}</h6>)}
                
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3 mb-2 custom-card-shine custom-card-orange">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon">
                      <img src={questions} />
                    </div>
                    <div className="ms-2 c-details">
                      <h6 className="mb-0" style={{color:"#fff"}}>{translation("Exams.MainExamnoof")}</h6>
                    </div>
                  </div>
                  <div className="badge"> {/* <span>Design</span>{" "} */}</div>
                </div>
                <div className="" id="performtitle">
                  {performance &&
                    performance.map((item) => <h6>{item.totalNoOfQ}</h6>)}
                
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3 mb-2 custom-card-shine custom-card-blue">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon">
                      <img src={exmicon} />
                    </div>
                    <div className="ms-2 c-details">
                      <h6 className="mb-0" style={{color:"#fff"}}>{translation("Exams.ExambackCorrect1")}</h6> 
                    </div>
                  </div>
                  <div className="badge"> {/* <span>Design</span>{" "} */}</div>
                </div>
                <div className="" id="performtitle">
                  {performance &&
                    performance.map((item) => <h6>{item.totalCrtAns}</h6>)}
                 
                </div>
              </div>
            </div>
          </div>
          <QaCharts/>
        </div>
      </div>)}
   
    </div>
  );
}

export default Performance;
