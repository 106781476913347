import React from "react";
import Slider from "react-slick";
import Testimonial2 from "../../../assets/Images/Testimonial/Testimonial2.png";
import Testimonial3 from "../../../assets/Images/Testimonial/Testimonial3.png";
import Testimonial4 from "../../../assets/Images/Testimonial/Testimonial4.png";
import Testimonial5 from "../../../assets/Images/Testimonial/user.png";

function PeopleSay() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 1500,
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">
          <div className="container">
            <div className="titleFont text-center">
              <h3>What People Say</h3>
              <h2>What Our Client Said</h2>
            </div>
            <Slider {...settings}>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    Exam Mate has been a game-changer for my child's academic
                    journey. The platform offers  practice tests that have significantly improved my child's
                    understanding and performance. I highly recommend Exam Mate
                    to all students looking for website that gives mock test to
                    prepare for examinations.
                  </p>
                  <h3>MuthuKumar</h3>
                  <h3>Chennai</h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    Exam Mate website has truly transformed the way my child
                    learns and prepares for exams. The ability to track
                    progress, has made a world of difference. The website offers
                    a wide range of subjects and topics, ensuring my child gets
                    the support they need in every area. Thank you, Exam Mate
                    website, for helping my child reach their full potential!"
                  </p>
                  <h3> Subramanian ,</h3>
                  <h3>Tenkasi</h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    ExamMate has been a massive help for my exam preparation!
                    The progress tracking feature helps me stay on top of my
                    learning goals. I've seen a significant improvement in
                    my exam scores since using ExamMate.
                  </p>
                  <h3>
                    A.Karthik(12th),<br></br> S/O Annamalai,{" "}
                  </h3>
                  <h3> PSBB School, Chennai</h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    As a parent, finding the right educational resources for my
                    child can be overwhelming. However, Exam Mate website has
                    simplified the process by providing a one-stop solution for
                    all their exam preparation needs. The website offers practice tests and tracks their process, Ensuring
                    my child receives a well-rounded education. Exam Mate
                    website is a must-have for any parent looking to support
                    their child's academic success.
                  </p>
                  <h3>Manikandan</h3>
                  <h3> Madurai</h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    The ExamMate has made my exam preparation simple and
                    efficient. The progress tracking feature not only keeps me
                    motivated but also helps me identify my weak areas.The practice tests helps to score good grades in school examinations. Highly recommend it!
                  </p>
                  <h3>
                    S.Venu Seenivasan(12th),<br></br>S/O K.Selvam,{" "}
                  </h3>
                  <h3> HNUC Hr. Sec. School, Puliangudi</h3>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    Exam Mate website has exceeded my expectations in every way
                    possible. The website's 
                    mock tests have played a crucial role in my child's exam
                    success. The platform's user-friendly interface and
                    intuitive navigation make it easy for my child to access the
                    resources they need, whenever they need them. I couldn't be
                    happier with Exam Mate website!"
                  </p>
                  <h3>Sakthi</h3>
                  <h3>Trichy</h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    As a busy student, time is of the essence, and ExamMate
                    understands that. The progress tracking feature lets me
                    optimize my study time effectively.The mock test really helps  for my exams and it is a great way
                    to reinforce my learning.
                  </p>
                  <h3>
                    V.Nigilesh ,<br></br>S/O vadivel,{" "}
                  </h3>
                  <h3>
                    {" "}
                    Velammal matric higher secondary school mogappair chennai{" "}
                  </h3>
                  {/* <h6>Web Designer</h6> */}
                </div>
              </div>
              {/* <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                /> */}
                {/* <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    Exam Mate website has exceeded my expectations in every way
                    possible. The website's comprehensive study materials and
                    mock tests have played a crucial role in my child's exam
                    success. The platform's user-friendly interface and
                    intuitive navigation make it easy for my child to access the
                    resources they need, whenever they need them. I couldn't be
                    happier with Exam Mate website!"
                  </p>
                  <h3>Vinay</h3>
                  <h3>Coimbatore</h3>
                </div> */}
              {/* </div> */}
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                    ExamMate is a lifesaver for anyone who wants to excel in
                    their exams. The progress tracking feature provides a clear
                    picture of my performance and the website very
                    easy to use. It's boosted my confidence and results.
                  </p>
                  <h3>
                    Santhosh Kumar. R ,<br></br>S/O Ramalingam,{" "}
                  </h3>
                  <h3> Govt. Higher secondary school MGR nagar Chennai</h3>
                </div>
              </div>
              <div className="peopleSay text-center">
                <img
                  className="img-fluid mx-auto"
                  alt="PeopleSay"
                  src={Testimonial5}
                  style={{ height: 100 }}
                />
                <div className="peopleDetails">
                  <p style={{ textAlign: "justify" }}>
                  I was pleasantly surprised by ExamMate's online Assessment platform. The progress tracking feature allows me to easily monitor my advancements. It's an all-inclusive solution for effective exam preparation.
                  </p>
                  <h3>
                  Ashwin. S ,<br></br>S/O Sivasangaralingam{" "}
                  </h3>
                  <h3> Govt. Higher secondary school
MGR nagar, Chennai</h3>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default PeopleSay;
