import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
// import AboutSlider from "./AboutSlider";
import ab2 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";

function HomeFAQ() {
  return (
    <>
      <div className="container">
          <div className="spacing">
        <Row className="homeFAQRow ">
          <Col xl={6} lg={6} md={6}>
            <div className="titleFont">
              <h3>The ExamMate Feature </h3>
              <h2>Why Choose The ExamMate</h2>
            </div>
            <div className="homeFAQ"> 
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                What is the purpose of theexammate platform? 
                </Accordion.Header>
                <Accordion.Body>
                Our self-assessment platform is designed to facilitate online assessments and examinations, allowing students to prepare better for their exams. 
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                How do students access the exams? 
                </Accordion.Header>
                <Accordion.Body>
                Students can create an account on theexammate.com and access subject wise chapter wise exams
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                What happens if a student faces technical issues during the exam? 
                </Accordion.Header>
                <Accordion.Body>
                We have a dedicated technical support team available via chat or email to assist students with any technical difficulties they may encounter during the exam. Students should reach out immediately if they experience any issues.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                Is student performance data available for analysis?
                </Accordion.Header>
                <Accordion.Body>
                Yes, our platform provides comprehensive performance analytics and reports. Parents can analyse the students’ performance, identify strengths and weaknesses, and make informed decisions to enhance learning.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                How secure is student and educator data on the platform? 
                </Accordion.Header>
                <Accordion.Body>
                Data security is a top priority. We employ robust encryption protocols and adhere to strict privacy standards to safeguard all user data. Our platform is regularly audited to ensure compliance with data protection regulations.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6}>
            {/* <AboutSlider/> */}
            <img className="img-fluid mx-auto" alt="AboutUs" src={ab2} />
          </Col>
        </Row>
      </div>
      </div>
    </>
  );
}

export default HomeFAQ;
