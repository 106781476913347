import "./pdf.css";
import React, { useState } from "react";
import englishPDF from "../../../assets/pdf/CBSE/12/12th-english.pdf";
import hindiPDF from "../../../assets/pdf/CBSE/12/12th-hindi.pdf";
import Maths from "../../../assets/pdf/CBSE/12/12th-maths.pdf";
import biology from "../../../assets/pdf/CBSE/12/12th-biology.pdf";
// import physicaleducation from '../../../assets/pdf/CBSE/12/12th-phy-edu.pdf';
import sanskrit from "../../../assets/pdf/CBSE/12/12th-sanskrit.pdf";
import pschycology from "../../../assets/pdf/CBSE/12/12th-psycology.pdf";
import sociology from "../../../assets/pdf/CBSE/12/12th-sociology.pdf";
import urudu from "../../../assets/pdf/CBSE/12/12th-urudu.pdf";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Link ,useNavigate} from "react-router-dom";
//

function PdfComponenttwel() {
  const [selectedSubject, setSelectedSubject] = useState(englishPDF);


const Navigate =useNavigate()

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  };

  return (
    <>
    <button onClick={(e) => Navigate("/Studymaterials")}>  <KeyboardDoubleArrowLeftIcon />
          Back</button>
    <div>
      <div className="pdf-viewer d-flex">
        <div className="subjects">
      
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(englishPDF)}
          >
            English
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(hindiPDF)}
          >
            Hindi
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(Maths)}
          >
            Maths
          </button>
          {/* <button className="subject-btn" onClick={() => handleSubjectClick(physicaleducation)}>PE</button> */}
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(sanskrit)}
          >
            Sanskrit
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(pschycology)}
          >
            pschycology
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(sociology)}
          >
            sociology
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(urudu)}
          >
            Urudu
          </button>
          <button
            className="subject-btn"
            onClick={() => handleSubjectClick(biology)}
          >
            biology
          </button>
        </div>

        <div className="pdf-container">
          {selectedSubject && (
            <iframe
              src={selectedSubject}
              title="PDF Viewer"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </div>
      </div>
    </div>
    </>
  );
}

export default PdfComponenttwel;
