import React from "react";
import {
  Routes,
  Route,
  Router,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import { WelcomeScreen } from "../Welcome/Home";
import Portfolio from "../components/pages/Portfolio/Portfolio";
import { Contact } from "../components/pages/Contact/Contact";
import Home from "../components/pages/HomePage/Home";
import Service from "../components/pages/Service/Service";
import FAQ from "../components/pages/FAQ/FAQ";
import Planing from "../components/pages/Planing/Planing";
import AboutUsMenu from "../components/pages/AboutUs/AboutUsMenu";
import SignUp from "../components/SignUp/SignUp";
import Blog from "../components/pages/Blog/Blog";
import { BlogPage } from "../components/pages/Blog/BlogPage";
// import FormSignIn from "../components/SignIn/SignIn";
import Login from "../components/pages/Login/Login";
import StudentDashBoard from "../components/pages/StudentDashBoard/Studentdashboard";
import SampleExam from "../components/pages/SampleExam/SampleExam";
import SampleResult from "../components/pages/SampleResult/SampleResult";
import ExamRoom from "../components/pages/ExamRoom/ExamRoom";
import Header from "../components/header/Header";
import { useState, useEffect } from "react";
import ExamRoomResult from "../components/pages/ExamRoomResult/ExamRoomResult";
import ExamResult from "../components/pages/MyPerformance/ExamResult/ExamResult";
import SubjectWiseResult from "../components/pages/MyPerformance/SubjectWiseResult/SubjectWiseResult";
import MyCertificate from "../components/pages/MyPerformance/MyCertificate/MyCertificate";
import ChangePassword from "../components/pages/My Account/ChangePassword";
import MyProfile from "../components/pages/My Account/MyProfile";
import StudentInformation from "../components/pages/MyProfile/StudentInformation";
import EducationalInformation from "../components/pages/MyProfile/EducationalInformation";
import Subscription from "../components/pages/MyProfile/Subscription";
import ExamRoom2 from "../components/pages/ExamRoom2/ExamRoom2";
import StudentInfo from "../components/pages/MyProfile/StudentInfo";
import SelfAssesment from "../components/pages/Selfassesment/SelfAssesment";
import SelfQuestion from "../components/pages/Selfassesment/SelfQuestion";
import MainExam from "../components/pages/ExamRoomResult/MainExam";
import Pdf from "../components/pages/PdfVIewer/Pdf";
import Footer from "../components/Footer/Footer";
import PricingPage from "../components/pages/Pricing/PricingPage";
import Terms from "../components/pages/Terms/Terms";
import PrivacyPolicy from "../components/pages/Terms/PrivacyPolicy";
import ResetPassword from "../components/pages/ResetPassword/ResetPassword";
import Performance from "../components/pages/MyPerformance/ExamResult/Performance";
// const Footer = React.lazy(() => import ())
import ExamResultTable from "../components/pages/MyPerformance/ExamResult/ExamResultTable";
import Resultchart from "../components/pages/MyPerformance/ExamResult/Resultchart";
import Studymaterial from "../components/pages/PdfVIewer/Studymaterial";
import PdfComponent from "../components/pages/PdfVIewer/PdfComponent";
import PdfComponentten from "../components/pages/PdfVIewer/PdfComponentten";
import PdfComponentele from "../components/pages/PdfVIewer/PdfComponentele";
import PdfComponenttwel from "../components/pages/PdfVIewer/PdfComponenttwel";
import Sitemap from "../components/pages/Terms/Sitemap";
import SchoolAdminDashboard from "../components/pages/MyPerformance/ExamResult/SchoolAdminDashboard";
import LoadingScreen from "../components/pages/Loading/Loadingscreen";
import SubsPricing from "../components/pages/MyProfile/SubsPricing";
import TabNavigation from "../components/pages/TabNavigation/TabNavigation";
import RefundPolicy from "../components/pages/Terms/RefundPolicy";
import OurPricing from "../components/pages/HomePage/OurPricing";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import Layout from "../components/pages/Dashboard/Layout";
import BannerForm from "../components/pages/HomePage/BannerForm"
import Olympiad from "../components/pages/Olympiad/Olympiad";

export default function Index() {
  const [token, setToken] = useState("");
  const [isRender, setRender] = useState();
  const [nav, SetNav] = useState(false);
  const [showNav, SetShownav] = useState(false);
  const [g, setG] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  useEffect(() => {
    let tok = sessionStorage.getItem("token");
    if (tok != null && tok != undefined) {
      setToken(tok);
    }
    
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, [isRender, location, g]);

  const render = () => {
    setRender(!isRender);
  };
  const update = () => {
    setG(!g);
  };

  return (
    <div>
      <div>
       
        {token ? (
         <><Layout name={isRender} update={update}/><Routes>

            <Route path="/TheExamMate" element={<WelcomeScreen />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/student-dashboard" element={<StudentDashBoard />} />
            {/* <Route path="/sample-exam" element={<SampleExam />} />
             <Route path="/sample-result" element={<SampleResult />} /> */}
            {/* <Route path="/exam-room" element={<ExamRoom />} /> */}
            {/* <Route path="/examroom-result" element={<ExamRoomResult />} />
            <Route path="/exam-result" element={<ExamResult />} />
            <Route path="/exam-room2" element={<ExamRoom2 />} /> */}
            {/* <Route path="/examresult" element={<MainExam />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}



            <Route path="/subjectwise-result" element={<SubjectWiseResult />} />
            {/* <Route path="/ourpricing" element={<OurPricing />} /> */}
            <Route path="/my-certificate" element={<MyCertificate />} />
            {/* <Route path="/change-password" element={<ChangePassword />} /> */}
            <Route path="/my-profile" element={<MyProfile />} />
            {/* <Route
              path="/student-information"
              element={<StudentInformation />} /> */}
            <Route path="/subscriptionpricing" element={<SubsPricing />} />
            {/* <Route
              path="/educational-information"
              element={<EducationalInformation />} /> */}
            <Route path="/student-info" element={<StudentInfo />} />
            {/* <Route path="/subscription" element={<Subscription />} /> */}
      

            <Route path="/service" element={<Service />} />
            {/* <Route path="/Result" element={<Resultchart />} /> */}
            <Route path="/portfolio" element={<Portfolio />} />
            {/* <Route path="/faq" element={<FAQ />} /> */}
            <Route path="/planing" element={<Planing />} />
            <Route path="/blog" element={<Blog />} />
            <Route exact path="/blogpage/:id" element={<BlogPage />} />


            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/aboutus" element={<AboutUsMenu />} /> */}
            <Route path="/signin" element={<SignUp />} />
            <Route path="/self-assesement" element={<SelfAssesment />} />
            {/* <Route path="/self-Question" element={<SelfQuestion />} /> */}
            <Route path="/Studymaterial" element={<Pdf />} />
            {/* <Route path="/Pricing" element={<PricingPage />} />
    <Route path="/Terms" element={<Terms />} />
    <Route path="/Privacy" element={<PrivacyPolicy />} />
    <Route path="/Sitemap" element={<Sitemap />} />
    <Route path="/RefundPolicy" element={<RefundPolicy />} /> */}
          </Routes></>
        ) : (
          <><> {location.pathname === "/examresult" ||
              location.pathname === "/TheExamMate" ? null : (
              <Header name={isRender} update={update} />
            )}</><Routes>
                <Route path="/TheExamMate" element={<WelcomeScreen />} />
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login render={render} />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/ourpricing" element={<OurPricing />} />
                <Route path="/aboutus" element={<AboutUsMenu />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/Pricing" element={<PricingPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/sample-exam" element={<SampleExam />} />
                <Route path="/sample-result" element={<SampleResult />} />
                <Route path="/Studymaterial" element={<Pdf />} />
                <Route
                  path="/SchoolAdminDashboard"
                  element={<SchoolAdminDashboard />} />
                <Route exact path="/blogpage/:id" element={<BlogPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/aboutus" element={<AboutUsMenu />} />
                {/* <Route path="/sample-exam" element={<SampleExam />} />
                <Route path="/sample-result" element={<SampleResult />} /> */}
                <Route path="/Pricing" element={<PricingPage />} />
                <Route path="/Examtable" element={<ExamResultTable />} />
                <Route path="/Performance" element={<Performance />} />
                <Route path="/Studymaterials" element={<Studymaterial />} />
                <Route path="/SUbjectpdf" element={<PdfComponent />} />
                <Route path="/SUbjectpdf10" element={<PdfComponentten />} />
                <Route path="/SUbjectpdf11" element={<PdfComponentele />} />
                <Route path="/SUbjectpdf12" element={<PdfComponenttwel />} />
                <Route path="/Sitemap" element={<Sitemap />} />
                <Route path="/Terms" element={<Terms />} />
                <Route path="/Privacy" element={<PrivacyPolicy />} />
                <Route path="/RefundPolicy" element={<RefundPolicy />} />
                <Route path="/olympiadlogin" element={<Olympiad />} />
                <Route path="/bannerform" element={<BannerForm />} />
              </Routes>
              {location.pathname === "/examresult"  || location.pathname === "/TheExamMate" ? null : (<Footer />)}
        {token &&
        !(
          location.pathname === "/examresult" ||
          location.pathname === "/TheExamMate"
        ) ? (
          <TabNavigation />
        ) : null}
              </>
              
        )}
       
      </div>
    </div>
  );
}