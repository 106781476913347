import React from "react";

function Sitemap() {
  return (
    <div>
      <div className="privacy" style={{ textAlign: "center", margin: 30 }}>
        <h3>Site Map</h3>
        <div className="container" style={{ textAlign: "justify", margin: 30 }}>
          <p>
            <h5>Home</h5>
          </p>

          <p style={{ textAlign: "justify" }}>
            <ul>
              <a>
                <li>overview</li>
              </a>
              <a>
                <li>About us</li>
              </a>
              <a>
                <li>Pricing</li>
              </a>
              <a>
                <li>Our team Members</li>
              </a>
              <a>
                <li>Testimonials</li>
              </a>
              <a>
                <li>Our Blogs</li>
              </a>
              <a>
                <li>Boards Logo</li>
              </a>
              <a>
                <li>Newsletter Subscription</li>
              </a>
            </ul>
          </p>

          <h5>About Us</h5>

          <p style={{ textAlign: "justify" }}>
            <ul>
              <a>
                <li>About us </li>
              </a>
              <a>
                <li>Our team Members</li>
              </a>
              <a>
                <li>Boards Logo</li>
              </a>
            </ul>
          </p>

          <h5>Sample Exam</h5>

          <h5>Resources</h5>
          <h5>Study Materials</h5>
          <h5>CBSE BOARD</h5>

          <p style={{ textAlign: "justify" }}>
            <ul>
              <a>
                <li>12th Syllabus</li>
              </a>
              <a>
                <li>11th Syllabus</li>
              </a>
              <a>
                <li>10th Syllabus</li>
              </a>
              <a>
                <li>9th Syllabus</li>
              </a>
              <a>
                <li>8th Syllabus</li>
              </a>
              <a>
                <li>7th Syllabus</li>
              </a>
              <a>
                <li>6th Syllabus</li>
              </a>
            </ul>
          </p>

          <h5>Pricing</h5>

          <p style={{ textAlign: "justify" }}>
            <ul>
              <a>
                <li>About pricing</li>
              </a>
              <a>
                <li>Full Academic year</li>
              </a>
              <a>
                <li>National Olympiad</li>
              </a>
              <a>
                <li>Single Exam</li>
              </a>
            </ul>
          </p>

          <h5>Blogs</h5>
          <h5>Contact Us</h5>
          <h5>Terms Conditions</h5>
          <h5>Privacy Policy</h5>
          <h5>FAQ’S</h5>
        </div>
      </div>
    </div>
  );
}

export default Sitemap;
