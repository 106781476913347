import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const SubjectWiseResult = () => {
        const [chartData, setChartData] = useState({});
        const [chartOptions, setChartOptions] = useState({});
    
        useEffect(() => {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
            const data = {
                labels: ['Tamil', 'English', 'Maths', 'Physics', 'Chemistry', 'CS'],
                datasets: [
                    {
                        type: 'bar',
                        label: 'Percentage',
                        backgroundColor: '#c0c2c0',
                        data: [41, 52, 24, 74, 23, 21, 32]    
                    }
                ]
            };
            const options = {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
    
            setChartData(data);
            setChartOptions(options);
        }, []);
    
        return (
            <div>
             <div class="container student-dashboard">
                 <h3>Subject  Wise Result</h3>
                 <div className="row">
                     <div className="col-lg-12 col-sm-12 col-md-12">
                         <div className="card">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                    </div>
                     </div>
                 </div>
             </div>
            </div>
        )
    }
            
        
export default SubjectWiseResult;