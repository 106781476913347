import React from "react";
import SubjectIcon from "@mui/icons-material/Subject";
import subject from "../../../assets/Images/Icons/books.png";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function Studymaterial() {


   const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };


  return (
    <div style={{ background: "#f9f9f9", height: "100vh" }}>
      <div
        className="container"
        style={{ height: "100%", padding: 20, background: "#f9f9f9" }}
      >
        <div className="row">
          <div className="main col-lg-12">
            <h3> {translation("Studymaterials.StudyTitle1")}</h3>

            <div className="main1 d-flex flex-wrap">
              <div className="maindivs1 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/subjectpdf">9th</a>
                  </Typography>
                  <p>
                    {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs2 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/SUbjectpdf10">10th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs3 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/SUbjectpdf11">11th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs3 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/SUbjectpdf12">12th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
            </div>
            <hr></hr>
            <h3>{translation("Studymaterials.StudyTitle2")}</h3>
            <div className="main1 d-flex flex-wrap">
              <div className="maindivs1 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/Studymaterials">9th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs2 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/Studymaterials">10th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs3 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/Studymaterials">11th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} , 
                    {translation("Studymaterials.StudyPrevques")} , 
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
              <div className="maindivs3 d-flex col-lg-3 col-md-6">
                <div>
                  <img src={subject} style={{ height: 50, paddingRight: 20 }} />
                </div>
                <div>
                  <Typography variant="h5">
                    <a href="/Studymaterials">12th</a>
                  </Typography>
                  <p>
                  {translation("Studymaterials.StudyTextbook")} ,   
                    {translation("Studymaterials.StudyPrevques")} ,   
                    {translation("Studymaterials.StudyMaterial")}
                  </p>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studymaterial;
