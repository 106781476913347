import React, { useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import "../../../theme/css/Contactus.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import "./EducationalInformation.css";
import OurPricing from "../HomePage/OurPricing";

import MyProfile from "../My Account/MyProfile";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
import "aos/dist/aos.css";
import OurPricingData from "../HomePage/OurPricingData";
import { useTranslation } from "react-i18next";
import { useTokenNavigation } from "../Usetoken/useTokenNavigation";
function Subscription() {

  const navigate = useNavigate()
    
  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
            <div class="container subscription">
            <MyProfile value={2} />
            <h4>{translation("Studentinfo.subscription")}</h4>
          
            <OurPricing/> 
            <div className="col-lg-12 col-md-12">
              <div
                className="form-group"
                style={{ display: "flex", justifyContent: "start" }}
              >
              
                <button onClick={(e)=>navigate("/educational-information")} className="loginBtn">
                    Back
                  </button>
            
              </div>
            
            </div>
            </div>
    </div>
  )
}

export default Subscription