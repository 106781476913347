import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import WELCOMELOGO from "../../../assets/Images/BrandLogo/The ExamMate Welcom logo.jpeg";

export default function SimpleBackdrop() {
  return (
    <div className="loader">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true} style={{height:"100vh"}}
      >
         <img
          style={{ height: 100,background:"#d5d5d5" }}
          src={WELCOMELOGO}
          alt="themeLogo"
          className="img-fluid mx-auto WLogo welcome-img"
          id="logomain"
          />
          {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    </div>
  );
}
