import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const [message, setMessage] = useState("");
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [schoolnameError, setSchoolNameError] = useState('');


 

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  // const toastSuccess = (data)=>{
  //   toast.success(data, {
  //     position: toast.POSITION.TOP_RIGHT
  //   });
  // }
  const toastError = (data)=>{
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);

    // Define a regex pattern for a valid mobile number
    const mobilePattern = /^[0-9]{10}$/;

    if (value.trim() === '') {
      setMobileError('Mobile number is required');
    } else if (!mobilePattern.test(value)) {
      setMobileError('Please enter a valid 10-digit mobile number');
    } else {
      setMobileError('');
    }
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Define a regex pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (value.trim() === '') {
      setEmailError('Email is required');
    } else if (!emailPattern.test(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleSchoolNameChange = (e) => {
    const value = e.target.value;
    setSchoolName(value);

    // Split the input by spaces and check if it has more than one word
    const words = value.trim().split(' ');

    if (words.length < 2) {
      setSchoolNameError('Please enter a valid school name');
    } else {
      setSchoolNameError('');
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   if (
  //     name.trim() === "" ||
  //     mobile.trim() === "" ||
  //     email.trim() === "" ||
  //     schoolname.trim() === "" ||
  //     message.trim() === ""
  //   ) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Fill the Form",
  //       text: "Please fill in all the required fields.",
  //     });
  //     return;
  //   }
  //   const headers = {
  //     // Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //    // Check if any validation errors exist
  

  //   const d = {
  //     Name: name,
  //     MobileNo: mobile,
  //     EmailId: email,
  //     SchoolName: schoolname,
  //     Message: message,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${window.BASEURL}${window.CONTACTUS}`,d
  //     );
  //     console.log(response.data);
  //     if (response.data.result === "Success") {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Your data submitted successfully!",
  //       }).then(() => {
  //         setName("");
  //         setMobile("");
  //         setEmail("");
  //         setSchoolName("");
  //         setMessage("");
  //       });
  //     } else {
  //       toastError(response.errorMsgs[0]);
  //     }
      
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
     // Check if there are any validation errors
  if (name.trim() === "" || mobileError || emailError || schoolnameError || message.trim() === "") {
    const errorMessages = [];

    if (name.trim() === "") {
      errorMessages.push("Please enter your name.");
    }

    if (mobileError) {
      errorMessages.push("Please enter a valid mobile number.");
    }

    if (emailError) {
      errorMessages.push("Please enter a valid email address.");
    }

    if (schoolnameError) {
      errorMessages.push("Please enter a valid school name.");
    }

    if (message.trim() === "") {
      errorMessages.push("Please enter a message.");
    }

    // Display the error messages using Swal
    Swal.fire({
      icon: "error",
      title: "Validation Error",
      html: errorMessages.join("<br>"),
    });

    return;
  }
  
    const headers = {
      "Content-Type": "application/json",
    };
  
    const d = {
      Name: name,
      MobileNo: mobile,
      EmailId: email,
      SchoolName: schoolname,
      Message: message,
    };
  
    try {
      const response = await axios.post(
        `${window.BASEURL}${window.CONTACTUS}`,
        d,
        { headers }
      );
      console.log(response.data);
      if (response.data.result === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your data submitted successfully!",
        }).then(() => {
          setName("");
          setMobile("");
          setEmail("");
          setSchoolName("");
          setMessage("");
        });
      } else {
        toastError(response.errorMsgs[0]);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  

  return (
    <>
      <div className="aboutUsBannerImg">
      <ToastContainer />
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={12} md={12} lg={12} sm={12}>
                <div>
                  <div className="titleFont">
                    <h3>Let's Contact Us</h3>
                    <h2>Ready to Get Started?</h2>
                  </div>
                  <form
                    onSubmit={handleFormSubmit}
                    className="row g-3  formContactus"
                  >
                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        // id="validationCustom01"
                        placeholder="Student Name*"
                        value={name}
                        onChange={handleNameChange}
                        // required
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile No*"
                        value={mobile}
                        onChange={handleMobileChange}
                        // required
                      />
                        {mobileError && <span className="error">{mobileError}</span>}
                    </div>

                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        // id="validationCustom03"
                        placeholder="Email*"
                        value={email}
                        onChange={handleEmailChange}
                        // required
                      />
                        {emailError && <span className="error">{emailError}</span>}
                    </div>
                    <div className="col-md-6 col-sm-6 col-6">
                      <input
                        type="text"
                        className="form-control"
                        // id="validationCustom04"
                        placeholder="School Name*"
                        value={schoolname}
                        onChange={handleSchoolNameChange}
                        // required
                      />
                        {schoolnameError && <span className="error">{schoolnameError}</span>}
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        // id="validationCustom05"
                        placeholder="Message*"
                        value={message}
                        onChange={handleMessageChange}
                        // required
                      />
                      <div className="invalid-feedback">
                        Please provide a Send Message.
                      </div>
                    </div>

                    <div className="col-12">
                      <button className="button" type="submit">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}