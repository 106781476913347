import React from "react";
import { useState } from "react";
import axios, { all } from "axios";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LoadingScreen from "../Loading/Loadingscreen";
import { useTranslation } from "react-i18next";
import { memo } from "react";

// mui autocomplete

// check box
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function StudentInfo({ r, cha, ss, langN ,majorError, handleSave,setMajorError}) {
  // Language //
  const [Language, setLanguage] = useState("");
  const [allLanguage, setAllLanguage] = useState([]);
  const [LanguageError, setLanguageError] = useState("");
  const [checkss, setCheckss] = useState(true);

  // Major //
  const [Major, setMajor] = useState();
  const [allMajor, setAllMajor] = useState([]);
  // const [MajorError, setMajorError] = useState('')
  const [token, setToken] = useState("");

  const [SubjectModel, setSubjectModel] = useState([]);
  const [save, SetSave] = useState("");
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();
  const [h, setH] = useState([]);
  const location = useLocation();

 
  // checkbox
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [select, setSelect] = useState([]);
  const [selecttwo, setSelectTwo] = useState();
  const [c, setC] = useState({
    m: null,
    l: null,
    check: true,
  });
  // second layout checkbox
  const [checked, setChecked] = React.useState([]);
  const [checkboxError, setCheckboxError] = useState();
  const [chLan, setChlan] = useState([]);
  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  console.log(select,chLan,"language");

  const handleLanguage = (value) => () => {
    console.log("-Duplicate", value);
    console.log(chLan, chLan.length);

    if (chLan.length > 0) {
      let s = chLan.map(item =>item.id === value.id)
      console.log("-------------------",s);
      if(s[0]){
        let fil = chLan.filter(map=>map.id !==value.id)
        
        setChlan(prev =>{
          let u = fil;
          console.log("----------------------u----------",u);
          langN(u);
          return u
        });
      }else{
       
        setChlan(prev =>{
          let u = [...prev,value];
          console.log("----------------------u----------",u);
          langN(u);
          return u
        });
      }
      
      // langN([]);
    } else {
      console.log("VAAA", value);
      setChlan([value]);
      langN([value]);
    }
 

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleToggle = (value) => () => {
    console.log("-Duplicate", value);

    if (select.length > 0) {
      let find = select.some((item) => item.id === value.id);
      console.log(value.id);
      if (find) {
        let fil = select.filter((item) => item.id !== value.id);
    
        setSelect((prev) => {
          const up = fil;
          cha(up);
          return up;
        });
      } else {
        setSelect((prev) => {
          const up = [...prev, value];
          cha(up);
          return up;
        });
        setSelect([...select, value]);
      }
    } else {
      setSelect((prev) => {
        const up = [value];
        console.log(up);
        cha(up);
        return up;
      });
    }

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // check bocvalue store

  // const handlechange = (e) => {
  //   if (languagecheck == true) {
  //     setlanguagecheck(true);
  //   }
  // };

  useEffect(() => {
    const tok = sessionStorage.getItem("token");

    setToken(tok);
    if (r != undefined && r != null) {
    }
    let ll = localStorage.getItem("ll");
    if (ll) {
      setCheckss(false);
    }
    educationSubmitForm(tok, r);
    getLanguage(tok, r);

    const userId = sessionStorage.getItem("userId");
    s(userId, tok);
    if (tok != null || tok != undefined) {
      check(tok, userId);
    }

    setSelect([]);
    setChlan([]);
    langN([]);
    cha([]);
  }, [r]);
  useEffect(() => {
    if (select.length === 0 && chLan.length > 0) {
      setMajorError(true);
    } else {
      setMajorError(false);
    }
  }, [select, chLan]);

  const s = async (u, t) => {
    const h = {
      Authorization: `Bearer ${t}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}/student/get-studentsubject-info/${u}`
      );
      if (data.length > 0) {
        setC((prev) => {
          return { ...prev, l: null, m: null, check: false };
        });
      } else {
        setC((prev) => {
          return { ...prev, l: null, m: null, check: true };
        });
      }

      // setAllLanguage(data)
    } catch (error) {
      console.log(error);
    }
  };

  const check = async (t, u) => {
    const h = {
      Authorization: `Bearer ${t}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}/student/get-studentsubject-info/${u}`
      );

      setH(data);

      // setAllLanguage(data)
    } catch (error) {
      console.log(error);
    }
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getLanguage = async (token, gradeId) => {
    setLoading(true);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.LANGUAGE}${gradeId}`,
        { headers: h } // Pass headers to the API call if required
      );
      setAllLanguage(data);
      setLoading(false);
    } catch (error) {
      // Debug: Log the error if any
      setLoading(false);
    }
  };

  const educationSubmitForm = async (token, gradeId) => {
    setLoading(true);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.MAJOR}${gradeId}`,
        { headers: h } // Pass headers to the API call if required
      );
      // Debug: Log the data received
      setAllMajor(data);
      setLoading(false);
    } catch (error) {
      // Debug: Log the error if any
      setLoading(false);
    }
  };
  // const getLanguage = async (token, gradeId) => {
  //   setLoading(true);
  //   console.log(gradeId);
  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   try {
  //     const { data } = await axios.get(
  //       `${window.BASEURL}${window.LANGUAGE}${gradeId}`
  //     );
  //     console.log(data);
  //     setAllLanguage(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // console.log(h);
  // const educationSubmitForm = async (token, gradeId) => {
  //       setLoading(true);
  //   console.log(gradeId);
  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   try {
  //     const { data } = await axios.get(
  //       `${window.BASEURL}${window.MAJOR}${gradeId}`
  //     );
  //     console.log(data);
  //     setAllMajor(data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const d = [1, -1];
  // console.log(checkss,"ov;hvofehbvghfv.hkfvghkefvgkh")

  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div class="container add-listings-box" style={{ marginTop: 0 }}>
          <ToastContainer />
          <form>
            <div class="row">
              <div class="LanguageSubject">
                <div class="form-group">
                  <h3>
                    {translation("Exams.Examlang")}:
                  
                    <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>

                <div class="form-group">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {c.check
                      ? allLanguage.map((value) => {
                          const labelId = value;
                          return (
                            <ListItem
                              key={value}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                ></IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={handleLanguage(value)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    // checked = {h.map((item)=>{if(value.id == item.id){
                                    //   return true
                                    // }else{return false}})}
                                    // checked = "true"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={labelId.subjectName}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      : allLanguage.map((value) => {
                          const labelId = value;

                          return (
                            <ListItem
                              key={value}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                ></IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={handleLanguage(value)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    // checked={checked.indexOf(value) !== -1}
                                    checked={h.some(
                                      (item) => value.id == item.subjectId
                                    )}
                                    disabled={checkss ? "false" : "true"}
                                    // checked = "true"
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={labelId.subjectName}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                  </List>
                </div>
                
              </div>

              <div class="LanguageSubject">
                <div class="form-group">
                  <h3>
                    {translation("Exams.Exammajor")}:
                    <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>

                <div className="form-group d-flex">
                  <List
                    value={{ save }}
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {c.check
                      ? allMajor.map((value) => {
                          const labelId = value;

                          return (
                            <ListItem
                              key={value}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                ></IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={handleToggle(value)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={labelId.subjectName}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      : allMajor.map((value) => {
                          const labelId = value;

                          return (
                            <ListItem
                              key={value}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                ></IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                role={undefined}
                                onClick={handleToggle(value)}
                                dense
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    // checked={checked.indexOf(value) !== -1}
                                    checked={h.some(
                                      (item) => value.id == item.subjectId
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                    disabled={checkss ? "false" : "true"}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={labelId.subjectName}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                  </List>
                </div>
                {majorError && select.length === 0 && chLan.length > 0 && (
      <p>
        {/* Please select at least one major subject */}
      </p>
    )}
              </div>

              <div
                className="LanguageSubject"
                style={{ display: "flex" }}
              ></div>

              <div
                className="LanguageSubject"
                style={{ display: "flex" }}
              ></div>
              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  style={{ display: "flex", justifyContent: "right" }}
                ></div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default memo(StudentInfo);
