import React from "react";
import { useState } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SpeedIcon from '@mui/icons-material/Speed';
import CreateIcon from '@mui/icons-material/Create';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import ExamRoom from "../ExamRoom/ExamRoom";

function TabNavigation() {
  const [value, setValue] = useState(0);
  

  const navigate = useNavigate()

  const isMobileOrTablet = useMediaQuery('(max-width: 960px)');

  return (
    <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 ,}}>
      {isMobileOrTablet && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        //   sx={{ backgroundColor: "#0059ff" }}
        sx={{
            backgroundColor: "#0059ff",
            "& .MuiSvgIcon-root": {
              fill: "#fff", // Icon color is set to white
            },
            "& .MuiBottomNavigationAction-label": {
              color: "#fff", // Text color is set to white
            },
          }}
        >
          <BottomNavigationAction label="Exam" icon={<CreateIcon />} onClick={(e)=> navigate("/exam-room")} />
          <BottomNavigationAction label="My Performance" icon={<SpeedIcon />}  onClick={(e)=> navigate("/exam-result")} />
          <BottomNavigationAction label="Study Materials" icon={<LibraryBooksIcon />}  onClick={(e)=> navigate("/Studymaterials")} />
        </BottomNavigation>
      )}
    </div>
  );
}

export default TabNavigation;
