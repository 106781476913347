import React from 'react'
import './Mainexam.css'

function ExamFooter() {
  return (
    <footer
    id="sticky-footer"
    className="flex-shrink-0 py-4 bg-dark text-white-50"
  >
    <div className="container text-center">
      <small>Copyright © The ExamMate</small>
    </div>
  </footer>
  
  )
}

export default ExamFooter