import React, { useState,useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Resultchart = () => {

  const [studentid, setStudentId] = useState();

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const userId = sessionStorage.getItem("userId");
    setStudentId(userId)
    console.log(userId)
    getperformanceresult()
  }, [studentid]);

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      zoom: {
        enabled: false, // Disable zoom functionality
      },
   
    },
  });

  const getperformanceresult = async () => {
    const h = {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentid),
      // FromDate: "2023-06-01",
      // ToDate: "2023-07-24",
    };
    console.log(d);
 // Inside getperformanceresult() function after fetching data
try {
  const { data } = await axios.post(
    `${window.EXAMURL}${window.SUBJECTWISERESULT}`,
    d,
    { headers: h }
  );
  console.log(data);
  if (data) {
    const series1Data = data.map((item) => item.totalCrtAns);
    const series2Data = data.map((item) => item.totalNoOfQ);
    const monthNames = data.map((item) => item.cmMonthName); // Get the array of month names

    setChartData((prevState) => ({
      ...prevState,
      series: [
        { name: "Correct Answers", data: series1Data },
        { name: "Total Questions", data: series2Data },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: monthNames, // Set the month names as x-axis labels
        },
      },
    }));
  }
} catch (error) {
  console.log(error);
}

  };
  

  return (
    <div  className="container add-listings-box educational"  style={{height:"100vh"}}>
        <h3>{translation("Exams.Exammonthly")}</h3>
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
    </div>
  );
};

export default Resultchart;
