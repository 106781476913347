import React from "react";
import axios, { all } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import "./SampleResult.css";
import Swal from "sweetalert2";
import Cup from "../../../assets/Images/Icons/cup2.png"


// import './ExamRoomResult.css'

// import { SignalCellularNull, Subject } from "@mui/icons-material";

function SampleResult() {
  // Add these state variables at the top of your component
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [notAnswered, setNotAnswered] = useState([]);

  const [allQuestion, setAllQuestion] = useState([]);
  const [correct, setCorrect] = useState();
  const [wrong, setWrong] = useState();
  const [notselectquestion, setNotselectquestion] = useState();
  const [question, setQuestion] = useState([]);
  const [not, setNot] = useState();
  const [a, setA] = useState();
  const [b, setB] = useState();
  const navigate = useNavigate();
  const [length, setLength] = useState();
  const [hide, setHide] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [percentage, setPercentage] = useState();


  const [SERid, SetSERid] = useState();
  const [check, setCheck] = useState(false);
  // const [find,setFind] = useState([{Answer:"A",Result:"A"},{Answer:"A",Result:"B"}]);
  const location = useLocation();

  const [SampleModel, setTT] = useState([]);
  const [ch, setCh] = useState(false);

  let what;
  const styleOne = {};

  const [count, setCount] = useState();

  let c = 0;
  useEffect(() => {
    if (location.state) {
      console.log(c);
      console.log(location.state);
      setAllQuestion(location.state);
      setQuestion(location.state);
      setTotalQuestions(location.state.length);
      percentagefun(location.state.length,location.state.correctAnswers);
      if (c === 0) {
        let a = [];
        location.state.map((item) => {
          console.log(location.state);
          console.log(item);
          setA([...a, item.result]);
          a.push(item.result);
        });
        setA(a);
      }
      c++;
    }
    if (sessionStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  console.log(a);

  const getAnswer = async (e) => {
    e.preventDefault();
    setB(b);

    // Check if any question is selected
    if (SampleModel.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No answers selected",
        text: "Please select at least one question",
      });
      return;
    }

    // Show a confirmation message using SweetAlert2
    Swal.fire({
      title: "Are you sure?",
      text: "Once submitted, you cannot undo your answers.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with the form submission
        const SampleMod = {
          SampleModel,
        };

        const h = {
          "Content-Type": "application/json",
        };

        setCh(true);

        axios
          .post(`${window.EXAMURL}${window.GETANSWER}`, SampleMod, {
            headers: h,
          })
          .then(({ data }) => {
            if (data) {
              Swal.fire({
                icon: "success",
                title: "Submitted!",
                text: "Your data has been submitted.",
              });

              // Pass both the data, SampleModel, and totalQuestions to the result page
              navigate("/sample-result", {
                state: {
                  data,
                  SampleModel,
                  totalQuestions: allQuestion.length,
                },
              });
console.log(data);
              setCheck(true);
              setQuestion(data);
              setHide(false);
              window.scrollTo(0, 0);
              // Compare the user's answers with the correct answers and update the state variables
              const correctAnswers = [];
              const wrongAnswers = [];
              const notAnswered = [];

              data.forEach((item, index) => {
                const userAnswer = SampleModel.find(
                  (ans) => ans.SERid === item.id
                );
                if (!userAnswer) {
                  //   notAnswered.push(index);
                } else if (userAnswer.Answer === item.result) {
                  correctAnswers.push(index);
                } else {
                  wrongAnswers.push(index);
                }
              });

              if (allQuestion.length - correctAnswers) {
                setNotAnswered(notAnswered);
              }

              setCorrectAnswers(correctAnswers);
              setWrongAnswers(wrongAnswers);
              //   setNotAnswered(notAnswered);
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "An error occurred while submitting your answers",
            });
          });
      }
    });
  };

  // final code
  // const getAnswer = async (e) => {
  //   e.preventDefault();
  //   setB(b);

  //   // Check if any question is selected
  //   if (SampleModel.length === 0) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "No answers selected",
  //       text: "Please select at least one question",
  //     });
  //     return; // Exit the function if no questions are selected
  //   }

  //   // Show a confirmation message using SweetAlert2
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Once submitted, you cannot undo your answers.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Submit",
  //     cancelButtonText: "Cancel",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // If the user confirms, proceed with the form submission
  //       const SampleMod = {
  //         SampleModel,
  //       };

  //       const h = {
  //         "Content-Type": "application/json",
  //       };

  //       setCh(true);

  //       axios
  //         .post(`${window.EXAMURL}${window.GETANSWER}`, SampleMod, { headers: h })
  //         .then(({ data }) => {
  //           if (data) {
  //             Swal.fire({
  //               icon: "success",
  //               title: "Submitted!",
  //               text: "Your data has been submitted.",
  //             });

  //             // Navigate to the result page here if needed
  //             navigate("/sample-result", { state: { data, SampleModel,totalQuestions } });
  //             setCheck(true);
  //             setQuestion(data);
  //             setHide(false);

  //             // Compare the user's answers with the correct answers and update the state variables
  //             const correctAnswers = [];
  //             const wrongAnswers = [];
  //             const notAnswered = [];

  //             data.forEach((item, index) => {
  //               const userAnswer = SampleModel.find(
  //                 (ans) => ans.SERid === item.id
  //               );
  //               if (!userAnswer) {
  //                 notAnswered.push(index);
  //               } else if (userAnswer.Answer === item.result) {
  //                 correctAnswers.push(index);
  //               } else {
  //                 wrongAnswers.push(index);
  //               }
  //             });

  //             setCorrectAnswers(correctAnswers);
  //             setWrongAnswers(wrongAnswers);
  //             setNotAnswered(notAnswered);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           Swal.fire({
  //             icon: "error",
  //             title: "Error",
  //             text: "An error occurred while submitting your answers",
  //           });
  //         });
  //     }
  //   });
  // };

  const sampleSubmit = (e, item, index) => {
    console.log(e.target.value);

    what = e.target.parentElement;
    console.log(e.target.checked);

    //    const dataCreate ={}
    if (item) {
      let find = SampleModel && SampleModel.some((it) => it.SERid == item.id);
      console.log(find);
      if (find) {
        let findIndex = SampleModel.findIndex((it) => it.SERid == item.id);
        console.log(findIndex);

        SampleModel[findIndex].Answer = e.target.value;
      } else {
        const dataCreate = {};
        dataCreate.SERid = item.id;
        dataCreate.Answer = e.target.value;
        dataCreate.index = index;
        console.log(dataCreate);
        setTT([...SampleModel, dataCreate]);
      }
    }
  };
  console.log(SampleModel);
  const isN = allQuestion.length - question.length;

  const percentagefun = (count,correct) => {
    // Check if count is greater than 0 to avoid division by zero
    if (allQuestion.length > 0) {
      const result = (allQuestion.length / correctAnswers.length) * 100;
      setPercentage(result);
    } else {
      // Handle the case where count is zero
      setPercentage(0); // Or set it to an appropriate default value
    }
  };
  
  return (
    <div>
      {check && (
        <header class="container exam-room header" id="head">
            <div className="Mainouter">
          <div className="exmresult ">
            <div className="exmresultpt1 ">
              <div className="heading">
                <div className="heading-text">
                <div className="heading-text"></div>
                  <h3 >
                  N/O.Q :
                  </h3>
                  
                  <h3>Correct :</h3>
                  <h3>Wrong :</h3>
                  <h3>Not Answered :</h3>
                </div>
                <div className="heading-count">
                  <h4 >{allQuestion.length}</h4>
                  <h4 style={{ color: "green" }}>{correctAnswers.length}</h4>
                  <h4 style={{ color: "red" }}>{wrongAnswers.length}</h4>
                  <h4 style={{ color: "orange" }}>{isN}</h4>
                </div>
              </div>
            </div>
            <div className="exmresultpt2">
                
              <div className="resultui">
               
                <h1>Score</h1>
               
                <h2>{percentage}%</h2>
              
              </div>
              <img id="cup" src={Cup} />
            </div>
          </div>
        </div>
         
          {/* <div className="heading">
        
            <h4>{}</h4>
          </div>
          <div className="heading">
           
            <h4>{}</h4>
          </div>
          <div className="heading">
           
            <h4>{}</h4>
          </div> */}
        </header>
      )}

      <div class="container exam-room">
        {/* <div className="exam-room-time">
                    <h3>Lesson : All</h3>
                    <h3>Time : 10:11</h3>
                </div> */}

        <div class="row">
          <form onSubmit={getAnswer}>
            {check
              ? question &&
                question?.map((item, index) => {
                  return (
                    <div class="col-lg-12 col-md-12 p-2" key={index} id="over">
                      <div class="form-group" id="form">
                        <div className="question">
                          <p>{index + 1}.</p>
                          <p>{item.question}</p>
                        </div>
                        <div className="image-web">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageWeb}`}
                            />
                          )}
                        </div>
                        <div className="image-tab">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageTab}`}
                            />
                          )}
                        </div>
                        <div className="image-mobile">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageMobile}`}
                            />
                          )}
                        </div>
                        <div className="question-body">
                          <div className="row" id="row">
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor "
                                id="opt"
                              >
                                {}
                                (A)
                                <input
                                  type="radio"
                                  id={item.answerA}
                                  name={item.id}
                                  value={"A"}
                                  style={{ marginLeft: "5px" }}
                                  checked={item.answer == "A" && true}
                                />
                                <label
                                  for={item.answerA}
                                  id={
                                    "a" == item.result
                                      ? "easys"
                                      : item.answer == "a"
                                      ? "noeasys"
                                      : ""
                                  } 
                                >
                                  {item.answerA}
                                </label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor "
                                id="opt"
                              >
                                (B)
                                <input
                                  type="radio"
                                  id={item.answerB}
                                  name={item.id}
                                  value={"B"}
                                  style={{ marginLeft: "5px" }}
                                  checked={item.answer == "B" && true}
                                />
                                <label
                                  for={item.answerB}
                                  id={
                                    "B" == item.result
                                      ? "easys"
                                      : item.answer == "B"
                                      ? "noeasys"
                                      : ""
                                  }
                                >
                                  {item.answerB}
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor "
                                id="opt"
                              >
                                (C)
                                <input
                                  type="radio"
                                  id={item.answerC}
                                  name={item.id}
                                  value={"C"}
                                  style={{ marginLeft: "5px" }}
                                  checked={item.answer == "C" && true}
                                />
                                <label
                                  for={item.answerC}
                                  id={
                                    "C" == item.result
                                      ? "easys"
                                      : item.answer == "C"
                                      ? "noeasys"
                                      : ""
                                  }
                                >
                                  {item.answerC}
                                </label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor "
                                id="opt"
                              >
                                (d)
                                <input
                                  type="radio"
                                  id={item.answerD}
                                  name={item.id}
                                  value={"D"}
                                  style={{ marginLeft: "5px" }}
                                  checked={item.answer == "D" && true}
                                />
                                <label
                                  for={item.answerD}
                                  id={
                                    "D" == item.result
                                      ? "easys"
                                      : item.answer == "D"
                                      ? "noeasys"
                                      : ""
                                  }
                                >
                                  {item.answerD}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : allQuestion &&
                allQuestion?.map((item, index) => {
                  return (
                    <div class="col-lg-12 col-md-12 p-2" key={index} id="over">
                      <div class="form-group">
                        <div className="question">
                          <p>{index + 1}.</p>
                          <p>{item.question}</p>
                        </div>
                        <div className="image-web">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageWeb}`}
                            />
                          )}
                        </div>
                        <div className="image-tab">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageTab}`}
                            />
                          )}
                        </div>
                        <div className="image-mobile">
                          {item.displayType === "Image" && (
                            <img
                              alt="imageQ"
                              src={`${window.IMAGE}/${item.questionImageMobile}`}
                            />
                          )}
                        </div>
                        <div className="question-body">
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="opt"
                              >
                                (A)
                                <input
                                  type="radio"
                                  id={item.answerA}
                                  name={item.id}
                                  value={"A"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => sampleSubmit(e, item, index)}
                                />
                                <label for={item.answerA}>{item.answerA}</label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="opt"
                              >
                                (B)
                                <input
                                  type="radio"
                                  id={item.answerB}
                                  name={item.id}
                                  value={"B"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => sampleSubmit(e, item, index)}
                                />
                                <label for={item.answerB}>{item.answerB}</label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="opt"
                              >
                                (C)
                                <input
                                  type="radio"
                                  id={item.answerC}
                                  name={item.id}
                                  value={"C"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => sampleSubmit(e, item, index)}
                                />
                                <label for={item.answerC}>{item.answerC}</label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="opt"
                              >
                                (d)
                                <input
                                  type="radio"
                                  id={item.answerD}
                                  name={item.id}
                                  value={"D"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => sampleSubmit(e, item, index)}
                                />
                                <label for={item.answerD}>{item.answerD}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

            {hide && (
              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  id="flexbuttons"
                  style={{ display: "flex" }}
                >
                  <div> </div>
                  <div>
                    {" "}
                    <button className="loginBtn" type="submit" name="Exambtn2">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SampleResult;
