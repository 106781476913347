import React from 'react'
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import { useEffect } from "react";
import FormSignup from '../../SignUp/FormSignup';
import FormSignIn from '../../SignIn/SignIn';

function Login({render}) {

    const [log,setLog]=useState(true)

    const login = ()=>{
        setLog(!log)
    }

   
  return (
    <div>
        {log?<FormSignIn  l={login} r={render}/>:<FormSignup l={login}/>}
    </div>
  )
}

export default Login