import React from 'react'
// import { CenterFocusWeakRounded } from "@mui/icons-material";
// import award1 from '../../Image/award1.png';
// import award2 from '../../Image/award2.png';
// import award3 from '../../Image/award3.png';
import './MyCertificate.css';

function MyCertificate() {
  return (
    <div>
            <div class="container student-dashboard">
                <h3>My Certificates</h3>
                <div className="row award-head">
                    {/* <div className="col-lg-4 col-md-6 text-center">
                        <img src={award1} alt="award" width={200} />
                        <p>Silver</p>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center">
                        <img src={award2} alt="award" width={200} />
                        <p>Gold</p>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center">
                        <img src={award3} alt="award" width={200} />
                        <p>Platinum</p>
                    </div> */}
                </div>
                <div class="nofound">
                <h3>Sorry! No Result Found</h3>
                </div>
            </div>
    </div>
  )
}

export default MyCertificate