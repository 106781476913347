import React from "react";
import "./olympiad.css";
import LOGO from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";

function Olympiad() {
  return (
    <div>
      <>
        <div id="body">
          <div className="containerss" id="container">
            <div className="form-containers sign-in-container">
              <form action="#" className="formforolyp">
                <img src={LOGO} style={{ height: 100 }} />
                <label
                  htmlFor="mobileNo"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  Enter Mobile No:
                </label>
                <input
                  type="email"
                  id="mobileNo"
                  placeholder="e.g., 123-456-7890"
                />

                <label
                  htmlFor="pin"
                  style={{ display: "inline-block", textAlign: "left" }}
                >
                  Enter your Pin:
                </label>
                <input type="number" id="pin" placeholder="e.g., 1234" />
                <a href="#">Forgot your Pin?</a>
                <button>Create Exam</button>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" id="signIn">
                    Sign In
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" id="signUp">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Olympiad;
