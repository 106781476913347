import "./pdf.css";
import React, { useState } from "react";
import englishPDF from "../../../assets/pdf/CBSE/11/11th-english.pdf";
import hindiPDF from "../../../assets/pdf/CBSE/11/11th-hindi.pdf";
import Maths from "../../../assets/pdf/CBSE/11/11th-maths.pdf";
import biology from "../../../assets/pdf/CBSE/11/11-biology.pdf";
import physicaleducation from "../../../assets/pdf/CBSE/10/10th-phy-edu.pdf";
import sanskrit from "../../../assets/pdf/CBSE/11/11th-sanskrit.pdf";
import pschycology from "../../../assets/pdf/CBSE/11/11th-pschycology.pdf";
import sociology from "../../../assets/pdf/CBSE/11/11th-sociology.pdf";
import urudu from "../../../assets/pdf/CBSE/11/11th-urudu.pdf";
import { Link ,useNavigate} from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

function PdfComponentele() {
  const [selectedSubject, setSelectedSubject] = useState(englishPDF);


const Navigate =useNavigate()

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  };

  return (

    <>
      <button onClick={(e) => Navigate("/Studymaterials")}>  <KeyboardDoubleArrowLeftIcon />
            Back</button>
    <div className="pdf-viewer d-flex">
      <div className="subjects">
     

        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(englishPDF)}
        >
          English
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(hindiPDF)}
        >
          Hindi
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(Maths)}
        >
          Maths
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(physicaleducation)}
        >
          PE
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(sanskrit)}
        >
          Sanskrit
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(pschycology)}
        >
          pschycology
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(sociology)}
        >
          sociology
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(urudu)}
        >
          Urudu
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(biology)}
        >
          biology
        </button>
      </div>

      <div className="pdf-container">
        {selectedSubject && (
          <iframe
            src={selectedSubject}
            title="PDF Viewer"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    </div>
    </>
  );
}

export default PdfComponentele;
