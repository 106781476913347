import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Icon1 from "../../../assets/Images/WeProvide/Icon1.png";
import Icon2 from "../../../assets/Images/WeProvide/Icon2.png";
import Icon3 from "../../../assets/Images/WeProvide/Icon3.png"
import Icon4 from "../../../assets/Images/WeProvide/Icon4.png";
import { useTranslation } from "react-i18next";


export default function AboutUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  
  return (
    <>
      <div className="container">
        <div className="aboutus spacing">
          <Row className="align-items-center">
            <Col xl={6} lg={6} md={6} sm={6} xs={12} className="details-icon">
              <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon1}
                      />
                    </div>
                    <div className="weProBoxText">
                  
                        <h6>{translation("HomePageAbout.iconcontent1")}</h6>
                 
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon2}
                      />
                    </div>
                    <div className="weProBoxText">
                    <h6>     {translation("HomePageAbout.iconcontent2")}</h6>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon3}
                      />
                    </div>
                    <div className="weProBoxText">
                    <h6>     {translation("HomePageAbout.iconcontent3")}</h6>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div className="weProBox">
                    <div className="weProBoxImg">
                      <img
                        className="img-fluid mx-auto"
                        alt="AboutUs"
                        src={Icon4}
                      />
                    </div>
                    <div className="weProBoxText">
                    <h6>     {translation("HomePageAbout.iconcontent4")}</h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={12} data-aos="fade-up" className="details-summary">
              <div className="titleFont" id="titlefontabout" style={{fontSize:18}}>
                <h3>  {translation("HomePageAbout.Abouttitlemain")}</h3>
                <h2 style={{paddingTop:10,paddingBottom:10}}>  {translation("HomePageAbout.Abouttitle")}</h2>
              </div>
              <p className="paragraph" style={{textAlign:'justify'}}>
              {translation("HomePageAbout.AboutPara")}
              </p>
             
              <button className="button">
                <Link to="/aboutus">{translation("HomePageAbout.Aboutbtn")}</Link>
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
