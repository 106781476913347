import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
// import Aos from "aos";
// import "aos/dist/aos.css";
import OurPricingData from "../HomePage/OurPricingData";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTokenNavigation } from "../Usetoken/useTokenNavigation";
// import OurPricingDataEN from "./OurPricingDataEN";
// import OurPricingDataTA from "./OurPricingDataTA ";
import axios from "axios";
import Swal from "sweetalert2";
import InstapayButton from "../instamojo/InstapayButton ";
import AlertComponent from "./AlertComponent";
// import AlertComponent from

export default function OurPricing() {
  const navigate = useNavigate();
  const [translation, i18n] = useTranslation("global");
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const [token, setToken] = useState("");
  const [plans, setPlans] = useState([]);
  const [studentid, setStudentId] = useState("");
  const [planLink, setPlanLink] = useState("");
  const [activateplan, setActivateplan] = useState("");

  const [planinfo, setplaninfo] = useState("");

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const user_Id = sessionStorage.getItem("userId");
    const planId = localStorage.getItem("selectedPlanId");
    setStudentId(user_Id);
    getPlan();
    fetchPlanLink();
    if (token && studentid) {
      getPlaninfo(token, studentid);
    }
    if (token != null && user_Id != null) {
    }
    if (user_Id != null) {
    }
    if (token != null) {
      setToken(token);
    }
    const handlePopstate = () => {
      // Reload the page when the back button is clicked
      window.location.reload();
    };
    const fetchData = async () => {
      try {
        const plansData = await getPlan();
        console.log(plansData); // Check if data is received
        setPlans(plansData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    // Attach the event listener to the popstate event
    window.addEventListener("popstate", handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [token, studentid]);

  const handleGetStartedClick = async (token, studentid, planId) => {
    if (token && studentid) {
      try {
        await getPlanid(token, studentid, planId);
        localStorage.setItem("selectedPlanId", planId); // Store the new plan ID in localStorage
        navigate("", { state: { planId } });
      } catch (error) {
        // Handle any errors if needed
      }
    } else {
      // Handle the case when token or studentid is not available
    }
  };

  // payment gateway code
  const onOpenHandler = () => {
    // alert("Payments Modal is Opened");
  };

  const onCloseHandler = () => {
    // alert("Payments Modal is Closed");
  };

  // const onPaymentSuccessHandler = (response) => {
  //   alert("Payment Success");
  //   console.log("Payment Success Response", response);
  // };
  const freeplan = async (selectedPlanId) => {
    let planssid = localStorage.getItem("selectedPlanId");
    const apiResponse = await getPlanid(token, studentid, planssid);
    if (apiResponse.result === "Success") {
      // Navigate to "exam-room" component
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your Plan Was successfully Activated",
        onClose: () => {
          // Navigate to the "exam-room" component after the alert is closed
          navigate("/exam-room", { state: { planId: selectedPlanId } });
        },
      });
    }
  };

  const onPaymentSuccessHandler = async (response) => {
    // alert("Payment Success");
    console.log("Payment Success Response", response);
    // const { PaymentRef, PaymentStatus } = response;
    const PaymentRef = response.paymentId;
    const PaymentStatus = response.status;

    // Assuming you have access to the selected plan ID and student ID
    const selectedPlanId = localStorage.getItem("selectedPlanId");
    const studentId = studentid; // Assuming this is set properly

    console.log(selectedPlanId, studentId);
    if (selectedPlanId && studentId) {
      try {
        // Call getPlanid API
        const apiResponse = await getPlanid(token, studentId, selectedPlanId);
        const planinfo = await getPlaninfo(token, studentId);
        const paymentInfo = await getpaymentinfo(
          token,
          studentId,
          selectedPlanId,
          PaymentRef,
          PaymentStatus
        );

        // Check if the API call was successful
        if (apiResponse.result === "Success") {
          // Navigate to "exam-room" component

          setplaninfo(planinfo);
          navigate("/");
          navigate("/exam-room", { state: { planId: selectedPlanId } });
        }
      } catch (error) {}
    }
  };

  const onPaymentFailureHandler = (response) => {
    alert("Payment Failure");
    console.log("Payment Failure Response", response);
  };

  const fetchPlanLink = async () => {
    try {
      const response = await axios.get(
        `${window.BASEURL}${window.PRICINGPLAN}`
      );
      const data = response.data;
      setPlanLink(data.planLink); // Assuming the response has a property called planLink
    } catch (error) {
      console.error("Error fetching plan link:", error);
    }
  };

  // Define the onButtonClick function
  const onButtonClick = async (id) => {
    try {
      const plans = await getPlan();
      console.log(plans);

      const clickedButtonId = id; // Get the clicked button's ID
      console.log(id);
      localStorage.setItem("selectedPlanId", id);
      // Find the plan with the matching ID
      const selectedPlan = plans.filter((plan) => plan.id == clickedButtonId);
      console.log(selectedPlan);
      if (selectedPlan) {
        const generatedLink = selectedPlan[0].urlLink; // Access the URL of the selected plan
        console.log(generatedLink);

        // Instamojo.configure({
        //   handlers: {
        //     onOpen: onOpenHandler,
        //     onClose: onCloseHandler,
        //     onSuccess: onPaymentSuccessHandler,
        //     onFailure: onPaymentFailureHandler,
        //   },
        // });

        if (generatedLink) {
          // Instamojo.open(generatedLink, {
          //   api_key: "662e7142f64610225401e1b532ba5330", // Replace with your actual API key
          //   token: "41d74761a896385cc871dd3d8bfa3785", // Replace with your actual token
          // });
        } else {
          freeplan();

          // console.log("No plan link found.");
        }
      } else {
        console.log("Selected plan not found.");
      }
    } catch (error) {
      console.error("Error during Instamojo integration:", error);
      alert("An error occurred during payment. Please try again later.");
    }
  };
  //  const navigateBack = () => {
  //       navigate("/ourpricing") // Navigate back to the previous page (pricing)
  //      };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.instamojo.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getPlan = async () => {
    const h = {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.PRICINGPLAN}`
      );

      console.log(data);
      setPlans(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getPlanid = async (token, studentid, planId) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentid),
      PlanId: parseInt(planId),
    };
    console.log(d);
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.PRICINGPLANID}`,
        d,
        { headers: h }
      );
      console.log(data);

      // Display success alert
      if (data.result === "Success") {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your Plan Was successfully Activated",
          onClose: () => {
            // Navigate to the "exam-room" component after the alert is closed
            navigate("/exam-room");
          },
        });
      } else if (data.result === "Failed") {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "You have already used the free plan. Please select a Paid plan.",
        });
      }
    } catch (error) {
      console.log(error);

      // Display error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting data.",
      });
    }
  };

  const getpaymentinfo = async (
    token,
    studentid,
    planId,
    PaymentRef,
    PaymentStatus
  ) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentid),
      PlanId: parseInt(planId),
      PaymentRef: PaymentRef,
      PaymentStatus: PaymentStatus,
    };
    console.log(d);
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.PAYEMENTSUCCESS}`,
        d,
        { headers: h }
      );
      console.log(data);

      // Display success alert
      if (data.result === "Success") {
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(activateplan);

  // getplaninfo
  // const getPlaninfo = async (token, studentId) => {
  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };

  //   try {
  //     const { data } = await axios.get(
  //       `${window.BASEURL}${window.PLANINFO}?StudentId=${encodeURIComponent(studentId)}`,
  //       { headers: h }
  //     );
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getPlaninfo = async (token) => {
    // console.log(subjectId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      // console.log(subjectId);
      console.log(window.CHAPTER);
      const { data } = await axios.get(
        `${window.BASEURL}${window.PLANINFO}/${studentid}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setActivateplan(data);
      // setAllChapter(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  
  const handleNavigation = useTokenNavigation();
  // const pricingData =
  //   i18n.language === "ta" ? OurPricingDataTA : OurPricingDataEN;

  return (
    <div className="container">
      {/* <AlertComponent/> */}

      <div className="spacing">
        <div className="titleFont">
          {/* <h3> {translation("Pricing.PricingTitle2")}</h3> */}
          <h2> {translation("Pricing.PricingTitle1")}</h2>
        </div>
        <div className="ourPricingCard">
          {/*    */}
          <Row>
            {activateplan ? ( // Check if activateplan data is available
              // Render the new JSX with activateplan data
              <Col
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={12}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="pricingCard ">
                  <div className="pricingColorBox text-center">
                    <h5>{activateplan.planName}</h5>
                    <h3 id="fontfamforrupess">
                      ₹ {activateplan.planValue}
                      <span id="fontfamforrupess">
                        {/* {activateplan.offerValue} */}
                      </span>
                    </h3>
                    <h2>{activateplan.subPlan}</h2>
                  </div>
                  <div className="pricingSpacingBox">
                    <ul className="list-unstyled pricingBoxList">
                      {activateplan.planDescription
                        .split("</li>")
                        .map((paragraph, index) => {
                          paragraph = paragraph.replace("<li>", "").trim();

                          if (paragraph) {
                            const paragraphWithCheckmark = `<i class="fa fa-check"></i> ${paragraph}`;

                            return (
                              <li
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: paragraphWithCheckmark,
                                }}
                              />
                            );
                          }
                          return null;
                        })}
                    </ul>
                    <div className="text-center"></div>
                  </div>
                </div>
              </Col>
            ) : (
              // Render the old JSX code if activateplan data is not available
              plans.map((pricing) => (
                <Col xl={4} lg={4} md={4} sm={4} xs={12} key={pricing.id}>
                  <div
                    className="pricingCard"
                    data-aos="flip-leftsss"
                    style={{ marginBottom: 20 }}
                  >
                    <div
                      className="pricingColorBox text-center"
                      key={pricing.id}
                    >
                      <h5>
                        {pricing.planName}{" "}
                        {/* {translation("Pricing.pricingpara")} */}
                      </h5>
                      <h3 id="fontfamforrupess">
                        ₹ {pricing.planValue}
                        {/* <span id="fontfamforrupess">{pricing.offerValue}</span> */}
                      </h3>
                      <h2>{pricing.subPlan}</h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        {pricing.planDescription
                          .split("</li>")
                          .map((paragraph, index) => {
                            paragraph = paragraph.replace("<li>", "").trim();

                            if (paragraph) {
                              // Add a checkmark icon before each paragraph
                              const paragraphWithCheckmark = `<i class="fa fa-check"></i> ${paragraph}`;

                              return (
                                <li
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: paragraphWithCheckmark,
                                  }}
                                />
                              );
                            }

                            return null;
                          })}
                      </ul>
                          
                      <div className="text-center">
                        <div className="text-center">
                          {token ? (
                            <div>
                              {pricing.id !== 3 && (
                                <button
                                  id={pricing.id}
                                  onClick={() => onButtonClick(pricing.id)}
                                >
                                  Pay Now
                                </button>
                              )}
                            </div>
                          ) : (
                            <button onClick={() => navigate("/login")}>
                              Pay Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
