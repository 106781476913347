import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams, useLocation } from "react-router-dom";
// import Footer from "../../Component/Footer/Footer";
// import Navbar from "../../Component/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
// import './Exams.css';
import "./selfassesement.css";

function SelfAssesment() {
  // Show the data one by one
  const [cardData, setCardData] = useState([]);
  const [visible, SetVisible] = useState(1);

  const [allQuestion, setAllQuestion] = useState([]);
  const [token, setToken] = useState("");
  const [EQPId, setEQPId] = useState(null);
  const [QuestionId, setQuestionId] = useState();
  const Navigate = useNavigate();
  const dataCreate = {};
  const [EQBDId, setEQBDId] = useState(null);
  const [Answer, setAnswer] = useState(null);
  const [Result, setResult] = useState(null);
  const [find, setFind] = useState([
    { Answer: "A", Result: "A" },
    { Answer: "A", Result: "B" },
  ]);
  const [ch, setCh] = useState(false);
  const [op, setO] = useState(false);
  const [subject, setSubject] = useState();
  const [e, setE] = useState([]);
  const [c, setC] = useState(0);
  const location = useLocation();
  const [s, setS] = useState();
  const [answer, setAns] = useState([]);

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  //   show the answer one by one function
 
  // timer function
  const [colorA, setColorA] = useState("#6a6a6a"); // set initial font color to black
  const [colorB, setColorB] = useState("#6a6a6a"); // set initial font color to black
  const [colorC, setColorC] = useState("#6a6a6a"); // set initial font color to black
  const [colorD, setColorD] = useState("#6a6a6a"); // set initial font color to black

  // const TrnEQPAnswer = [];
  const [TrnEQPAnswer, setTT] = useState([]);
  let what;
  const styleOne = {};
  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      Navigate("/");
    }
    const tok = sessionStorage.getItem("token");
    // setTrnEQPAnswer(...TrnEQPAnswer,dataCreate)
    if (tok != null || tok != undefined) {
      setToken(tok);
    }
    if (location.state) {
      // console.log(location.state);
      setAllQuestion(location.state.data);
      setE([location.state.data[c]]);
      setSubject(location.state.show);
    }
    //   getAnswer();
  }, [c]);
  //   console.log(e);

  const getAnswer = async (e) => {
    e.preventDefault();
    what.classList.add("easy");
    //   console.log(answer)

    const answer = {
      answer,
    };
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    setCh(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_EXAM_DOMAIN_NAME}/TEMExam/get-exam-room-training`,
        answer,
        { headers: h }
      );
      //   console.log(data);
      setCardData(data);
      if (data) {
        Navigate("/CloneExams", { state: { data, TrnEQPAnswer, subject } });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(answer.answer, find);
  // console.log(answer.answer == find.result);
  const validate = (e, item) => {
    // e.preventDefault();
    // console.log(e.target.value);
    // waat = e.target.parentElement;
    // console.log(e.target.checked);
    // console.log(item);
    let val;

    console.log(s, answer[0].answer);
    if (s == answer[0].answer) {
      console.log("success");
      //  val == true
    } else {
      console.log("wrong answer");
      val = false;
    }
    setO(true);
    if (val === true) {
      e.preventDefault();
      switch (answer[0].answer) {
        case "a":
          setColorA("green");
          break;
        case "b":
          setColorB("green");
          break;
        case "c":
          setColorC("green");
          break;
        case "d":
          setColorD("green");
          console.log("green");
          break;
        default:
          console.log("greendd");

          console.log(true);
      }
    } else {
      switch (s) {
        case "a":
          setColorA("red");
          break;
        case "b":
          setColorB("red");
          break;
        case "c":
          setColorC("red");
          break;
        case "d":
          setColorD("red");

          break;
        default:
          console.log(true);
      }
      switch (answer[0].answer) {
        case "a":
          setColorA("green");
          break;
        case "b":
          setColorB("green");
          break;
        case "c":
          setColorC("green");
          break;
        case "d":
          setColorD("green");
          console.log("green");
          break;
        default:
          console.log("greendd");
      }
    }
  };

  const checkedFind = (e, item) => {
    if (item) {
      let results = answer && answer.some((it) => it.id == item.id);
      console.log(results);

      if (results) {
        let findres = answer.findIndex((it) => it.id == item.id);
        console.log(findres);
        answer[findres].answer = e.target.value;
      } else {
        console.log(e.target.value);
        setAns([item]);
        setS(e.target.value);
      }
      setS("");
    }
  };
  const loadmore = () => {
    setC(c + 1);
    console.log(c);
    setColorA("");
    setColorB("");
    setColorC("");
    setColorD("");
    setAns("");
    // console.log(answer)
  };

  console.log(answer);

  console.log(allQuestion);
  // console.log(allQuestion);
  console.log(op);
  return (
    <div>
      <div
        class="container exam-room"
        style={{ height: "400px", marginBottom: "190px", marginTop: "100px" }}
      >
        <div className="exam-room-time">
          <h3>Subject: {subject}</h3>
          <h3>Chapter : All</h3>
          <h3>
            Time :{minutes}:{seconds}
          </h3>
        </div>

        <div class="row">
          <form>
            {e.map((item, index) => {
              let image = `http://192.168.1.212/temexamapi`;
              return (
                <div class="col-lg-12 col-md-12 p-2" key={index}>
                  <div class="form-group">
                    <div className="question">
                      <p>{index + 1}.</p>
                      <p>{item.question}</p>
                    </div>
                    <div className="image-web">
                      {item.displayType === "Image" && (
                        <img
                          alt="imageQ"
                          src={`${image}/${item.questionImageWeb}`}
                        />
                      )}
                    </div>
                    <div className="image-tab">
                      {item.displayType === "Image" && (
                        <img
                          alt="imageQ"
                          src={`${image}/${item.questionImageTab}`}
                        />
                      )}
                    </div>
                    <div className="image-mobile">
                      {item.displayType === "Image" && (
                        <img
                          alt="imageQ"
                          src={`${image}/${item.questionImageMobile}`}
                        />
                      )}
                    </div>
                    <div className="question-body">
                      <form onSubmit={getAnswer}>
                        <div className="row">
                          <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                            <div
                              className="d-flex align-items-baseline radio-cursor"
                              id="option"
                            >
                              (A)
                              <input
                                type="radio"
                                id={item.answerA}
                                name={item.id}
                                value={"a"}
                                style={{ marginLeft: "5px" }}
                                onChange={(e) => checkedFind(e, item)}
                              />
                              <label
                                style={{ color: colorA }}
                                for={item.answerA}
                              >
                                {item.answerA}{" "}
                              </label>
                            </div>
                            <div
                              className="d-flex align-items-baseline radio-cursor"
                              id="option"
                            >
                              (B)
                              <input
                                type="radio"
                                id={item.answerB}
                                name={item.id}
                                value={"b"}
                                style={{ marginLeft: "5px" }}
                                onChange={(e) => checkedFind(e, item)}
                              />
                              <label
                                style={{ color: colorB }}
                                for={item.answerB}
                              >
                                {item.answerB}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                            <div
                              className="d-flex align-items-baseline radio-cursor"
                              id="option"
                            >
                              (C)
                              <input
                                type="radio"
                                id={item.answerC}
                                name={item.id}
                                value={"c"}
                                style={{ marginLeft: "5px" }}
                                onChange={(e) => checkedFind(e, item)}
                              />
                              <label
                                style={{ color: colorC }}
                                for={item.answerC}
                              >
                                {item.answerC}
                              </label>
                            </div>
                            <div
                              className="d-flex align-items-baseline radio-cursor"
                              id="option"
                            >
                              (d)
                              <input
                                type="radio"
                                id={item.answerD}
                                name={item.id}
                                value={"d"}
                                style={{ marginLeft: "5px" }}
                                onChange={(e) => checkedFind(e, item)}
                              />
                              <label
                                style={{ color: colorD }}
                                for={item.answerD}
                              >
                                {item.answerD}
                              </label>
                            </div>
                          </div>
                          {/* className={ch ? "A" == find[index].Result ? "easy" :find[index].Answer == 'A'? "noeasy" :"" :""} */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              );
            })}
          </form>
          <div class="col-lg-12 col-md-12">
            <div class="form-group d-flex justify-content-between">
              <button
                className="loginBtn"
                onClick={() => {
                  validate();
                }} name="validate"
              >
                Validate
              </button>
              <button
                className="loginBtn"
                onClick={() => {
                  loadmore();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfAssesment;
