import React, { useState, useEffect } from "react";
import "./pdf.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import LoadingScreen from "../Loading/Loadingscreen";

function Pdf() {
  const [title, setTitle] = useState("");
  const [description, setDescrition] = useState("");
  const [Pdf, setPdf] = useState("");
  const location = useLocation();
  const [allsub, setAllsub] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // refresh the component while clicking the button
  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    console.log(location.state);
    {
      location.state && setTitle(location.state.pageTitle);
      location.state && setDescrition(location.state.description);
      location.state && setPdf(location.state.url);
      getPDf(location.state.menuName);
    }
  }, []);

  const getPDf = async (menuname) => {
    let v = menuname.trim();
    console.log(v);
    console.log();
    const h = {
      "Content-Type": "application/json",
    };
    try {
      setIsLoading(true)
      const { data } = await axios.get(
        `${window.BASEURL}${window.PDFNAV}${menuname}`
      );
      console.log(data);
      if (data) {
        console.log(data);
        setAllsub(data);
      setIsLoading(false)

      }
    } catch (error) {
      console.log(error);
    }
  };

  const pageTitle = allsub.length > 0 ? allsub[0].pageTitle : "";
  // const description = allsub.length > 0 ? allsub[0].description : "";
  console.log(allsub);
  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="overallpage">
          <div className="page-title">
            {/* {allsub &&
            allsub.map((item) => (
              <h4 className="container">{item.pageTitle}</h4>
            ))} */}

            <h4 className="container">{pageTitle}</h4>
          </div>
          <div className="container">
            {allsub &&
              allsub.map((item) => (
                <button id="subjectbtn">{item.subjectName}</button>
              ))}
          </div>
          <div>
            {allsub &&
              allsub.map((item) => (
                <p className="container" id="descriptionforpdf">
                  {item.description}
                </p>
              ))}
            <p></p>
          </div>

          <div className="container">
            <div className="pdf-container">
              {/* {allsub && allsub.map((item) =>(
            <iframe
            src={item.url}
            title="testPdf"
            style={{ width: "100%", height: "100%" }}
          />
           ))} */}
              {allsub &&
                allsub.map((item) => (
                  <iframe
                    className="no-print"
                    key={item.id} // Add a unique key for each item in the map
                    src={item.pdfurl}
                    title="testPdf"
                    style={{ width: "100%", height: "100%" }}
                  />
                ))}
              {/* <DocViewer
              documents={allsub.map((item) => ({ uri: item.pdfurl }))}
              pluginRenderers={DocViewerRenderers}
            /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pdf;
