
export const OurPricingData= [

  {
    id: 4,
    title: "Academic Year",
    newPrice: "₹7999",
    // oldPrice: "₹7499",
    subTitle: " + GST",
    contain1: "Self Asesement",
    contain2: "Unlimited Examinations ",
    contain3: "National Level Olympiad",
    contain4: "Performance Tracking",
    contain5: "Customer Support",
    // contain5: "Hosing (1 year Free)",
    // contain6: "Domain (1 Year Free)"   
  },
  {
    id: 5,
    title: "National Olympiad",
    newPrice: "₹799",
    // oldPrice: "₹6000",
    subTitle: " + GST",
    contain1: "All Subjects",
    contain2: "National Level Ranking ",
    contain3: "State Level Ranking",
    contain4: "Subject Wise Ranking",
    contain5: "Customer Support",
  },
  {
    id: 6,
    title: " Free Plan ",
    newPrice: "₹0",
    // oldPrice: "₹6000",
    subTitle: " 3 days",
    contain1: "Self Asesement",
    contain2: "Unlimited Examinations ",
    contain3: "National Level Olympiad",
    contain4: "Performance Tracking",
    contain5: "Customer Support",
  
  }
 
];


export default OurPricingData;