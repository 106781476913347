import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/TheExammate.png";
import BlackThemeLogo from "../../assets/Images/BrandLogo/TheExamMateLogo.png";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LanguageIcon from "@mui/icons-material/Language";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import "./header.css";

const Header = (props) => {
  const [translation, i18n] = useTranslation("global");

  var className = "inverted";
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [language, setLanguage] = useState("en");
  const [isMobileView, setIsMobileView] = useState(false);
  const [isHeaderInverted, setIsHeaderInverted] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobileView(screenWidth <= 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsHeaderInverted(addClass === "inverted");
  }, [addClass]);
  var scrollTrigger = 20;
  const navigate = useNavigate();
  const [menu, setMenu] = useState();
  const [getclassid, setGetclassid] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleChange = (event) => {
  //   const selectedLanguage = event.target.value;
  //   setLanguage(selectedLanguage);
  //   handleChangeLanguage(selectedLanguage);
  //   localStorage.setItem("selectedLanguage", selectedLanguage);
  // };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang, (err) => {
      if (err) {
        console.log("Error changing language:", err);
      }
    });
  };

  // const chlanguages = [
  //   { lovCode: "en", lovName: "English" },
  //   { lovCode: "ta", lovName: "Tamil" },
  //   // Add more language options if needed
  // ];
  const getmenu = async () => {
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.MENULIST}`);
      console.log(data);
      if (data) {
        console.log(data);
        setMenu(data);
        setGetclassid(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptionChange = (event) => {
    setGetclassid(event.target.value);
  };
  const reload = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    const tok = sessionStorage.getItem("token");
    getmenu();
    getWebLanguage("Lang");

    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      setLanguage("en");
    }

    handleChangeLanguage(language);
    if (tok != null) {
      setToken(tok);
    }
    console.log(tok);
  }, [props.isRender, language]);

  const onToggle = () => {
    document.getElementById("myOverlay").style.display = "block";
    document.getElementById("stamenu").className = "active";
  };

  const onCloseNav = () => {
    const screenWidth = window.innerWidth;
    // Check if the screen width is less than or equal to the mobile view breakpoint (e.g., 992px)
    if (screenWidth <= 992) {
      document.getElementById("myOverlay").style.display = "none";
      document.getElementById("stamenu").className = "";
    }
  };

  // My Profile Navigates //
  const myProfile = () => {

    navigate("/student-information");
  };

  const studentDashboard = () => {
    navigate("/student-dashboard");
  };
  const changePassword = () => {
    setAnchorEl(null);
    navigate("/change-password");
  };
  const logOutProfile = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("otp");
    sessionStorage.removeItem("f");
    sessionStorage.removeItem("userId");
    localStorage.removeItem("ll");
    localStorage.removeItem("tok");
    localStorage.removeItem("userId");
    localStorage.removeItem("sub");
    navigate("/");
    reload();
  };
  // My Profile Navigates ends //

  // My Performance Navigates //
  const ExamResult = (e) => {
    setAnchorEl(null);
    e.preventDefault();
    navigate("/exam-result");
  };
  const Pdf = (e) => {
    e.preventDefault();
    navigate("/Studymaterials");
    onCloseNav();
  };

  const blog = (e) => {
    e.preventDefault();
    navigate("/blog");
    onCloseNav();
  };

  window.onscroll = function () {
    setAddClass();
    if (
      window.scrollY >= scrollTrigger ||
      window.pageYOffset >= scrollTrigger
    ) {
      document.getElementsByTagName("nav")[0].classList.add(className);
    } else {
      document.getElementsByTagName("nav")[0].classList.remove(className);
    }
  };

  const click = (item) => {
    navigate("/Studymaterial", { state: item });
    props.update();
  };
  const getWebLanguage = async (lang) => {
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.NOFOQANDLANG}${lang}`
      );
      setLanguages(data);
    } catch (error) {
      console.log("An error occurred:", error.message);
      if (error.response) {
        console.log("Response:", error.response.data);
      }
    }
  };

  console.log(token);

  return (
    <>
      <div
        className={addClass}
      >
        <div className={`header ${isMobileView ? "inverted" : ""}`} id="nav">
          <div className="  sticky-top " id="stamenu">
            <Navbar expand="lg" collapseOnSelect>
              <div className="container-fluid">
                <div className="inner-header">
                  <Navbar.Brand
                    href="/home"
                  >
                    <img
                      style={{ height: 57 }}
                      src={ThemeLogo}
                      alt="themeLogo"
                      className="img-fluid mx-auto WLogo"
                      id="logomain"
                    />
                    <img
                      style={{ height: 57 }}
                      src={BlackThemeLogo}
                      alt="themeLogo"
                      className="img-fluid mx-auto BLogo"
                      id="logomain"
                    />
                    <div className="curve"></div>
                  </Navbar.Brand>
                  <Button
                    className="btn-navbar navbar-toggle"
                    onClick={onToggle}
                    data-toggle="collapse"
                    data-target=".nabar-ex1-collapse"
                    style={{ color: "black" }}
                  >
                    <i
                      className="fa fa-bars"
                      aria-hidden="true"
                      style={{ fontSize: 22 }}
                    ></i>
                  </Button>

                  <div className="multi-l" style={{ order: 2 }}>
                    {token !== null ? (
                      <Nav href="#">
                        {token !== null ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    open ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                >
                                  <AccountCircleIcon
                                    sx={{ width: 25, height: 25 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={anchorEl}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem
                                onClick={myProfile}
                                className="Profilemenu"
                              >
                                {translation("navbar.myProfile")}
                              </MenuItem>
                              <MenuItem
                                onClick={ExamResult}
                                className="Dashmenu"
                              >
                                {translation("navbar.myPerformance")}
                              </MenuItem>
                              <MenuItem
                                onClick={changePassword}
                                className="Changepassmenu"
                              >
                                {translation("navbar.changePassword")}
                              </MenuItem>
                              <MenuItem
                                onClick={logOutProfile}
                                className="Logoutmenu"
                              >
                                {translation("navbar.logout")}
                              </MenuItem>
                            </Menu>
                          </>
                        ) : null}
                      </Nav>
                    ) : (
                      <Nav.Link
                        href="#"
                        id="signInbutton"
                        className="d-flex align-items-center"
                      >
                        <div id="mySidenav" className="sidenav menu-vertical">
                          <div className="close-nav hidden-md hidden-lg hidden-xl ">
                            <span>
                              {" "}
                              <img
                                style={{ height: 45 }}
                                src={ThemeLogo}
                                alt=""
                                className="img-fluid mx-auto "
                                id=""
                              />
                            </span>
                            <Button
                              className="closebtn pull-right"
                              onClick={onCloseNav}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </Button>
                          </div>

                          <Nav
                            className="justify-content-center"
                            id="myOverlay"
                            style={{ flex: 1 }}
                          >
                            <Nav.Link href="home" onClick={onCloseNav}>
                              <div onClick={onCloseNav}>
                                <Link to="/home" >
                                  {" "}
                                  {translation("navbar.home")}{" "}
                                </Link>
                              </div>
                            </Nav.Link>
                            <Nav.Link href="aboutus">
                              <Link to="/aboutus" onClick={onCloseNav}>
                                {" "}
                                {translation("navbar.aboutus")}{" "}
                              </Link>
                            </Nav.Link>

                            {token !== null ? (
                              <Nav.Link href="#Exam">
                                <Link to="/exam-room" onClick={onCloseNav}>
                                  {translation("navbar.Exam")}
                                </Link>
                              </Nav.Link>
                            ) : (

                              <Nav.Link href="#sample-exam">
                                <Link
                                  to="/sample-exam"
                                  style={{ textTransform: "capitalize" }}
                                  onClick={onCloseNav}
                                >
                                  {translation("navbar.sample Exam")}
                                </Link>
                              </Nav.Link>
                            )}
                            {/* <Nav.Link href="Pricing">
                              <Link to="/Pricing" onClick={onCloseNav}>
                                {" "}
                                {translation("navbar.pricing")}
                              </Link>
                            </Nav.Link> */}

                            <Nav href="#">
                              <NavDropdown
                                style={{
                                  textTransform: "capitalize",
                                  marginTop: "-6px",
                                }}
                                title={translation("navbar.Resuorces")}
                                id="basic-nav-dropdown"
                              >
                                <>
                                  <MenuItem onClick={Pdf}>
                                    {translation("navbar.Study Materials")}
                                  </MenuItem>

                                  <MenuItem onClick={blog}>
                                    {translation("navbar.blog")}
                                  </MenuItem>
                                </>
                              </NavDropdown>
                            </Nav>
                          </Nav>
                        </div>
                        <button
                          className={`white-btn ${addClass === "inverted" && isMobileView ? "inverted" : ""} button`}
                          id="sighin"
                          style={{
                            backgroundColor: isHeaderInverted ? "blue" : "",
                          }}
                        >
                          <Link to="/login">
                            {translation("navbar.signIn")}
                          </Link>
                        </button>
                      </Nav.Link>
                    )}
                  </div>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
