import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ExamRoom.css";
import TextsmsIcon from "@mui/icons-material/Textsms";
import LoadingScreen from "../Loading/Loadingscreen";
import team4 from "../../../assets/Images/Team/team3.png";
import { useTranslation } from "react-i18next";
import AlertComponent from "../HomePage/AlertComponent";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AD2 from "../../../assets/Images/Banner/AD2.png";

function ExamRoom() {
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [noOfQuestion, setNoOfQuestion] = useState("");
  const [error, setError] = useState("");
  const [allSubject, setAllSubject] = useState([]);
  const [allChapter, setAllChapter] = useState([]);
  const [token, setToken] = useState("");
  const [studentId, setStudentId] = useState("");
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [selectStyleClass, setSelectStyleClass] = useState("");
  const [showChapters, setShowChapters] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = useState(false);
  const [planids, setPlanid] = useState("");

  const handleSubjectClickss = () => {
    setExpanded(!expanded);
  };

  // const [studentid, setStudentId] = useState("");

  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  // Validation Error
  const [subjecterror, setSubjectError] = useState("");
  const [chaptererror, setChapterError] = useState("");
  const [NoofQerror, setNoofQeError] = useState("");

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const location = useLocation();
  const planId = location.state?.planId;
  useEffect(() => {
    const tok = sessionStorage.getItem("token");

    console.log(tok);
    localStorage.setItem("tok", tok);

    const userId = sessionStorage.getItem("userId");

    console.log("userId:", userId); // Add this line
    setStudentId(userId);
    getvalidaity(token, userId);
    getvalidaityfreeplan(token, userId);

    if (token && studentId) {
      getPlaninfo(token, studentId);
    }
    if (tok != null || tok != undefined) {
      getSubject(tok);
      setToken(tok);
      setSubjectName("");
      setChapterName("");

      // getNoOfQuestion(tok)
    }
  }, [token, studentId]);

  const handleClickOpen = (e) => {
    if (e && e.target) {
      setChapter(e.target.value);

      if (e.target.options && e.target.selectedIndex !== undefined) {
        setChapterName(e.target.options[e.target.selectedIndex].text);
      }

      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPlaninfo = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      console.log(window.CHAPTER);
      const { data } = await axios.get(
        `${window.BASEURL}${window.PLANINFO}/${studentId}`,
        {
          headers: h,
        }
      );
      console.log(data);
      let plnid = data.planId;
      return plnid;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleChapterClick = async (chapterId, chapterName, subjectId,subjectName) => {
    setChapter(chapterId);
    setChapterName(chapterName);
    setSubject(subjectId);
    setSubjectName(subjectName)

    try {
 
      await getChapter(subjectId);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2),
      justifyContent: "center", // Center the actions horizontally
    },
    display: "flex",
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  }));

  const getSubject = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      console.log(window.SUBJECT);
      const { data } = await axios.get(`${window.BASEURL}${window.SUBJECT}`);
      console.log(data);
      if (data) {
        setAllSubject(data.reverse());
        setCheck(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChapter = async (subjectId) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.CHAPTER}/${subjectId}`,
        {
          headers: h,
        }
      );
      console.log(data);
      if (data.isFreePlan === "Yes") {
        setSelectStyleClass("normal-background");
      } else if (data.isFreePlan === "No") {
        setSelectStyleClass("light-shadow");
      }
      setAllChapter(data);
      console.log(data)
    } catch (error) {
      console.log(error);
    }
  };

  const getvalidaity = async (token, studentId) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const storedPlanId = localStorage.getItem("selectedPlanId");

    const d = {
      StudentId: studentId,
      PlanId: planids,
    };

    console.log(d);
    // Call getPlaninfo to get the planId
    const planId = await getPlaninfo(token);

    // Check if planId is not null (you can add error handling here)
    if (planId !== null) {
      // Call getvalidaity with the obtained planId
      const storedPlanId = localStorage.getItem("selectedPlanId");
      const d = {
        StudentId: studentId,
        PlanId: planId, // Use the obtained planId
      };

      console.log(d);
      try {
        const { data } = await axios.post(
          `${window.BASEURL}${window.PLANVALIDITY}`,
          d
        );
        console.log(data);
        if (data.result === "Success") {
          navigate("/exam-room");
        } else {
          // Swal.fire({
          //   icon: "error",
          //   title: "No Plans Selected",
          //   text: "Please subscribe to a plan.",
          // });
          // navigate("/ourpricing");
        }
      } catch (error) {
        console.log(error);
        // Handle the error
      }
    }
  };
  const createExamRoom = async (e) => {
    e.preventDefault();

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentId),
      SubjectId: parseInt(subject),
      ChapterId: parseInt(chapter),
      NoofQ: parseInt(noOfQuestion),
      SubjectName: subjectName,
      ChapterName: chapterName,
    };
    console.log(d);

    // Clear previous error messages
    setSubjectError("");
    setChapterError("");
    setNoofQeError("");
    setError("");

    let hasError = false;

    // Check if subject is selected
    if (!subject) {
      setSubjectError("Please select Subject");
      hasError = true;
    }

    // Check if chapter is selected
    if (!chapter) {
      setChapterError("Please Select Chapter");
      hasError = true;
    }

    // Check if the noOfQuestion is selected
    if (!noOfQuestion) {
      setNoofQeError("Please Select No Of Questions");
      hasError = true;
    }

    if (!hasError) {
      setIsLoading(true);
      try {
        // First, call getvalidaityfreeplan
        const storedPlanId = localStorage.getItem("selectedPlanId");

        const validityResponse = await getvalidaityfreeplan(token, chapter);
console.log(validityResponse);
        // Check the result of getvalidaityfreeplan
        // if (validityResponse.data.result === "Success") {
          if (true) {
          const { data } = await axios.post(
            `${window.EXAMURL}${window.CREATEEXAMROOM}`,
            d,
            {
              headers: h,
            }
          );

          if (data) {
            let index = allSubject.findIndex(
              (item) => item.subjectId == subject
            );
            let inde = allChapter.findIndex((item) => item.id == chapter);
            let show = allSubject[index].subjectName;
            let chap = allChapter[inde].chapterName;
            setIsLoading(false);
            navigate("/examresult", {
              state: { data, show, chap, noOfQuestion, d },
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "upgrade your plan",
            text: "Please upgrade your plan to access this feature",
          });
          navigate("/PlanUpdate");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getvalidaityfreeplan = async (token, chapter) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const d = {
      ChapterId: parseInt(chapter),
      PlanId: parseInt(planids),
    };

    console.log(d);
    const planId = await getPlaninfo(token);

    // Check if planId is not null (you can add error handling here)
    if (planId !== null) {
      const storedPlanId = localStorage.getItem("selectedPlanId");
      const d = {
        ChapterId: parseInt(chapter),
        PlanId: planId, // Use the obtained planId
      };

      console.log(d);
      try {
        const response = await axios.post(
          `${window.BASEURL}${window.FREEPLANMAP}`,
          d
        );
        console.log(response);
        return response;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubjectClick = (selectedSubject) => {
    setExpanded(!expanded);
    if (showChapters === selectedSubject.subjectId) {
      // If the selected subject is already shown, hide it
      setShowChapters(null);
    } else {
      // Otherwise, show chapters for the selected subject
      setShowChapters(selectedSubject.subjectId);
      getChapter(selectedSubject.subjectId); // Fetch chapters for the selected subject
    }
  };

  const examcreate = async (value) => {
    setNoOfQuestion(value);
    await createExamRoom(); // Call your createExamRoom API here
  };

  // const getvalidaityfreeplan = async (token) => {
  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };

  //   const storedPlanId = localStorage.getItem("selectedPlanId");

  //   const d = {
  //     ChapterId:parseInt(chapter),
  //     PlanId: parseInt(storedPlanId),
  //   };

  //   console.log(d);
  //   try {
  //     const { data } = await axios.post(
  //       `${window.BASEURL}${window.FREEPLANMAP}`,
  //       d
  //     );
  //     if (data.result === "Success") {
  //       createExam();
  //     }else{
  //       alert("Please upgrade your plan to access this feature.");
  //     }

  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const createExamRoom = async (e) => {
  //   e.preventDefault();

  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   const d = {
  //     StudentId: parseInt(studentId),
  //     SubjectId: parseInt(subject),
  //     ChapterId: parseInt(chapter),
  //     NoofQ: parseInt(noOfQuestion),
  //     SubjectName: subjectName,
  //     ChapterName: chapterName,
  //   };
  //   console.log(d);

  //   // Clear previous error messages
  //   setSubjectError("");
  //   setChapterError("");
  //   setNoofQeError("");
  //   setError("");

  //   let hasError = false;

  //   // Check if subject is selected
  //   if (!subject) {
  //     setSubjectError("Please select Subject");
  //     hasError = true;
  //   }

  //   // Check if chapter is selected
  //   // if (!chapter) {
  //   //   setChapterError("Please Select Chapter");
  //   //   hasError = true;
  //   // }

  //   // Check if the noOfQuestion is selected
  //   if (!noOfQuestion) {
  //     setNoofQeError("Please Select No Of Questions");
  //     hasError = true;
  //   }

  //   if (!hasError) {
  //     setIsLoading(true);
  //     try {
  //       const { data } = await axios.post(
  //         `${window.EXAMURL}${window.CREATEEXAMROOM}`,
  //         d,
  //         {
  //           headers: h,
  //         }
  //       );
  //       if (data) {
  //         let index = allSubject.findIndex((item) => item.subjectId == subject);
  //         let inde = allChapter.findIndex((item) => item.id == chapter);
  //         let show = allSubject[index].subjectName;
  //         let chap = allChapter[inde].chapterName;
  //         setIsLoading(false);
  //         navigate("/examresult", {
  //           state: { data, show, chap, noOfQuestion, d },
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  // getvalidaityfreeplan(token);
  // const createExamRoom = async (e) => {
  //   e.preventDefault();

  //   const h = {
  //     Authorization: `Bearer ${token}`,
  //     "Content-Type": "application/json",
  //   };
  //   const d = {
  //     StudentId: parseInt(studentId),
  //     SubjectId: parseInt(subject),
  //     ChapterId: parseInt(chapter),
  //     NoofQ: parseInt(noOfQuestion),
  //     SubjectName: subjectName,
  //     ChapterName: chapterName,
  //   };
  //   console.log(d);
  //   if (subject === "") {
  //     setSubjectError("Please select Subject");
  //   }
  //   if (chapter === "") {
  //     setChapterError("Please Select Chapter");
  //   }
  //   if (noOfQuestion === "") {
  //     setNoofQeError("Please Select No Of Questions");
  //   } else {
  //     setIsLoading(true);
  //     setError("");
  //     try {
  //       const { data } = await axios.post(
  //         `${window.EXAMURL}${window.CREATEEXAMROOM}`,
  //         d,
  //         {
  //           headers: h,
  //         }
  //       );
  //       if (data) {
  //         let index = allSubject.findIndex((item) => item.subjectId == subject);
  //         console.log(index);
  //         console.log(data)

  //         let inde = allChapter.findIndex((item) => item.id == chapter);
  //         let show = allSubject[index].subjectName;
  //         let chap = allChapter[inde].chapterName;
  //         setIsLoading(false);
  //         navigate("/examresult", {
  //           state: { data, show, chap, noOfQuestion, d },
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <AlertComponent />
          <div className="container add-listings-box">
            <h3> {translation("Exams.Examtitlemain")}</h3>
            <div className="row">
              {/* <div className="col-lg-4"></div> */}
              <div className="col-lg-8">
                <form onSubmit={createExamRoom}>
                  <div>
                    <ul style={{paddingLeft:"0px"}}>
                      {allSubject?.map((item, index) => (
                        <li key={index} style={{ listStyle: "none" }}>
                          <div
                            className={`pagetitles ${
                              expanded ? "expanded" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSubjectClick(item)}
                          >
                            {item.subjectName}
                            <KeyboardArrowDownIcon />
                          </div>
                          {showChapters === item.subjectId && (
                            <ul>
                              {allChapter?.map((chapterItem, chapterIndex) => (
                                <div
                                  className="pagetitles"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    // chapterName
                                    handleChapterClick(
                                      chapterItem.id,
                                      chapterItem.chapterName,
                                      chapterItem.subjectId,
                                      chapterItem.subjectName
                                    )
                                  }
                                >
                                  <li key={chapterIndex}>
                                    {chapterItem.chapterName}
                                  </li>
                                </div>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* <div className="form-group" style={{ marginTop: 40 }}>
                    <p>Select Subject:</p>
                    <select
                      className="dashbaord-category-select"
                      onChange={(e) => {
                        setSubject(e.target.value);
                        setName(e.target.options[e.target.selectedIndex].text);
                        setSubjectName(
                          e.target.options[e.target.selectedIndex].text
                        );
                        setChapter(""); // Reset chapter when subject changes
                        getChapter(e.target.value);
                      }}
                      value={subject}
                    >
                      <option value="">Select a Subject</option>
                      {allSubject?.map((item, index) => (
                        <option value={item.subjectId} key={index}>
                          {item.subjectName}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  {/* {subject && (
                    <div className="form-group">
                      <p>Select Chapter:</p>
                      <select
                        className="dashbaord-category-select"
                        onChange={(e) => {
                          setChapter(e.target.value);
                          setChapterName(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                        value={chapter}
                      >
                        <option value="">Select a Chapter</option>
                        {allChapter?.map((item, index) => (
                          <option value={item.id} key={index} >
                            {item.chapterName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )} */}

                  <div>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                    >
                      <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                      >
                        Select Number of Questions:
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>

                      <div className="form-group">
                        <ul className="dashboardss">
                          <li
                            className={`pagetitless ${
                              noOfQuestion === "10" ? "selecteds" : ""
                            }`}
                            value="10"
                            onClick={() => examcreate("10")}
                          >
                            10
                          </li>
                          <li
                            className={`pagetitless ${
                              noOfQuestion === "20" ? "selecteds" : ""
                            }`}
                            value="20"
                            onClick={() => examcreate("20")}
                            style={{ textAlign: "center" }}
                          >
                            20
                          </li>
                        </ul>
                      </div>

                      <div className="form-group">
                        {error.length > 0 && (
                          <span className="error">{error}</span>
                        )}
                      </div>
                      <DialogActions>
                        <div className="form-group">
                          <button
                            className="loginBtn"
                            type="submit"
                            name="Exambtn1"
                            onClick={createExamRoom}
                            disabled={!subject || !chapter || !noOfQuestion}
                          >
                            {translation("Exams.Exambtn")}
                          </button>
                        </div>
                      </DialogActions>
                    </BootstrapDialog>
                  </div>

                  {/* <div className="form-group">
                    <button
                      className="loginBtn"
                      type="submit"
                      name="Exambtn1"
                      disabled={!subject || !chapter || !noOfQuestion}
                    >
                      {translation("Exams.Exambtn")}
                    </button>
                  </div> */}
                </form>
              </div>
              <div className="col-lg-4">
                <div className="colred">
                  <div>
                    <img src={AD2} alt="Image 1" id="ADIMAG" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ExamRoom;
