import React, { useState } from "react";
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";
import SignupIMG from "../../assets/Images/AboutUs/SignupIMG.jpg"
import SignIn from "../SignIn/SignIn";
import BannerForm from "../pages/HomePage/BannerForm";
const Form = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLogin, setLogin] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  const loginForm = () => {
    setLogin(!isLogin);
  };
  return (
    <>
      <div  className="form-container">
           
        
        {/* <div className="form-content-left">
          <img className="form-img img-fluid mx-auto" src={SignupIMG} alt="spaceship" />
        </div> */}
        {!isSubmitted ? (
          <>
          {!isLogin && (
            // <FormSignup submitForm={submitForm} login={loginForm} />
            <BannerForm />
          )}
          {isLogin && (
            <SignIn submitForm={submitForm} login={loginForm} />
          )}
          </>
        ) : (
          <FormSuccess />
        )}
      </div>
    </>
  );
};

export default Form;
