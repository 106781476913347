import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./theme/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DefultScrollToTop from "../src/components/ScrollToTop/DefultScrollToTop";
import FormSignIn from "./components/SignIn/SignIn";
import i18next from 'i18next';
import { I18nextProvider } from "react-i18next";

// for multilanguages
import global_en from '../src/translation/en/global.json'
import global_ta from '../src/translation/ta/global.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      global: global_en
    },
    ta: {
      global: global_ta
    }
  }
})

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Helmet>
      {/* Short-tail keywords */}
      <meta name="keywords" content="EdTech platform, Educational technology, Student engagement, Personalized learning, Adaptive learning, Student analytics, Assessment tools, Educational platform examples, EdTech, Assessment tools, Education technology, Self-assessment, Educational software, Student evaluation, Skill assessment, Personalized learning, Adaptive learning, Progress tracking, Performance analytics, Competency-based assessment, EdTech self-assessment, Adaptive assessment platform, Competency-based assessment, Student progress tracking, Learning analytics, Self-assessment quizzes, Skill evaluation platform, Feedback and reflection tools, Individualized learning plans, Goal setting and tracking, Student performance analysis" />

      {/* Long-tail keywords */}
      <meta name="keywords" content="Educational technology assessment, Self-evaluation tools for students assessment, Self-assessment tools for students, Online self-assessment platform, Self-assessment in education, Interactive self-assessment software, Student self-assessment strategies, Self-assessment quizzes for students, Digital self-assessment activities, Self-assessment rubrics for learning, Self-assessment resources for educators, Self reflection exercises for students, Assessment for CBSE subjects, Self assessment for academic progress, Self assessment apps for education, Self assessment questionnaires for students, Individualized self assessment platform, Self assessment feedback tools, Online self-assessment platform for students, Student assessment tool, Interactive self-evaluation software for education, Adaptive self-assessment platform for students, Student performance evaluation platform, Digital assessment solution for educational self-evaluation, Advanced self-assessment tools for students, Online assessment platform for self-assessment, Self-assessment software for academic improvement, Automated student evaluation system, Tailored assessment solutions for individual learners, Efficient self-evaluation platform for educational progress, Real-time self-assessment software for students, Intelligent student assessment platform, Comprehensive self-evaluation tool for learners, Data-driven student performance analysis platform" />
    </Helmet>
    <I18nextProvider i18n={i18next}>
      <App />
      <DefultScrollToTop />
    </I18nextProvider>
  </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
