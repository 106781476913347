import React from 'react'

function Terms() {
  return (
    <div>
        <div className='privacy' style={{ textAlign: 'justify', padding: '20px' }} >
<h3 >Terms & Conditions</h3>
<div className='container' style={{ textAlign: 'justify', padding: '0' }}>
    <p><h5>Welcome to TheExamMate. These Terms & Conditions govern your use of our examination platform website. By accessing or using our platform, you agree to comply with these Terms & Conditions in their entirety. If you do not agree with any part of these terms, please refrain from using our services.</h5></p>

    <p style={{textAlign:'justify'}}>
    User Responsibilities: You are responsible for providing accurate and up-to-date information during registration and ensuring the security of your account credentials. You agree to use our platform in compliance with applicable laws and regulations, refraining from any illegal, unauthorized, or harmful activities.
    </p>
    <p style={{textAlign:'justify'}}>
    Intellectual Property: All content and materials on our examination platform, including but not limited to text, graphics, logos, and software, are protected by intellectual property laws. You agree not to reproduce, modify, distribute, or exploit any of the platform's content without obtaining prior written permission from us
    </p>
    <p style={{textAlign:'justify'}}>
    Examination Conduct: When taking examinations on our platform, you must adhere to the rules and guidelines set forth by the examination organizers. Any form of cheating, plagiarism, or unauthorized use of materials is strictly prohibited. Violation of these rules may result in disqualification or other penalties determined by the examination organizers.
    </p>


    <p style={{textAlign:'justify'}}>
   Data Accuracy: While we strive to provide accurate and reliable information, we do not warrant the completeness, accuracy, or reliability of the content on our platform. You acknowledge that any reliance on such information is at your own risk, and we are not liable for any consequences arising from inaccuracies.
    </p>


    <p style={{textAlign:'justify'}}>
    Privacy and Data Protection: Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect, use, and protect your personal information. By using our platform, you consent to the practices described in our Privacy Policy.
    </p>


    <p style={{textAlign:'justify'}}>
    Limitation of Liability: To the fullest extent permitted by law, we disclaim any liability for damages or losses incurred through the use of our examination platform. We are not responsible for any technical malfunctions, errors, or interruptions that may affect your experience or examination results.
    </p>
    <p style={{textAlign:'justify'}}>
   Termination: We reserve the right to terminate or suspend your access to our platform at any time and for any reason, including violation of these Terms & Conditions. Upon termination, you must cease all use of our services.
    </p>


    <p style={{textAlign:'justify'}}>
   Modifications to Terms & Conditions: We may update or modify these Terms & Conditions from time to time without prior notice. It is your responsibility to review the terms periodically. Continued use of our examination platform after modifications implies acceptance of the updated Terms & Conditions.
    </p>

    <p style={{textAlign:'justify'}}>
   Governing Law: These Terms & Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in [Chennai, India].
    </p>

    <h5 style={{textAlign:'justify'}}>If you have any questions or concerns regarding these Terms & Conditions, please contact us at <a  >reachout@theexammate.com</a>. 
    </h5>

    <p style={{textAlign:'justify'}}>
    By using our examination platform, you acknowledge and agree to be bound by these Terms & Conditions.
    </p>


</div>

    </div>  
    </div>
  )
}

export default Terms