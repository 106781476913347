import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./StudentInformation.css";
//react prime theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import LoadingScreen from "../Loading/Loadingscreen";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import Swal from "sweetalert2";
import PlaceholderImage from "../../../assets/Images/Icons/userprofile.png";
import { ProfileValidation } from "../validation/Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import userimg from "../../../assets/Images/Icons/User.png";
import { ToastContainer, toast } from "react-toastify";

// import Swal from "sweetalert2";

//react prime core
import "primereact/resources/primereact.min.css";
import { apiGet } from "../../../Service/Api";
import MyProfile from "../My Account/MyProfile";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { refresh } from "aos";
import { toastError } from "../../../utils/toast";
import { useRef } from "react";

function StudentInformation({ handleNext }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [parentName, setParentName] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [token, setToken] = useState("");
  const [allState, setAllState] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [allTaluk, setAllTaluk] = useState([]);
  const [userId, setUserId] = useState("");
  const [error, setError] = useState("");
  const [run, setRun] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [perror, setPError] = useState("");
  const [pinError, setPinError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setMailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [cityError, setCityError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageKey, setImageKey] = useState(0);

  const [st, setSt] = useState();
  const [stdist, setDist] = useState();
  const [sttalukerr, setTalukErr] = useState();

  // Tab function
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // react hook form validation
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    control,
  } = useForm({ resolver: yupResolver(ProfileValidation) });

  const navigate = useNavigate();
  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const user_Id = sessionStorage.getItem("userId");

    if (token != null && user_Id != null) {
      getStudentInfo(token, user_Id);
      displayImageFromBackend();
    }
    if (user_Id != null) {
      setUserId(user_Id);
    }
    if (token != null) {
      setToken(token);
      getAllState(token);
    }
  }, []);

  const cacheBuster = Date.now();
  const imageUrl = `${window.BASEURL2}${selectedImage}?cache=${cacheBuster}`;

  const handleName = (e) => {
    const name = e.target.value;
    setName(e.target.value.trim());
    if (name.length <= 1) {
      setNameError("Please Enter Valid UserName");
    } else if (name.length > 40) {
      setNameError("Please Enter Within 40 Character");
    }
    // else if(!name.test()){
    //   setNameError('Please Enter alphabet characters only');
    // }
    else {
      setNameError("");
    }
  };

  // Parent Name
  const handleParentName = (e) => {
    const parentName = e.target.value;
    setParentName(e.target.value.trim());
    if (parentName.length <= 1) {
      setPError("Please Enter Valid  Parent Name");
    } else if (parentName.length > 40) {
      setPError("Please Enter City within 40 Characters");
    } else {
      setPError("");
    }
  };

  // Email Validation
  const isEmailValid = (e, yes) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let valid;
    if (!emailRegex.test(email)) {
      setMailError("Invalid email format");
    } else {
      setMailError("");
    }
  };

  //   Address Space Valid
  const addressValid = (e) => {
    const address = e.target.value;
    setAddress(e.target.value.trim());
    if (address == "") {
      setAddressError("Please Enter Adddress");
    } else if (address.length <= 2) {
      setAddressError("Please Enter Valid Address");
    } else if (address.length > 200) {
      setAddressError("Please Enter Address within 200 Characters");
    } else {
      setAddressError("");
    }
  };

  // City Valid
  const handleCity = (e) => {
    const city = e.target.value;
    setCity(e.target.value.trim());
    if (city == "") {
      setCityError("Please Enter City");
    } else if (city.length <= 2) {
      setCityError("Please Enter Valid City");
    } else if (city.length > 40) {
      setCityError("Please Enter City within 40 Characters");
    } else {
      setCityError("");
    }
  };

  // const pinsss = (e) => {
  //   let inputval = e.replace(/\D/g, "");
  //   setPincode(inputval);
  // };

  const pinsss = (e) => {
    let inputval = e.replace(/\D/g, "");
    if (inputval.length <= 6) {
      setPincode(inputval);
      setPinError("");
      setPinError("Pincode must have 6 digits only");
    }
    if (inputval.length === 6) {
      setPinError("");
    }
  };

  const onChangeState = (e) => {
    getAllDistrict(e.target.value);
    setState(e.target.value);
  };

  const onChangeDistrict = (e) => {
    setDistrict(e.target.value);
    getAllTaluk(e.target.value);
  };

  const getAllState = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.STATE}`);
      // console.log(data);
      setAllState(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDistrict = async (id) => {
    console.log(id);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.DISTRICT}${id}`
      );
      setAllDistrict(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTaluk = async (id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.TALUK}${id}`);
      setAllTaluk(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentInfo = async (token, StudentId) => {
    console.log(StudentId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${window.BASEURL}${window.AUTOFILL}${StudentId}`
      );
      console.log(data);
      getAllTaluk(data.districtId);
      setMobile(data.mobileNo);
      setEmail(data.email);
      setName(data.stName);
      localStorage.setItem("stname", data.stName);
      setParentName(data.fatherName);
      setPincode(data.pinCode);
      setState(data.stateId);
      getAllDistrict(data.stateId);
      setDistrict(data.districtId);
      setTaluk(data.talukId);
      setCity(data.city);
      setAddress(data.address);
      setIsLoading(false);
      setSelectedImage(data.studentPhoto);
    } catch (error) {
      console.log(error);
    }
  };

  const nav = () => {
    if (
      parentName !== "" &&
      state !== "" &&
      district !== "" &&
      // taluk !== "" &&
      address !== "" &&
      // email !== "" &&
      name !== "" &&
      city !== "" &&
      mobile !== "" &&
      state !== "" &&
      pincode !== "" &&
      // Validation Error With Not Allow nxt page //
      nameError === "" &&
      cityError === "" &&
      addressError === "" &&
      emailError === "" &&
      perror === "" &&
      pinError === ""
    ) {
      // All fields are filled correctly, navigate to the next page
      navigate("/educational-information");
    } else {
      // Display a popup to fill all fields
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill out all fields and correct any validation errors!",
      });
    }
  };

  const profileSubmitForm = async (e) => {
    console.log('-------------');
    e.preventDefault();
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      Id: userId,
      StName: name,
      FatherName: parentName,
      Email: email,
      PinCode: pincode,
      StateId: state,
      DistrictId: district,
      TalukId: taluk,
      City: city,
      Address: address,
    };

    // Pin regex
    let pinregex = /^(\d{4}|\d{6})$/;
    let pin = pincode.trim();

    if (pin === "") {
      setPinError("Please enter a pincode");
    } else if (!pinregex.test(pin)) {
      setPinError("Invalid pincode");
    } else if (pin.length !== 6) {
      setPinError("Pincode must have 6 characters");
    } else {
      setPinError("");
    }
    // Pincode validation

    // else {
    //   // setError("")
    //   setPinError("");
    // }
    if (parentName == null || parentName == "" || parentName == undefined) {
      setPError("Please Enter Parent Name");
    }
    if (address == "") {
      setAddressError("Please Enter Address");
    }
    if (pincode == "") {
      setPinError("Please Enter Pincode");
    }
    if (pincode.length !== 6) {
      setPinError("Please Enter Pincode");
    }
    // if (email == "") {
    //   setMailError("Please Enter Email");
    // }
    if (name == "") {
      setNameError("Please Enter Name");
    }
    if (city == "") {
      setCityError("Please Enter City");
    }
    if (mobile == "") {
      setMobileError("Please Enter Mobile");
    }

    if (state == "Select State" || state == "") {
      setSt("Please Select State");
    } else {
      setSt("");
    }

    if (district == "Select District" || district == "") {
      setDist("Please Select District");
    } else {
      setDist("");
    }

    // if (taluk == "Select Taluk" || taluk == "") {
    //   setTalukErr("Please Select Taluk");
    // } else {
    //   setTalukErr("");
    // }

    isEmailValid(email);
    if (
      parentName != "" &&
      state != "" &&
      district != "" &&
      // taluk != "" &&
      address != "" &&
      // email != "" &&
      name != "" &&
      city != "" &&
      mobile != "" &&
      state != "" &&
      pincode != "" &&
      //Validation Error With Not Allow nxt page //
      nameError == "" &&
      cityError == "" &&
      addressError == "" &&
      perror == ""
    ) {

      if (pincode.length === 6) {
        try {
          setIsLoading(true);
          const { data } = await axios.post(
            `${window.BASEURL}${window.STUDENTSUBMITFORM}`,
            d,
            { headers: h }
          );
          console.log(data);
          if (data.result == "Success") {
            navigate("/educational-information");
            setIsLoading(false);
          } else {
            setError(data.errorMsgs);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setPinError("Pincode must have 6 digits only")
      }

    }
  };

  const uploadphoto = async () => {
    const h = {
      Authorization: `Bearer ${token}`,
    };

    // Remove the prefix from the selectedImage
    const base64Image = selectedImage ? selectedImage.split(",")[1] : "";

    const d = {
      Id: userId,
      img64: base64Image,
    };

    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.STUDENTPHOTO}`,
        d,
        { headers: h }
      );

      console.log(data);

      if (data) {
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  // Upload users image and refresh the page
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     setSelectedImage(reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };
  const inputFile = useRef(null);

  const handleImageChange = (event) => {
    console.log(event.target.files[0]);
    console.log(event.target.files[0]);
    console.log(event);
    const file = event.target.files[0];
    const reader = new FileReader();
    console.log(file && /\.(jpg|jpeg|png)$/i.test(file.name))
    if (file && /\.(jpg|jpeg|png)$/i.test(file.name)) {
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
    } else {
      // toastError('Please select a valid JPG, JPEG, or PNG file.');
      alert('Please select a valid JPG, JPEG, or PNG file.')
      // createNewInput();
      if (inputFile.current) {
        inputFile.current.value = "";
        inputFile.current.type = "text";
        inputFile.current.type = "file";
      }

    }
    // reader.onload = () => {
    //   setSelectedImage(reader.result);
    // };


  };




  const handleimg = async (e) => {
    e.preventDefault();
    await uploadphoto();
    refresh();
    // displayImageFromBackend();
  };

  const displayImageFromBackend = async () => {
    const imageElement = document.getElementById("image");

    if (imageElement && selectedImage) {
      imageElement.src = window.BASEURL2 + selectedImage;
    } else {
      console.log("Image element not found or no selected image.");
    }
  };

  // Call this function when you want to display the image from the backend
  displayImageFromBackend();

  // Refresh the page
  const refresh = () => window.location.reload(true);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container add-listings-box">
          <MyProfile value={0} />

          <h3>{translation("Studentinfo.stutitle")}</h3>
          <form onSubmit={profileSubmitForm}>
            <div className="row">
              <div className="col-lg-3" id="studentpic">
                <div>
                  <input
                    accept="image/*"
                    ref={inputFile}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    className="imageInput"
                    multiple
                    type="file"

                    onChange={handleImageChange}
                  />
                  {selectedImage ? (
                    <img id="image" key={imageKey} src={selectedImage} alt="Image" accept=".jpg, .jpeg, .png" />
                  ) : (
                    <img id="usimg" src={userimg} alt="Image" />
                  )}

                  {/* {selectedImage && (
                    <img id="image" key={imageKey} src={imageUrl} alt="Image" />
                  )}
                  <img id="" src={userimg} alt="Image" /> */}
                </div>
                <div>
                  <label htmlFor="raised-button-file">
                    <Button id="submitbtn" component="span">
                      {translation("Studentinfo.studentphotoupload")}
                    </Button>
                  </label>
                  <Button id="submitbtn" component="span" onClick={handleimg}>
                    {translation("Studentinfo.studentphotosubmit")}
                  </Button>
                </div>
              </div>
              <div className="col-lg-9" style={{ marginTop: 22 }}>
                <div className="row">
                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuname")}
                      value={name}
                      inputProps={{
                        maxLength: 40,
                        minLength: 2,
                        border: "1px solid green",
                      }}
                      onChange={(e) => {
                        handleName(e);
                        setName(e.target.value);
                      }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                    />
                    <span style={{ color: "red" }}>{nameError}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stumail")}
                      value={email}
                      onChange={(e) => {
                        isEmailValid(e);
                        setEmail(e.target.value);
                      }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                    />
                    <span style={{ color: "red" }}>{emailError}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuMobile")}
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                      inputProps={{ maxLength: 10, readOnly: true }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                    />
                    <span style={{ color: "red" }}>{mobileError}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuparent")}
                      value={parentName}
                      onChange={(e) => {
                        handleParentName(e);
                        setParentName(e.target.value);
                      }}
                      style={{ marginBottom: 30 }}
                      inputProps={{ maxLength: 41, minLength: 2 }}
                      sx={{ width: "100%" }}
                    />
                    <span style={{ color: "red" }}>{perror}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {translation("Studentinfo.stustate")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        onChange={(e) => {
                          onChangeState(e);
                          setAllTaluk([]);
                        }}
                        value={state}
                        label={translation("Studentinfo.stustate")}
                        style={{ marginBottom: 30 }}
                      >
                        <MenuItem value="">
                          <em>{translation("Studentinfo.stustate")}</em>
                        </MenuItem>
                        {allState &&
                          allState.map((state, index) => {
                            return (
                              <MenuItem key={index} value={state.id}>
                                {state.stateName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{st}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {translation("Studentinfo.stuDistrict")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        onChange={onChangeDistrict}
                        value={district}
                        label={translation("Studentinfo.stuDistrict")}
                        style={{ marginBottom: 30 }}
                      >
                        <MenuItem value="">
                          <em>{translation("Studentinfo.stuDistrict")}</em>
                        </MenuItem>
                        {allDistrict &&
                          allDistrict.map((dis, index) => {
                            return (
                              <MenuItem key={index} value={dis.id}>
                                {dis.districtName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{stdist}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {translation("Studentinfo.stuTaluk")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        onChange={(e) => setTaluk(e.target.value)}
                        value={taluk}
                        label={translation("Studentinfo.stuTaluk")}
                        style={{ marginBottom: 30 }}
                      >
                        <MenuItem value="">
                          <em>{translation("Studentinfo.stuTaluk")}</em>
                        </MenuItem>
                        {allTaluk &&
                          allTaluk.map((ta, id) => {
                            return (
                              <MenuItem key={ta.id} value={ta.id}>
                                {ta.blockName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <span style={{ color: "red" }}>{sttalukerr}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuAddress")}
                      onChange={(e) => {
                        addressValid(e);
                        setAddress(e.target.value);
                      }}
                      value={address}
                      inputProps={{ maxLength: 201, minLength: 2 }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                      readOnly
                    />
                    <span style={{ color: "red" }}>{addressError}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuCity")}
                      onChange={(e) => {
                        handleCity(e);
                        setCity(e.target.value);
                      }}
                      value={city}
                      inputProps={{ maxLength: 41, minLength: 2 }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                      readOnly
                    />
                    <span style={{ color: "red" }}>{cityError}</span>
                  </div>

                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "20px" }}
                  >
                    <TextField
                      id="outlined-required"
                      label={translation("Studentinfo.stuPincode")}
                      onChange={(e) => pinsss(e.target.value)}
                      // setPincode(e.target.value).trim()
                      value={pincode}
                      type="text"
                      InputLabelProps={{ color: "" }}
                      inputProps={{ maxLength: 10, minLength: 2 }}
                      style={{ marginBottom: 30 }}
                      sx={{ width: "100%" }}
                      readOnly
                    />
                    <span style={{ color: "red" }}>{pinError}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="forstudentinfo d-flex">
                <div className="forimg col-lg-4 col-md-6">
                  {" "}
                  <div className="col-lg-4 col-md-6"></div>
                </div>
              </div>
              <div className="form-outline"></div>
              <div className="col-lg-12 col-md-12">
                <div
                  className="form-group"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div className="profile-btn">
                    <button type="submit" name="Savebtn">
                      {translation("Studentinfo.stubtn")}
                    </button>
                    <button onClick={nav}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default StudentInformation;
