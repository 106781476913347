import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AboutMenuBlog from "../../../assets/Images/AboutUs/AboutMenuBlog.png";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import "../../../theme/css/Contactus.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/about.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
import OurTeams from "./OurteamsAbt";

import { useTranslation } from "react-i18next";

export default function AboutUsMenu() {



  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

 
  
  return (
    <>
      <div className="otherPageBanner contactBanners">
        <div className="text-center title">
          <h2>ABOUT US</h2>
          {/* <h6>We are specialize in startups</h6> */}
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu ">
          <Row>
            <Col xl={6} lg={6} md={6} sm={7}>
            {/* {...settings} */}
              <Slider {...settings}>
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  src={aboutus}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  // data-aos="flip-left"
                  src={aboutusImg1}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  // data-aos="flip-left"
                  src={aboutusImg3}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  // data-aos="flip-left"
                  src={aboutusImg4}
                />
              </Slider>
            </Col>
            <Col xl={6} lg={6} md={6} sm={5} className="colForResponsive">
              <div className="titleFont" style={{textAlign:'center',fontSize:30}}>
                <h3> {translation("HomePageAbout.Abouttitlemain")}</h3>
                {/* <h2> examate</h2> */}
              </div>
              <p style={{textAlign:'justify'}}>
              {translation("MainAbout.MainAboutpara")}
              </p>
            
            </Col>
          </Row>
        </div>
        <div className="aboutusMenuCounting">
          <Row>
            <Col xl={4} lg={4} md={4} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>1000+</h2>
              <h6>   {translation("MainAbout.MainAboutAcheive1")}</h6>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>800+</h2>
              <h6>   {translation("MainAbout.MainAboutAcheive2")}</h6>

            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>20000+</h2>
              <h6>   {translation("MainAbout.MainAboutAcheive3")}</h6>

            </Col>
            {/* <Col xl={3} lg={3} md={3} sm={6} xs={6} data-aos="zoom-in-up">
              <h2>250</h2>
              <h6>Award winning</h6>
            </Col> */}
          </Row>
        </div>

        {/* <AboutUsVideo /> */}
      </div>
      <div className="spacing">
      {/* <OurTeams /> */}
      </div>
      <div className="spacing">
        <Brandlogo />
      </div>
    </>
  );
}
