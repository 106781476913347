import React from "react";
import ExamRoomNav from "./ExamRoomNav";
import ExamRoomResult from "./ExamRoomResult";
import ExamFooter from "./ExamFooter";
// import ExamRoom2 from "../ExamRoom2/ExamRoom2";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ExamRoom2 from "../ExamRoom2/ExamRoom2";
// import { useLocation } from "react-router-dom";
import './Mainexam.css'
import BeforeUnloadComponent from "../../Refresh/BeforeUnloadComponent.js";
function MainExam() {

const location = useLocation();

if(location.pathname === "/examresult" ){
  window.history.pushState(null, null, location.href);
  window.onpopstate = function(event) {
    window.history.go(1);
  };
}
  
  
  return (
    <div  className="mai">
      <BeforeUnloadComponent />
      {/* <ExamRoomNav /> */}
      <ExamRoom2 />
      {/* <ExamFooter /> */}
    </div>
  );
}

export default MainExam;
