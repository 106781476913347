import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Service/AxiosInstance";
import axiosInstance2 from "../../Service/AxiosInstance2";
import "../../../src/components/pages/My Account/ChangePassword.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Login } from "../pages/validation/Validation";
import "./SignIn.css";

const FormSignIn = ({ submitForm, l, r }) => {

  const [allfield, setAllField] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [mobileno, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [otp2, setOtp2] = useState("");
  const [token, setToken] = useState([]);
  const [resendBtn, setResendBtn] = useState(false);
  const [resendButtonVisible, setResendButtonVisible] = useState(true);
  const [master, setMaster] = useState({
    username: null,
    password: null,
  });
  const Navigate = useNavigate();

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (token != null && token != undefined) {
      setToken(token);
    }
    if (sessionStorage.getItem("token")) {
      Navigate("/");
    }

  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    control,
  } = useForm({ resolver: yupResolver(Login) });

  // Nav Refresh
  const reload = () => {
    window.location.reload(true);
  };

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
    const toastError = (data) => {
      toast.error(data, {
        position: toast.POSITION.TOP_RIGHT,
      });
    };

  const forgetpasswordcall = () => {
    setOpen3(true);
    let number = localStorage.getItem("userNumber");
    if(number){
      setMobileNo(number);
    }
    setTimeout(() => {
      setResendBtn(true);
    }, 120000);
  };


  const handleChangeMobile = (e) => {
    const mobile = e.target.value;
    setMobileNo(mobile);
    const pattern = /^[0-9]{10}$/;

    if (!mobile.trim()) {
      setMobileError("Mobile number is required");
    } else if (!pattern.test(mobile)) {
      setMobileError("Please enter a valid 10-digit mobile number");
    } else {
      setMobileError("");
    }
  };

  const handleClickOpen = () => {
    const number = localStorage.getItem("userNumber");
    if (number && number.trim() !== "") {
      setMobileNo(number);
      setTimeout(() => {
        setResendBtn(true);
      }, 120000);
      setOpen3(true);
    }
  };


  const handleClose = () => {
    setOpen(false);
    setOpen3(false);
    setOpen4(false);
    setResendBtn(false);
    // setValue("username"," ")
    setMobileNo("")
    setMobileError("");
  };

  const funNotVerNumber = async (n) => {
    setOtp('')
    const d = {
      MobileNo: n,
    };
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.SIGNUPGETOTP}`,
        d
      );
      console.log(data);
      if(data){
        setOpen4(true);
        setTimeout(() => {
          setResendBtn(true);
        }, 120000);
      }else{
        toastError(data.errorMsgs[0])
      }
    
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen2 = async () => {
    if (!mobileno || mobileno.trim() === "") {
      toastError("Please enter a valid mobile number");
      return;
    }
    if (!mobileError) {
      const h = {
        "Content-Type": "application/json",
      };
      const d = {
        MobileNo: mobileno,
      };
      try {
        const { data } = await axios.post(
          `${window.BASEURL}${window.RESETPASSWORDGETOTP}`,
          d,
          { headers: h }
        );
        if (data.errorMsgs && data.errorMsgs.includes('This account is not registered.')) {
          toastError('This account is not registered.');
          return;
        }
        setOpen2(true);
        if (mobileno == "") {
          toastError("Pleae Enter Mobile No")
        } else {
          toastError(data.errorMsgs[0])
          return
        }

      } catch (error) {}
    } else {
      toastError("Please enter a valid mobile number");
      return;
    }
  };

  const SignupSubmit = async () => {
    const d = {
      OTPNo: otp,
      MobileNo: mobileno,
    };
    if(otp !==""){
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.SIGNINOTPVERIFICATION}`,
        d
      );
      setOtp2(data);
      console.log('====================================');
      console.log(data);
      console.log('====================================');
      if (data.result == "Success") {
        toastSuccess("Successfully Submited")
        setTimeout(function () {
          reload()
        }, 4000)
        localStorage.setItem("otpVerified",true);
        localStorage.removeItem("userNumber");
        Navigate("/login");
      } else {
        toastError(data.errorMsgs[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }else{
    toastError("Please Enter OTP");
  }
  };

  const ResendOTPforForgot = async (token) => {
    setResendButtonVisible(false);
    setTimeout(() => {
      setResendButtonVisible(true);
    }, 120000);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      MobileNo: mobileno,
    };
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.FORGOTPASSWORDRESENDOTP}`,
        d,
        { headers: h }
      );
    } catch (error) {
      console.log(error);
    }
  }

  const ResetPasswordOnclick = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      OTPNo: otp,
      MobileNo: mobileno,
    };
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.RESETPASSWORDSUBMIT}`,
        d,
        { headers: h }
      );
      if (data.result == "Success") {
        sessionStorage.setItem("otp", data.token);
        Navigate("/resetpassword");
      }
      if (otp == "") {
        toastError("Please Enter OTP")
      }
      else {
        toastError(data.errorMsgs[0])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen4(false);
  };


  const LoginForm = async (data) => {
    const inData = data
    setMobileNo(data.username);
    if (isValid) {
      const d = {
        username: data.username,
        password: data.password,
      };
      try {
        const { data } = await axios.post(`${window.BASEURL}${window.LOGIN}`, d);
        console.log(data);
        if (data.token != null) {
          toastSuccess("Successfully Submitted");
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("userId", data.userId);
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;
          axiosInstance2.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;

          Navigate("/TheExamMate");
          let ll = localStorage.setItem("ll", false);
          reload();
        } else {
          toastError(data.errorMsgs[0]);
          if (
            data.errorMsgs ==
            "Your OTP Verification is Pending. Please Verify OTP and Proceed with the Sign In."
          ) {
            setOpen2(false);
            // setOpen(true);
            funNotVerNumber(inData.username)
            const isTrue = localStorage.getItem("otpVerified")
            if (!isTrue) {
              setMobileNo(inData.username);
            }
          }
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ResendOtp = async (e) => {
    setResendButtonVisible(false);
    setTimeout(() => {
      setResendButtonVisible(true);
    }, 120000);
    try {
      let token = sessionStorage.getItem("f");
      console.log(token);
      const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const { data } = await axios.post(
        `${window.BASEURL}${window.RESENDOTP}`, { MobileNo: mobileno },
        { headers: h }
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  
  const funhome = () => {
    Navigate("/")
  }

  return (
    <div className="banimg">
      <ToastContainer />
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="  col-lg-6 col-md-4  d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
              <h1></h1>
              <h2></h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a className="glightbox btn-watch-video">
                  <span></span>
                </a>
              </div>
            </div>
            <div className="  col-lg-6 order-1 order-lg-2 hero-img">
              <div className="form-container">
                <div className="form-content-right">
                  <form
                    onSubmit={handleSubmit(LoginForm)}
                    className="form"
                    noValidate>
                    <h1 className="SignUpInTitle">
                      {" "}
                      {translation("Form.formsignin")}
                    </h1>
                    <h1>{translation("Form.formtitle")}</h1>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-phone icon" id="singninicon"></i>
                        <input
                          className="form-input input-field"
                          type="tel"
                          name="phone"
                          maxLength={10}
                          placeholder="Enter your Mobile Number"
                          {...register("username")}
                          error={errors.username?.message ? true : false}
                          id="MobileNo"
                        />
                      </div>
                      {errors.username?.message ? (
                        <span style={{ color: "red" }} className="error-span" >
                          {errors.username?.message}
                        </span>
                      ) : null}
                    </div>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-key icon" id="singninicon"></i>
                        <input
                          className="form-input input-field"
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          {...register("password")}
                          error={errors.password?.message ? true : false}
                          id="Password"
                        />
                      </div>
                      {errors.password?.message ? (
                        <span style={{ color: "red" }} className="error-span">
                          {errors.password?.message}
                        </span>
                      ) : null}
                    </div>

                    <form>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogContent>
                          <div className="manu-form manu-form2" style={{ padding: 40, width: '400px', height: '200px' }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6">
                                <h6 style={{ marginBottom: "20px" }}>Enter Mobile Number</h6>
                              </Typography>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                style={{ position: 'absolute', top: '10px', right: '15px' }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div className="box width-40">
                              <TextField
                                className="txtfeild"
                                fullWidth
                                id="outlined-basic"
                                maxLength={10}
                                type="number"
                                label="Mobile Number"
                                value={mobileno}
                                variant="outlined"
                                size="small"
                                onChange={handleChangeMobile}
                                error={!!mobileError} 
                                helperText={mobileError}

                              />
                            </div>
                            <div className="btn" style={{ marginLeft: 100 }}>
                              <Button onClick={funNotVerNumber} >
                                Get OTP
                              </Button>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </form>

                    <form>
                      <Dialog open={open3} onClose={handleClose}>
                        <DialogContent>
                          <div className="manu-form manu-form2" style={{ padding: 40, width: '400px', height: '200px' }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6">
                                <h6 style={{ marginBottom: "20px" }}>Enter Mobile Number</h6>
                              </Typography>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                style={{ position: 'absolute', top: '10px', right: '15px' }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div className="box width-40">
                              <TextField
                                className="txtfeild"
                                fullWidth
                                id="outlined-basic"
                                maxLength={10}
                                type="number"
                                label="Mobile Number"
                                value={mobileno}
                                variant="outlined"
                                size="small"
                                onChange={handleChangeMobile}
                                error={!!mobileError}
                                helperText={mobileError}

                              />
                            </div>
                            <div className="btn" style={{ marginLeft: 100 }}>
                              <Button onClick={handleClickOpen2}>
                                Get OTP
                              </Button>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </form>
                    <form>
                      <Dialog open={open2} onClose={handleClose2}>
                        <DialogContent>
                          <div className="manu-form manu-form2" style={{ padding: 40, width: '400px', height: '200px' }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6">
                                <h6 style={{ marginBottom: "10px" }}>Enter OTP Number</h6>
                              </Typography>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose2}
                                style={{ position: 'absolute', top: '10px', right: '15px' }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div>

                              <div
                                className="box width-40"
                                style={{ marginTop: 10 }}
                              >
                                <TextField
                                  className="txtfeild"
                                  style={{ fontSize: 50 }}
                                  fullWidth
                                  id="outlined-basic"
                                  maxLength={6}
                                  type="number"
                                  label="otp"
                                  value={otp}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="btn">
                              <Button
                                onClick={ResetPasswordOnclick}
                                style={{ marginLeft: 60 }}
                              >
                                submit
                              </Button>
                              {resendBtn && (
                                <Button
                                  onClick={ResendOTPforForgot}
                                  style={{ visibility: resendButtonVisible ? "visible" : "hidden" }}
                                >
                                  Resend OTP
                                </Button>
                              )}
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </form>

                    <form>
                      <Dialog open={open4} onClose={handleClose2}>
                        <DialogContent>
                          <div className="manu-form manu-form2" style={{ padding: 40, width: '400px', height: '200px' }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography variant="h6">
                                <h6 style={{ marginBottom: "10px" }}>Enter OTP Number</h6>
                              </Typography>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose2}
                                style={{ position: 'absolute', top: '10px', right: '15px' }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div>

                              <div
                                className="box width-40"
                                style={{ marginTop: 10 }}
                              >
                                <TextField
                                  className="txtfeild"
                                  style={{ fontSize: 50 }}
                                  fullWidth
                                  id="outlined-basic"
                                  maxLength={6}
                                  type="number"
                                  label="otp"
                                  value={otp}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="btn">
                              <Button
                                onClick={SignupSubmit}
                                style={{ marginLeft: 60 }}
                              >
                                Submit
                              </Button>
                              {resendBtn && (
                                <Button
                                  onClick={ResendOtp}
                                  style={{ visibility: resendButtonVisible ? "visible" : "hidden" }}
                                >
                                  Resend OTP
                                </Button>
                              )}
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </form>

                    <div>
                      {allfield.length > 0 && (
                        <span style={{ color: "red" }}>{allfield}</span>
                      )}
                    </div>
                    <button
                      className="form-input-btn"
                      type="submit"
                      name="LoginBtn"
                      onClick={handleClickOpen}
                    >
                      {translation("Form.formsignin")} 
                    </button>
                    <span className="form-input-login" style={{ fontSize: 15 }}>
                      {translation("Form.formpara")}{" "}
                      <span
                        style={{ color: "#27cdff" }}
                        // onClick={navigator}
                        onClick={funhome}
                        id="registerhere"
                      >
                        {translation("Form.formpara1")}{" "}
                      </span>
                    </span>
                    <span className="form-input-login">
                      <span
                        style={{ color: "#27cdff" }}
                        onClick={forgetpasswordcall}
                        id="forgetpassword">
                        {translation("Form.forgotpass")}
                      </span>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormSignIn;