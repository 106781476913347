import React from 'react'
import "./Terms.css"

function RefundPolicy() {
  return (
    <div >
<div className='privacy' style={{ textAlign: 'center', padding: '20px' }} >
<h3 >Refund & Cancellation Policy</h3>
<div className='container' id='terms' style={{ textAlign: 'justify', padding: '0' }}>
    <p><h5>No refund or cancellation is permitted once the user has completed a transaction at theexammate.com for exam packs. </h5></p>

    <p style={{textAlign:'justify'}}>
    User needs to contact customer support through the registered mobile number or e-mail to enable effective provision of Services. Request from alternate details will not be entertained.   </p>
    <p style={{textAlign:'justify'}}>
    It is Your sole responsibility to ensure that the account information provided by You is accurate, complete and latest.
    </p>
    <p style={{textAlign:'justify'}}>
    You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot guarantee accessibility to the Application at all times. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time.
    </p>


    <p style={{ textAlign: 'justify' }}>
          Cancellation can happen when a query is raised only with&nbsp;<br></br>
          <a href="mailto:customersupport@theexammate.com">customersupport@theexammate.com</a><br></br>
          &nbsp;or <a href="tel:+919169170918">+91 9169170918</a>
        </p>

    <p style={{textAlign:'justify'}}>
    Free look up period before applying for cancellation upgraded orders will be solely on primary order and not on upgraded order.
    </p>

    <p style={{textAlign:'justify'}}>
    Your Subscribed package will continue until terminated. Unless you cancel your subscription before your payment, you authorize us to charge the package amount for the next billing cycle to your Payment Method.
    </p>

    <p style={{textAlign:'justify'}}>
    Once you cancel your subscription, package amount will not be refunded.
    </p>

    <p style={{textAlign:'justify'}}>
    In case of subscription of packages of duration less than one year, the fees paid shall be non-refundable in all circumstances including but not limited to cancellation of subscription by the user, etc.
    </p>

    <p style={{textAlign:'justify'}}>
     At <b>TheExamMate,</b> the Pack expires as per the validity suggested at the time they are purchased. The subscription price will be made clear to you on our sign up pages or otherwise during the sign up process and may vary from time to time. You agree to pay at the rates notified to you at the time of your sign up. Sign up to premium content on our app is generally for a year. We may however choose to offer monthly payment options. If you choose to pay monthly, your subscription will continue until you tell us that you no longer wish to receive it, in which case you will stop paying the monthly amounts.
    </p>
 
 


</div>

    </div>        

    </div>
  )
}

export default RefundPolicy