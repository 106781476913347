import React from "react";
import { useNavigate  } from "react-router-dom";
import { useEffect ,useState} from "react";
import axios from "axios";

export default function AlertComponent() {

    const [data, setData] = useState(null);


    const alertStyle = {
        height: "50px",
        backgroundColor: "#fff",
        color: "#fff",
        display: "flex",
        alignItems: "center", // Vertically center content
        justifyContent: "space-between", // Space between close button and marquee
        padding: "0 15px",
        border:"solid 1px red", // Add some padding for spacing
        marginTop: "14px"


      };

      const closeButtonStyle = {
        border: "none",
        background: "none",
        cursor: "pointer",
        color: "#fff",
        fontSize: "20px",
      };
      const Navigate = useNavigate()
      useEffect(() => {
        const token = sessionStorage.getItem("token");
        const user_Id = sessionStorage.getItem("userId");
    
        if (token != null && user_Id != null) {
          getStudentInfo(token, user_Id);
        }
        }, []);
  
        const getStudentInfo = async (token, StudentId) => {
          console.log(StudentId);
          const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
          try {
            const { data: responseData } = await axios.get(
              `${window.BASEURL}${window.AUTOFILL}${StudentId}`
            );
            console.log(responseData);
      
            setData(responseData); // Store the fetched data in state
            // ... (rest of the code)
          } catch (error) {
            console.log(error);
          }
        };
  
        const shouldDisplayAlert = data && data.gradeId === 0;
    
 return (
    <div className="container">
    <div className="row">
      {shouldDisplayAlert && (
        <div className="alert alert-danger alert-dismissible" role="alert" style={alertStyle}>
     
            <p style={{ fontFamily: "Poppins", fontSize: "1rem", margin: 0,color:'red' }}>
              Please fill your details in the Profile page
            </p>
       
          <button
            type="button"
            onClick={(e) => e.target.closest(".alert").remove()}
            className="close"
            data-dismiss="alert"
            style={closeButtonStyle}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      )}
    </div>
  </div>
);

}
