import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SampleExam.css";
import { apiGet } from "../../../Service/Api";
import Swal from "sweetalert2";
import LoadingScreen from "../Loading/Loadingscreen";
import { useTranslation } from "react-i18next";

function SampleExam() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // Sample Exam //
  const [board, setBoard] = useState();
  const [allBoard, setAllboard] = useState([]);
  const [medium, setMedium] = useState();
  const [allMedium, setAllMedium] = useState([]);
  const [updboard, setUpdboard] = useState();
  const [grade, setGrade] = useState();
  const [allGrade, setAllGrade] = useState();
  const [updmedium, setUpdmedium] = useState();
  // Validation Error //
  const [boarderror, setBoardError] = useState("");
  const [mediumerror, setMediumError] = useState("");
  const [gradeerror, setGradeError] = useState("");
  const [loading, setLoading] = useState(false);

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    getBoard();
    getMedium(updboard);

    const tok = sessionStorage.getItem("token");
    console.log(tok);
    console.log(board);

    const userId = sessionStorage.getItem("userId");
    if (tok != null || tok != undefined) {
      console.log(tok);
    }
  }, [updboard]);

  const getBoard = async (token) => {
    const h = {
      //  "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      console.log(window.BOARD);
      const { data } = await axios.get(`${window.BASEURL}${window.BOARD}`);
      console.log(data);
      setAllboard(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMedium = async (updboard) => {
    console.log(updboard);
    const h = {
      // "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.MEDIUM + updboard}`
      );
      console.log(data);
      setAllMedium(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getGrade = async (updmedium) => {
    console.log(updmedium);
    const h = {
      // "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.GRADE + updmedium}`
      );
      console.log(data);
      setAllGrade(data);
    } catch (error) {
      console.log(error);
    }
  };

  const sampleQuestion = async (e) => {
    e.preventDefault();
    console.log(window.BASE);
    const h = {
      // "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(updboard, medium, grade);
    const d = {
      BoardId: parseInt(updboard),
      MediumId: parseInt(medium),
      GradeId: parseInt(grade),
    };

    if (updboard == "" || updboard == undefined || updboard == null) {
      setBoardError("Please Select Board");
    }
    if (medium == "" || medium == undefined || medium == null) {
      setMediumError("Please Select Medium");
    }
    if (grade == "" || grade == undefined || grade == null) {
      setGradeError("Please Select Grade");
    } else {
      setError("");
      try {
        setLoading(true);
        console.log(window.EXAMLIST);
        const { data } = await axios.post(
          `${window.EXAMURL}${window.EXAMLIST}`,
          d
        );
        console.log(data);
        if (data && data.length > 0) {
          navigate("/sample-result", { state: data });
        } else {
          // Add a 2-second timeout before showing the Swal alert
          setTimeout(() => {
            Swal.fire({
              icon: "warning",
              title: "No Questions Available",
              text: "There are no questions available for the selected Boards.",
            });
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  // const sampleQuestion = async (e) => {
  //     e.preventDefault()
  //     console.log(window.BASE);
  //     const h = {
  //         // "Authorization": `Bearer ${token}`,
  //         "Content-Type": 'application/json'
  //     }
  //     console.log(updboard,medium,grade);
  //     const d = {
  //         BoardId: parseInt(updboard),
  //         MediumId: parseInt(medium),
  //         GradeId: parseInt(grade),
  //     }

  //     if(updboard == "" || updboard == undefined || updboard == null){
  //         setBoardError("Please Select Board")
  //     }
  //     if(medium == "" || medium == undefined || medium == null){
  //         setMediumError("Please Select Medium")
  //     }
  //     if(grade == "" || grade == undefined || grade == null){
  //         setGradeError("Plese Select Grade")
  //     }

  //     else {
  //         setError("")
  //         try {
  //             console.log(window.EXAMLIST);
  //             const { data } = await axios.post(`${window.EXAMURL}${window.EXAMLIST}`,d)
  //             console.log(data)
  //             if(data){
  //                 navigate("/sample-result",{state:data})
  //             }
  //         } catch (error) {
  //             console.log(error)
  //         }
  //     }
  // }

  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="container add-listings-box">
          <div className="row">
          <div className="col-lg-4" ></div>
          <div className="col-lg-4" >
          <form onSubmit={sampleQuestion}>
            <div class="row">
            
           
            
                <div class="form-group">
                <p> {translation("Exams.ExamBoard")}:</p>
                  <select
                    class="dashbaord-category-select"
                    onChange={(e) => {
                      setUpdboard(e.target.value);
                      setUpdmedium(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option>{translation("Exams.ExamBoardselect")}</option>
                    {console.log({ allBoard })}
                    {allBoard?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.boardName}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red" }}>{boarderror}</span>
                </div>
           
             
            
               
             
                <div class="form-group">
                <p> {translation("Exams.ExamMedium")}:</p>
                  <select
                    class="dashbaord-category-select"
                    onChange={(e) => {
                      getGrade(e.target.value);
                      setMedium(e.target.value);
                    }}
                  >
                    <option value="">{translation("Exams.ExamMediumselect")}</option>
                    {console.log({ allMedium })}
                    {allMedium &&
                      allMedium.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.mediumName}
                          </option>
                        );
                      })}
                  </select>
                  <span style={{ color: "red" }}>{mediumerror}</span>
                </div>
            
         

         
                <div class="form-group">
                <p> {translation("Exams.ExamGrade")}:</p>
                  <select
                    class="dashbaord-category-select"
                    onChange={(e) => setGrade(e.target.value)}
                  >
                    <option value="">{translation("Exams.ExamGradeselect")}</option>
                    {console.log({ allGrade })};
                    {allGrade &&
                      allGrade.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.grade}
                          </option>
                        );
                      })}
                  </select>
                  <span style={{ color: "red" }}>{gradeerror}</span>
                </div>
          
              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {error.length > 0 && <span className="error">{error}</span>}
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button className="loginBtn" type="submit">
                  {translation("Exams.ExamSubmit")}
                  </button>
                </div>
              </div>
            </div>
          </form>

            
          </div>
          <div className="col-lg-4" ></div>
          </div>
        </div>
        // <div class="container add-listings-box">
        //   <h3>Sample Exam</h3>
        //   <form onSubmit={sampleQuestion}>
        //     <div class="row">
        //       <div className="col-lg-4 col-md-6 col-sm-6"></div>
        //       <div class="col-lg-2 col-sm-6 col-md-4">
        //         <div class="form-group">
        //           <h4> {translation("Exams.ExamBoard")}:</h4>
        //         </div>
        //       </div>
        //       <div class="col-lg-3 col-sm-6 col-md-6">
        //         <div class="form-group">
        //           <select
        //             class="dashbaord-category-select"
        //             onChange={(e) => {
        //               setUpdboard(e.target.value);
        //               setUpdmedium(e.target.value);
        //               console.log(e.target.value);
        //             }}
        //           >
        //             <option>{translation("Exams.ExamBoardselect")}</option>
        //             {console.log({ allBoard })}
        //             {allBoard?.map((item, index) => {
        //               return (
        //                 <option value={item.id} key={index}>
        //                   {item.boardName}
        //                 </option>
        //               );
        //             })}
        //           </select>
        //           <span style={{ color: "red" }}>{boarderror}</span>
        //         </div>
        //       </div>
        //       <div className="col-lg-3 col-sm-6 col-md-6"></div>
        //       <div className="col-lg-4 col-md-6 col-sm-6"></div>
        //       <div class="col-lg-2 col-sm-6 col-md-4">
        //         <div class="form-group">
        //         <h4> {translation("Exams.ExamMedium")}:</h4>

        //         </div>
        //       </div>
        //       <div class="col-lg-3 col-sm-6 col-md-6">
        //         <div class="form-group">
        //           <select
        //             class="dashbaord-category-select"
        //             onChange={(e) => {
        //               getGrade(e.target.value);
        //               setMedium(e.target.value);
        //             }}
        //           >
        //             <option value="">{translation("Exams.ExamMediumselect")}</option>
        //             {console.log({ allMedium })}
        //             {allMedium &&
        //               allMedium.map((item, index) => {
        //                 return (
        //                   <option value={item.id} key={index}>
        //                     {item.mediumName}
        //                   </option>
        //                 );
        //               })}
        //           </select>
        //           <span style={{ color: "red" }}>{mediumerror}</span>
        //         </div>
        //       </div>
        //       <div className="col-lg-3 col-sm-6 col-md-6"></div>
        //       <div className="col-lg-4 col-md-6 col-sm-6"></div>
        //       <div class="col-lg-2 col-sm-6 col-md-4">
        //         <div class="form-group">
        //         <h4> {translation("Exams.ExamGrade")}:</h4>

        //         </div>
        //       </div>

        //       <div class="col-lg-3 col-sm-6 col-md-6">
        //         <div class="form-group">
        //           <select
        //             class="dashbaord-category-select"
        //             onChange={(e) => setGrade(e.target.value)}
        //           >
        //             <option value="">{translation("Exams.ExamGradeselect")}</option>
        //             {console.log({ allGrade })};
        //             {allGrade &&
        //               allGrade.map((item, index) => {
        //                 return (
        //                   <option value={item.id} key={index}>
        //                     {item.grade}
        //                   </option>
        //                 );
        //               })}
        //           </select>
        //           <span style={{ color: "red" }}>{gradeerror}</span>
        //         </div>
        //       </div>
        //       <div class="col-lg-12 col-md-12">
        //         <div
        //           class="form-group"
        //           style={{ display: "flex", justifyContent: "center" }}
        //         >
        //           {error.length > 0 && <span className="error">{error}</span>}
        //         </div>
        //       </div>
        //       <div class="col-lg-12 col-md-12">
        //         <div
        //           class="form-group"
        //           style={{ display: "flex", justifyContent: "center" }}
        //         >
        //           <button className="loginBtn" type="submit">
        //           {translation("Exams.ExamSubmit")}
        //           </button>
        //         </div>
        //       </div>
        //     </div>
        //   </form>
        // </div>
      )}
    </div>
  );
}

export default SampleExam;
