import React, { useState } from "react";
// import validate from "./validateInfo";
// import useForm from "./useForm";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Dialog, DialogContent, IconButton, TextField } from "@mui/material";
import { Token } from "@mui/icons-material";
import { useEffect } from "react";
// import './Form.css';
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import FormSignup from "../../SignUp/FormSignup";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidation } from "../validation/Validation";
import { useForm } from "react-hook-form";

const BannerForm = ({ submitForm, l }) => {

  const [formdata, setFormData] = useState(
    {
      name: "",
      email: "",
      mobile: "",
      password: "",
    }
  )

  const [translation, i18n] = useTranslation("global");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [allfield, setAllField] = useState("");

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [resendBtn, setResendBtn] = useState(false);
  const [resendButtonVisible, setResendButtonVisible] = useState(true);

  const [mobileno, setMobileNo] = useState("");
  // const [mobilenoerror,setMobileNoError] = useState("")
  const [otp, setOtp] = useState("");
  const [otp2, setOtp2] = useState("");

  // const [resotp,set]

  const [f, setF] = useState("");
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    formState: {errors, isValid },
    setValue,
    reset,
    control,
  } = useForm({ resolver: yupResolver(registerValidation) });


  // Nav Refresh
  const reload = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    // if (localStorage.getItem("userNumber") !== null) {
    //   let number = localStorage.getItem("userNumber");
    //   setMobileNo(number);
    // }
  });

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleClickOpen = () => {
    const mobilePattern = /^[0]?[6789]\d{9}$/;
    if (name.trim() === "") {
      console.log("Name is required.");
    }
    if (mobile.trim() === "") {
      console.log("Mobile is required.");
    } else if (!mobilePattern.test(mobile)) {
      console.log("Invalid mobile number. Please enter a 10-digit valid mobile number.");
    }
    if (password.trim() === "") {
      console.log("Password is required.");
    }
    if (
      name.trim() !== "" &&
      mobile.trim() !== "" &&
      mobilePattern.test(mobile) &&
      password.trim() !== ""
    ) {
      // setOpen(true);
      // setTimeout(() => {
      //   setsetResendBtn(true);
      // }, 120000);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen2 = async (e) => {
    const h = {
      Authorization: `Bearer ${f}`,
      "Content-Type": "application/json",
    };
    const d = {
      MobileNo: mobileno,
    };
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.SIGNUPGETOTP}`,
        d,
        { headers: h }
      );
      console.log(data);
      setOpen2(true);
      setOpen2(data);
    } catch (error) {
      console.log(error);
    }
  };

  const registerForm = async(data) => {
    if (isValid) {
      const d = {
        Name: data.name,
        EmailId: data.email,
        MobileNo: data.mobile,
        Password: data.password,
      };
      setMobileNo(data.mobile)

      try {

        const { data } = await axios.post(
          `${window.BASEURL}${window.STUDENTSIGNUP}`,
          d
        );
        if (data.token != null) {
          sessionStorage.setItem("register",true);
          sessionStorage.setItem("registerToken",data.token);
          localStorage.setItem("otpVerified",false);
          sessionStorage.setItem("f", data.token);
          setF(data.token);
          sessionStorage.setItem("userId", data.userId);
          localStorage.setItem("userNumber", mobile);
          funRegisterOtp()
          // handleClickOpen()
        } else {
          toastError(data.errorMsgs[0]);
          if (data.errorMsgs[0]) {
            handleClose();
          }
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }

    }
  }


  const funRegisterOtp = () => {
    setOpen(true);
    setTimeout(() => {
      setResendBtn(true);
    }, 120000);
  }

  const signupOtpSubmit = async() => {
    let token = sessionStorage.getItem("registerToken");
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const isMax = otp.length > 6
    const isMin = otp.length < 6
    if(otp !==""){
      const d = {
        OTPNo: otp,
      };
      try {
        const { data } = await axios.post(
          `${window.BASEURL}${window.SIGNUPSUBMIT}`,
          d,
          { headers: h }
        );
        console.log(data);
        if (data.result == "Success") {
          sessionStorage.setItem("otpToken",data.token);
          localStorage.setItem("otpVerified",true);
          navigate("/student-information");
          navigate("/login");
          localStorage.removeItem("userNumber");
          reload();
        }  else {
          toastError(data.errorMsgs[0]);
        }
      } catch (error) {
        console.log(error);
      }
    }else{
      toastError("Please Enter OTP");
    }
  
  }

  const signupResendOtpSubmit = async() => {
    setResendButtonVisible(false);
    setTimeout(() => {
      setResendButtonVisible(true);
    }, 120000);
    const token = sessionStorage.getItem("registerToken");
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASEURL}${window.RESENDOTP}`,
        { MobileNo: mobileno },  
        { headers: h }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="banimg">
      <ToastContainer />
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="  col-lg-6 col-md-4  d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
           
            <div className="banner-all-details d-none d-md-block">
                <div className="text-width">
                  <h2 className="text-capitalize">
                    {translation("banner.bannertitle")}
                  </h2>
                  <h3 className="text-capitalize"></h3>
                  <p>{translation("banner.bannerparagraph")}</p>
                  <button className=" button">
                    <Link to="/aboutus">
                      {translation("banner.bannerbutton")}
                    </Link>
                  </button>
                </div>
              </div>
          
            </div>


            <div className="  col-lg-6 order-1 order-lg-2 hero-img">
              <div className="form-container">
                <div className="form-content-right">
                <form onSubmit={handleSubmit(registerForm)} className="form" noValidate>
                    <h1 className="SignUpInTitle">Sign Up</h1>
                    <h1>Get started with us today!</h1>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-user icon"></i>
                        <input
                          className="form-input input-field"
                          type="text"
                          name="username"
                          maxLength={40}
                          {...register("name")}
                          placeholder="Enter your Username"
                          id="userName"
                        />
                      </div>
                  {errors?.name && <span style={{ color: "red" }} className="error-span">{errors?.name?.message}</span>}    
                    </div>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-envelope icon"></i>
                        <input
                          className="form-input input-field"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          {...register("email")}
                          id="userEmail"
                        />
                      </div>
                      {errors?.email && <span style={{ color: "red" }} className="error-span">{errors?.email?.message}</span>}  
                    </div>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-phone icon"></i>
                        <input
                          className="form-input input-field"
                          type="phone"
                          name="phone"
                          {...register("mobile")}
                          placeholder="Enter your Mobile Number"
                          maxLength={10}
                          id="userMobile"
                        />
                      </div>
                      {errors?.mobile && <span style={{ color: "red" }} className="error-span">{errors?.mobile?.message}</span>}  
                    </div>

                    <div className="form-inputs">
                      <div className="input-container">
                        <i className="fa fa-key icon"></i>
                        <input
                          className="form-input input-field"
                          type="password"
                          name="password"
                          {...register("password")}
                          placeholder="Enter your password"
                          pattern=" /^\S+$/"
                          id="userPassword"
                        />
                      </div>
                      {errors?.password && <span style={{ color: "red" }} className="error-span">{errors?.password?.message}</span>}  
                    </div>

                    <form>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogContent>
                          <div
                            className="manu-form manu-form2"
                            style={{
                              padding: 40,
                              width: "400px",
                              height: "200px",
                            }}
                          >
                            <div>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "15px",
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                              <div
                                className="box width-40"
                                style={{ marginTop: 10 }}
                              >
                                <TextField
                                  className="txtfeild"
                                  fullWidth
                                  id="outlined-basic"
                                  type="number"
                                  InputProps={{
                                    maxLength: 6,
                                  }}
                                  label="otp"
                                  value={otp}
                                  variant="outlined"
                                  size="small"
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="btn">
                              <Button
                                onClick={signupOtpSubmit}
                                style={{ marginLeft: 50 }}
                              >
                                Submit
                              </Button>
                              {resendBtn && (
                                <Button
                                  onClick={signupResendOtpSubmit}
                                  style={{ visibility: resendButtonVisible ? "visible" : "hidden" }}
                                >
                                  Resend OTP
                                </Button>
                              )}
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </form>

                    <div>
                      {allfield.length > 0 && (
                        <span style={{ color: "red" }}>{allfield}</span>
                      )}
                    </div>
                    <button
                      className="form-input-btn"
                      type="submit"
                      id="signupbtn"
                    >
                      Sign Up
                    </button>
                    <span className="form-input-login">
                      Already have an account? Login{" "}
                      <span style={{ color: "white" }} onClick={(e) => navigate("/login")}>
                        here
                      </span>
                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BannerForm;