import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import ThemeLogo from "../../assets/Images/BrandLogo/TheExamMateLogo.png";
import "./footer.css";
import { useTranslation } from "react-i18next";
import CircleIcon from '@mui/icons-material/Circle';

export const Footer = () => {
  const [Useopen, UsesetOpen] = useState(false);
  const [Copen, CsetOpen] = useState(false);
  const [Aopen, AsetOpen] = useState(false);

  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  

  const currentdate = new Date().getFullYear();

  return (
    <>
      <div className="footer">
        <Container>
          <hr style={{ color: "#ccc",marginBottom:60 }}></hr>
          <Row className="Footerbox">
            <Col xl={5} lg={5} md={4} sm={12}>
              <div className="F-col-1 footer-text">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                  style={{ width: "190px" }}
                />
                <p style={{ textAlign: "justify" }}>
                  {translation("Footer.FooterPara")}
                </p>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div
                className="F-col-2 footer-text FooterVertical"
                id="footertitles"
                style={{ marginBottom: 20 }}
              >
                <h3 className=" footerLinkHeading" style={{ color: "black" }}>
                {translation("Footer.FootersubTitle")}{" "}
                  <Button
                    className="Footer-button"
                    onClick={() => UsesetOpen(!Useopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Useopen}
                  ></Button>
                </h3>
                <Collapse in={Useopen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/aboutus"> <CircleIcon style={{ fontSize: '10px',margin:10 }} /> {translation("navbar.aboutus")}{" "}</Link>
                    </li>
                    {/* <li>
                      <Link to="/planing">Careers</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Media</Link>
                    </li> */}
                    <li>
                      <Link to="/Sitemap"><CircleIcon style={{ fontSize: '10px',margin:10 }} />
 {translation("Footer.FooterSitemap")}{" "}</Link>
                    </li>
                    <li>
                      <Link to="/Privacy"> <CircleIcon style={{ fontSize: '10px',margin:10 }} />
 {translation("Footer.Footerprivacy")}{" "}</Link>
                    </li>
                    <li>
                      <Link to="/Terms"> <CircleIcon style={{ fontSize: '10px',margin:10 }} />
 {translation("Footer.Footerterms")}{" "}</Link>
                    </li>
                    <li>
                      <Link to="/RefundPolicy"> <CircleIcon style={{ fontSize: '10px',margin:10 }} />
 {translation("Footer.FooterRefund")}{" "}</Link>
                    </li>
                    <li>
                      <Link to="/contact"> <CircleIcon style={{ fontSize: '10px',margin:10 }} />
  {translation("navbar.contact")}{" "}</Link>
                    </li>
                    <li>
                      <Link to="/faq"> <CircleIcon style={{ fontSize: '10px',margin:10 }} />
 {translation("Footer.FooterFaq")}{" "}</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2}>
              <div className="F-col-3 footer-text FooterVertical">
                <h3 className=" footerLinkHeading" style={{ color: "black" }}>
                {translation("Footer.FootersubTitle2")}{" "}
                  <Button
                    className="Footer-button"
                    onClick={() => CsetOpen(!Copen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Copen}
                  ></Button>
                </h3>
                <Collapse in={Copen}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to=""><CircleIcon style={{ fontSize: '10px',margin:10 }} />CBSE 12th</Link>
                    </li>
                    <li>
                      <Link to=""><CircleIcon style={{ fontSize: '10px',margin:10 }} />CBSE 11th</Link>
                    </li>
                    <li>
                      <Link to=""><CircleIcon style={{ fontSize: '10px',margin:10 }} />CBSE 10th</Link>
                    </li>
                    <li>
                      <Link to=""><CircleIcon style={{ fontSize: '10px',margin:10 }} />CBSE 9th</Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={12}>
              <div className="F-col-4 footer-text FooterVertical ">
                <h3 className="footerLinkHeading" style={{ color: "black" }}>
                {translation("navbar.contact")}{" "}
                  <Button
                    className="Footer-button"
                    onClick={() => AsetOpen(!Aopen)}
                    aria-controls="example-collapse-text"
                    aria-expanded={Aopen}
                  ></Button>
                </h3>
                <Collapse in={Aopen}>
                  <div className="quickContact">
                    <div className="contactUsIconContain">
                      <i className="fa fa-map-marker"></i>
                      <span>
                        <p>
                        TheExamMate <br></br> {/*  <br></br>1728 Workspaces, 1st Floor, 5057,
                          21st Main Road, G - Block, Anna Nagar, Chennai -
                          600040. */}
                {translation("Footer.FooterAddress")}{" "}

                        </p>
                      </span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-phone-alt"></i>
                      <span>+91 9169170918</span>
                    </div>
                    <div className="contactUsIconContain">
                      <i className="fas fa-envelope"></i>
                      <span> reachout@theexammate.com</span>
                    </div>
                  </div>
                </Collapse>
                <ul className="list-unstyled social-icon">
                  {/* <li className="d-inline-block">
                    <a href="https://www.facebook.com/theexammate" className="d-block"  target="blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li> */}
                  {/* <li className="d-inline-block">
                    <a href="/" className="d-block">
                      <i className="fa fa-google"></i>
                    </a>
                  </li> */}
                  <li className="d-inline-block"> 
                    <a href="https://twitter.com/TheExammate" className="d-block"  target="blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://www.linkedin.com/company/theexammate" target="blank" className="d-block">
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="d-inline-block">
                    <a href="https://instagram.com/theexammate" target="blank" className="d-block">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <h6 className="F-copywrite">
          Copyright © {currentdate} . All Rights Reserved
        </h6>
      </div>
    </>
  );
};
export default Footer;
