import React from 'react'
import "./Terms.css"

function PrivacyPolicy() {
  return (
    <div>
<div className='privacy' style={{ textAlign: 'center', padding: '20px' }} >
<h3 >Privacy Policy</h3>
<div className='container' id='terms' style={{ textAlign: 'justify', padding: '0' }}>
    <p><h5>At TheExamMate, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide to us through our examination platform website. By using our website and services, you consent to the practices described in this Privacy Policy.</h5></p>

    <p style={{textAlign:'justify'}}>
    Information Collection: We may collect personal information such as your name, email address, and other relevant details when you register an account or use our examination platform. Additionally, we may collect non-personal information such as device information, IP address, and usage data to improve our services.
    </p>
    <p style={{textAlign:'justify'}}>
   Use of Information: We use the collected information to provide and enhance our examination platform services, personalize user experience, communicate with you, process payments, and improve our website's functionality and security. We may also use aggregated and anonymized data for statistical analysis and research purposes.
    </p>
    <p style={{textAlign:'justify'}}>
    Data Protection: We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We restrict access to your information to authorized personnel only and utilize encryption technologies to safeguard sensitive data.
    </p>


    <p style={{textAlign:'justify'}}>
   Third-Party Disclosure: We do not sell, trade, or transfer your personal information to third parties without your consent, except for trusted service providers who assist us in operating our platform and conducting business, subject to confidentiality obligations.
    </p>


    <p style={{textAlign:'justify'}}>
   Cookies and Tracking Technologies: Our website may use cookies and similar tracking technologies to enhance your browsing experience, analyze usage patterns, and deliver targeted advertisements. You can control cookie preferences through your browser settings
    </p>


    <p style={{textAlign:'justify'}}>
   Children's Privacy: Our services are not intended for individuals under the age of 13. We do not knowingly collect or store personal information from children without parental consent. If you believe we have inadvertently collected information from a child, please contact us to have it removed.
    </p>
    <p style={{textAlign:'justify'}}>
    Updates to Privacy Policy: We may update this Privacy Policy periodically. It is your responsibility to review the policy for any changes. Continued use of our examination platform after modifications implies acceptance of the updated Privacy Policy.
    </p>


    <p style={{textAlign:'justify'}}>
    Contact Us: If you have any questions, concerns, or requests regarding our Privacy Policy or the handling of your personal information, please contact us through the provided channels.
    </p>

    <p style={{textAlign:'justify'}}>
    By using our examination platform website, you acknowledge and agree to the terms outlined in this Privacy Policy.
    </p>



</div>

    </div>        

    </div>
  )
}

export default PrivacyPolicy