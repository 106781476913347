import { useEffect } from "react";
import './StudentDashBoard.css';
import { CanvasJSChart } from 'canvasjs-react-charts'
import { useNavigate } from "react-router-dom";
import Performance from "../MyPerformance/ExamResult/Performance";

const StudentDashBoard = () => {
    const navigate = useNavigate()
    

    useEffect(()=>{
        if(!sessionStorage.getItem("token")){
            navigate("/")
        }
    },[])

    const options = {
        title: {
            text: "Basic Column Chart"
        },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints: [
                    { label: "Sub 1", y: 10 },
                    { label: "Sub 2", y: 15 },
                    { label: "Sub 3", y: 25 },
                    { label: "Sub 4", y: 40 },
                    { label: "Sub 5", y: 28 }
                ]
            }
        ]
    }
    return (
        <div>
            <div class="container student-dashboard">  
                <h3>Student Dashbaord</h3>
                <form>
                <Performance/>
                    <div class="row p-2" style={{ display: "flex", alignItems: "center" }}>
                        <div class="col-lg-6 col-md-12">
                            <div>
                                <CanvasJSChart options={options} />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className="important-date">
                                <h5>Next Important Date:</h5>
                                <div className="annual-sec">
                                    <dv><h6>Annual Exam :</h6></dv>
                                    <dv><p>12-03-2023</p></dv>
                                </div>
                                <div className="neet-sec">
                                    <div><h6>NEET Exam :</h6></div>
                                    <div><p>12-03-2023</p></div>
                                </div>
                                <div className="iit-sec">
                                    <div><h6>IIT Exam :</h6></div>
                                    <div><p>12-03-2023</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default StudentDashBoard;