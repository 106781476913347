import React from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import sideblogimg from "../../../assets/Images/Blog/sideblogimg.jpg";
import Progress from "../Service/Progress";
import blogpageimg from "../../../assets/Images/Blog/blogpageimg.jpg";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
export const BlogPage = () => {
  const [BlogData, setBlogData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    getallblog();
  }, []);

  const filteredBlog = BlogData.find((item) => item.id === parseInt(id));


  const getallblog = async () => {
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.BLOG}`);
      console.log(data);
      setBlogData(data);
    } catch (error) {
      console.log("An error occurred:", error.message);
      if (error.response) {
        console.log("Response:", error.response.data);
      }
    }
  };
  return (
    <>
    {filteredBlog &&(
      <div className="blogpage">
        <div className="container">
          <div className="spacing">
            <Row>
      
                <Col xl={8} lg={8} md={12}>
                  <div className="blogpageFstContainBlog">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: filteredBlog.title,
                      }}
                    />
                   
                    <img
                      className="img-fluid mx-auto"
                      variant="top"
                      src={window.BASEURL2 + filteredBlog.blogImage}
                      alt="blogpage"
                    />
                    <h5>   {filteredBlog.authorName} </h5>
                    {/* <div className="d-inline ourBlogContain"> */}
                     
             
                     <span>{filteredBlog.blogDate.substring(0, 10)}</span>
                     
                   {/* </div> */}
                    <p className="paragraph">
                    <div
                    style={{textAlign:"justify"}}
                      dangerouslySetInnerHTML={{
                        __html: filteredBlog.content,
                      }}
                    />
                    </p>
                  </div>
                  <hr />
             
             

                  {/* <div className="Course-review">
                    <h3>Reviews</h3>
                    <div className="Review-box">
                      <i className="Review-icon-person">
                        <i className="fas fa-user-circle"></i>
                      </i>
                      <div className="Review-author">
                        <div>
                          <h6>Jone Smit </h6>
                        </div>
                        <div className="Course-review-star">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                        </div>
                      </div>
                      <div className="Review-content">
                        <p className="paragraph">
                          The course identify things we want to change and then
                          figure out the things that need to be done to create
                          the desired outcome. The course helped me in clearly
                          define problems and generate a wider variety of
                          quality solutions. Support more structures analysis of
                          options leading to better decisions.
                        </p>
                      </div>
                    </div>
                    <div className="Review-box">
                      <i className="Review-icon-person">
                        <i className="fas fa-user-circle"></i>
                      </i>
                      <div className="Review-author">
                        <div>
                          <h6>Mark Rose </h6>
                        </div>
                        <div className="Course-review-star">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="far fa-star"></i>
                          <i className="far fa-star"></i>
                        </div>
                      </div>
                      <div className="Review-content">
                        <p className="paragraph">
                          At one extremity the rope was unstranded, and the
                          separate spread yarns were all braided and woven round
                          the socket of the harpoon; the pole was then driven
                          hard up into the socket..
                        </p>
                      </div>
                    </div>
                  </div> */}
                </Col>
             

              <Col xl={4} lg={4} md={12}>
                <div className="blogSticky">
                  <div className="startedEmailBox">
                    {/* <InputGroup className="">
                      <FormControl placeholder="Your Email..." />
                      <button className="button" style={{margin:0}}>
                        <Link to="/"> Subscribe </Link>
                      </button>
                    </InputGroup> */}
                  </div>
                  <div className="blogsidefstpart">
                    <Row>
                      <Col xl={12} lg={12} md={6}>
                        <img
                          className="img-fluid mx-auto"
                          src={sideblogimg}
                          alt="sideblog"
                        />
                      </Col>
                      <Col xl={12} lg={12} md={6}>
                        <div className="blogSlider">
                          <div className="scrollbar">
                            <div>
                              <h3>Values of Results</h3>
                              <Progress done="70" />
                              <h3>global experience</h3>
                              <Progress done="61" />
                              <h3>expect perspective</h3>
                              <Progress done="83" />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12} lg={12} md={6}>
                        <div className="followUs">
                          <h2>Follow Us</h2>
                          <Row>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div className="blogpageIcon">
                                <i className="fa fa-facebook"></i>
                                <span>
                                  Likes <b>2640</b>
                                </span>
                              </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div className="blogpageIcon">
                                <i className="fab fa-twitter"></i>
                                <span>
                                  Followers <b>2940</b>
                                </span>
                              </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div className="blogpageIcon">
                                <i className="fab fa-youtube"></i>
                                <span>
                                  Followers <b>2040</b>
                                </span>
                              </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                              <div className="blogpageIcon">
                                <i className="fab fa-instagram"></i>
                                <span>
                                  Followers <b>1540</b>
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={6}>
                        <div className="popularTags">
                          <h2>Popular Tags</h2>
                          <div className="sideblogbtn">
                            <button className="button">
                              <Link to="/blog">Science </Link>
                            </button>
                            <button className="button">
                              <Link to="/blog">Politics</Link>
                            </button>
                            <button className="button">
                              <Link to="/blog">Business</Link>
                            </button>
                            <button className="button">
                              <Link to="/blog">Sports </Link>
                            </button>
                            <button className="button">
                              <Link to="/blog">Technology</Link>
                            </button>
                            <button className="button">
                              <Link to="/blog"> Food </Link>
                            </button>
                            <button className="button">
                              <Link to="/blog"> FAQ </Link>
                            </button>
                            <button className="button">
                              <Link to="/blog"> Services </Link>
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )}
      
    </>
  );
};
