import React, { useState } from "react";
import "./FAQFINAL.css"


const AccordionComponent  = () => {


    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
  return (
    <div className="our-faq-wrapper">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          
        </div>
        <div className="col-lg-6">
          <div className="accordion" id="accordion">
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                 
                   What is the purpose of theexammate platform?
                 
                </p>
              </div>
              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div className="card-body">
                  {" "}
                  Our self-assessment platform is designed to facilitate online assessments and examinations, allowing students to prepare better for their exams.{" "}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo">
                <p className="mb-0">
               
                  How do students access the exams? {" "}
                </p>
              </div>
              <div
                 id="collapseTwo"
                 className="collapse show"
                 aria-labelledby="headingOne"
                 data-parent="#accordion"
              >
                <div className="card-body">
                  {" "}
                  Students can create an account on theexammate.com and access subject wise chapter wise exams{" "}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <p className="mb-0">
                 
                    {" "}
                    What happens if a student faces technical issues <br></br> during the exam? 
                
                </p>
              </div>
              <div
                id="collapseThree"
                className="collapse show"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div className="card-body">
                  {" "}
                  We have a dedicated technical support team available via chat or email to assist students with any technical difficulties they may encounter during the exam. Students should reach out immediately if they experience any issues.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="accordion" id="accordion2">
            <div className="card">
              <div className="card-header" id="heading4">
                <p className="mb-0">
                 
                    Is student performance data available for analysis?
                
                </p>
              </div>
              <div
                id="collapse4"
                className="collapse show"
                aria-labelledby="heading4"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  {" "}
                  Yes, our platform provides comprehensive performance analytics and reports. Parents can analyse the students’ performance, identify strengths and weaknesses, and make informed decisions to enhance learning.{" "}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading5">
                <p className="mb-0">
                 
                    How secure is student and educator data on the platform?
            
                </p>
              </div>
              <div
                id="collapse5"
                className="collapse show"
                aria-labelledby="heading5"
                data-parent="#accordion2"
              >
                <div className="card-body">
                  {" "}
                  Data security is a top priority. We employ robust encryption protocols and adhere to strict privacy standards to safeguard all user data. Our platform is regularly audited to ensure compliance with data protection regulations.{" "}
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default AccordionComponent ;
