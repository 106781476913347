// customHooks/useTokenNavigation.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function useTokenNavigation() {
  const [tokenAvailable, setTokenAvailable] = useState(false); // Replace 'false' with the logic to check token availability

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token != null) {
      setTokenAvailable(true);
    }
    
  }, []);

  const navigate = useNavigate();

  // Function to handle navigation based on token availability
  const handleNavigation = (path) => {
    if (tokenAvailable) {
      navigate(path);
    } else {
      navigate("/login");
    }
  };

  return handleNavigation;
}
