import React, { useEffect } from "react";
import { Accordion, Row, Col } from "react-bootstrap";
import "../../../theme/css/Faq.css";
import Aos from "aos";
import "aos/dist/aos.css";
import OurTestimonial from "./OurTestimonial";
import AccordionComponent from "./AccordionComponent ";
export default function FAQ() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="otherPageBanner "></div>
      <div className="container">
        <div className="faqContain" data-aos="fade-up">
          <div className="text-center title" data-aos="fade-down">
            <h2 style={{ padding: 10 }}>FREQUENTLY ASKED QUESTIONS</h2>
          </div>
          <AccordionComponent />
        </div>
      </div>
      {/* <OurTestimonial/> */}
    </>
  );
}
