import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { color, style } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png"


// password images
import img3 from "../../../assets/Images/password/accept.png";
import img2 from "../../../assets/Images/password/locked.png";
import img from "../../../assets/Images/password/reset-password.png";

function ResetPassword() {
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [allfield, setAllField] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");

  const [newpassworderror, setNewPasswordError] = useState("");
  const [confirmpassworderror, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null && token != undefined) {
      setToken(token);
    }

    if (!sessionStorage.getItem("token")) {
      // navigate("/");
    }
  }, []);

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // Nav Refresh
  // const relode = () => {
  //   window.location.reload(true);
  // };

  const loginPasswordVisibilityClick = () => {
      setShowPassword(!showPassword)
  }

  const resetPassword = async (e) => {    
    e.preventDefault();
    let token = sessionStorage.getItem("otp")
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      NewPassword: newpassword,
      ConfirmPassword: confirmpassword,
    };

    // Change Password Validation //
    const regexPassword =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (newpassword == "") {
        setNewPasswordError("Please Enter New Password");
      }
      if (confirmpassword == "") {
        setConfirmPasswordError("Please Enter Confirm Password");
      } else {
      setAllField("");
      }

    // New Password Validation //
    const regex = /^\S*$/;
    if (!regex.test(newpassword)) {
      setNewPasswordError("White Spaces Not Allow");
    } else if (newpassword == "") {
      setNewPasswordError("Please Enter New Password");
    } else if (newpassword.length < 6) {
      setNewPasswordError("Password mininum length 6");
    } 
    // else if (!regexPassword.test(newpassword)) {
    //   setNewPasswordError(
    //     "Password should have 1 uppercase 1 lowercase 1 spl character min 8"
    //   );
    // }

    // confirmpassword Space Validation //
    else if (!regex.test(confirmpassword)) {
      setConfirmPasswordError("White Spaces Not Allow");
    } else {
      setAllField("");

      try {
        const { data } = await axios.post(
          `${window.BASEURL}${window.RESETPASSWORDUPDATE}`,
          d,
          { headers: h }
        );
        console.log(data);
        setAllField(data.errorMsgs);
        // if (data.result === "Successfully Changed") {
        //   toastSuccess("Successfully Submitted");
        //   sessionStorage.removeItem("token");
        //   alert("Successfully Reset")
        //     navigate("/login")
        // }
        if (data.result === "Successfully reset..") {
          toastSuccess("Successfully Submitted");
          sessionStorage.removeItem("token");
        
          // Show SweetAlert success alert
          Swal.fire({
            icon: "success",
            title: "Password Reset Successful",
            text: "Your password has been successfully reset.",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      {/* <section className="mt-5" style={{ backgroundColor: "#fff5f1" }} > */}
      <div className="banimg2">
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="  col-lg-6 col-md-4  d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
                <h1></h1>
                <h2></h2>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  {/* <a className="glightbox btn-watch-video">
                    <i className="bi bi-play-circle" />
                    <span></span>
                  </a> */}
                </div>
              </div>
              <div className="  col-lg-6 order-1 order-lg-2 hero-img">
                <div className="mainDiv">
                  <div className="cardStyle">
                    <form onSubmit={resetPassword}>
                      <div className="Logo">
                        <img
                          src={BlackThemeLogo}
                          style={{ maxHeight: 85 }}
                        />
                      </div>
                      <h2 className="formTitle" style={{ color: "blue",fontSize:15 }}>
                        Reset Password
                      </h2>

                      <div className="inputDiv">
                        <label
                          className="form-label"
                          for="form3Example4c"
                          style={{ marginTop: 15 }}
                        ></label>
                        <div style={{ display: "flex" }}>
                          <img src={img2} className="foricon" />
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            id="form3Example4c ChangeNewpass"
                            className="form-control"
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                        <span style={{ color: "red" }}>{newpassworderror}</span>
                      </div>


                      <div className="inputDiv">
                        <label
                          className="form-label"
                          for="form3Example4c"
                          style={{ marginTop: 15 }}
                        ></label>
                        <div style={{ display: "flex" }}>
                          <img src={img3} className="foricon" />
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            id="form3Example4c  ChangeConfirmpass"
                            className="form-control"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {confirmpassworderror}
                        </span>
                      </div>
                      <div className="form-outline flex-fill mb-0">
                        {allfield.length > 0 && (
                          <span style={{ color: "red" }}>{allfield}</span>
                        )}
                      </div>
                      <div
                        className="d-flex justify-content-center mx-4 mb-3 mb-lg-4"
                        style={{ marginTop: 10 }}
                      >
                        <button type="submit" name="changepass">Reset Password</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResetPassword;
