import React,{useEffect} from "react";
// import ThemeLogo from '../assets/Images/BrandLogo/TheExammate.png';
import WELCOMELOGO from "../assets/Images/BrandLogo/The ExamMate Welcom logo.jpeg";

import axios from "axios";
import { useNavigate } from "react-router-dom";

export const WelcomeScreen = () => {
    const Navigate = useNavigate()
    useEffect(() => {
        const token = sessionStorage.getItem("token");
        const user_Id = sessionStorage.getItem("userId");
        
    
        if (token != null && user_Id != null) {
          getStudentInfo(token, user_Id);
        } 
        // if (user_Id != null) {
        //   setUserId(user_Id);
        // }
        // if (token != null) {
        //   setToken(token);
        //   getAllState(token);
        // }
      }, []);

      const getStudentInfo = async (token, StudentId) => {
        console.log(StudentId);
        const h = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        try {
          const { data } = await axios.get(
            `${window.BASEURL}${window.AUTOFILL}${StudentId}`
          );
          console.log(data);
          if(data.gradeId === 0){
            Navigate("/student-information");
          }else{
            Navigate("/Homepage");
          }
          
        } catch (error) {
            // Navigate("/student-information");
          console.log(error);
        }
      };





  return (
    <>
      <div className="welP">
        <img
          style={{ height: 110,background:"#d5d5d5" }}
          src={WELCOMELOGO}
          alt="themeLogo"
          className="img-fluid mx-auto WLogo welcome-img"
          id="logomain"
        />
      </div>
    </>
  );
};
