import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../theme/css/Blog.css";
// import BlogData from "./BlogData";
import { useEffect } from "react";
import axios from "axios";
import LoadingScreen from "../Loading/Loadingscreen";
import { InputGroup, FormControl } from "react-bootstrap";
import sideblogimg from "../../../assets/Images/Blog/sideblogimg.jpg";
import Progress from "../Service/Progress";
import "./blog.css";
export default function Blog() {
  const [BlogData, setBlogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getallblog();
  }, []);

  const getallblog = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${window.BASEURL}${window.BLOG}`);
      console.log(data);
      setBlogData(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.log("An error occurred:", error.message);
      if (error.response) {
        console.log("Response:", error.response.data);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <>
            <section className="section wb">
              <div className="container">
                <div className="row">
                <div className="otherPageBanner contactBanner">
            <div className="text-center title">
              <h2>LATEST BLOG</h2>
            </div>
          </div>
                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <div className="page-wrapper">
                      <div className="blog-list clearfix">
                        {BlogData.map((item)=>(
                          <div className="blog-box row">
                          <div className="col-md-4">
                            <div className="post-media">
                            <Link  to={`/blogpage/${item.id}${item.backLinkURL}`}>
                                <img
                                  src={window.BASEURL2 + item.blogImage}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="hovereffect" />
                           </Link>
                            </div>
                            {/* end media */}
                          </div>
                          {/* end col */}
                          <div className="blog-meta big-meta col-md-8">
                            <span className="bg-aqua">
                              <a href="garden-category.html" title="">
                           TheExammate
                              </a>
                            </span>
                            <h4>
                            <Link to={`/blogpage/${item.id}${item.backLinkURL}`}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.title,
                              }}
                            />
                            </Link>
                            </h4>
                            <div
                              style={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: item.content.substring(0, 400),
                              }}
                            />
                            <small>
                              <a href="garden-category.html" title="">
                                {/* <i className="fa fa-eye" /> 1887 */}
                              </a>
                            </small>
                            <small>
                              <a href="garden-single.html" title="">
                                {/* 11 July, 2017 */}
                              </a>
                            </small>
                            <small>
                              <a href="#" title="">
                                by Admin
                              </a>
                            </small>
                            <hr className="invis" />
                          </div>
                          {/* end meta */}
                        </div>
                        ))}
                        {/* end blog-box */}
                      </div>
                      {/* end blog-list */}
                    </div>
                    {/* end page-wrapper */}
                    <hr className="invis" />
                  
                    {/* end row */}
                  </div>
                  {/* end col */}

                  {/* end col */}
                </div>
                {/* end row */}
              </div>
              {/* end container */}
            </section>
          </>


        </>
      )}
    </div>
  );
}
