import React from "react";
import Banner from "../HomePage/Banner"
import AboutUs from '../HomePage/AboutUs'
import OurPricing from "./OurPricing";
import OurTeam from "./OurTeam";
import OurBlog from "./OurBlog";
import Brandlogo from "./Brandlogo";
import StartedToday from "./StartedToday";
import HomeFAQ from "./HomeFAQ";
import PeopleSay from "./PeopleSay";
import LoadingScreen from "../Loading/Loadingscreen";
import { useTranslation } from "react-i18next";
import BannerForm from "./BannerForm"

export default function Home() {
  
  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  
 
  return (
    <>   
    {/* <LoadingScreen/> */}
      {/* <Login/> */}
      {/* <Banner/> */}
      <BannerForm/>
      <AboutUs/>
      {/* <HomeFAQ/> */}
      {/* <OurPricing/> */}
      {/* <OurTeam/> */}
      <PeopleSay/>
      <OurBlog/>
      <Brandlogo/>
      {/* <StartedToday/>   */}
    </>
  );
}
