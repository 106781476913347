import "./pdf.css";
import React, { useState } from "react";
import englishPDF from "../../../assets/pdf/CBSE/10/10th-english.pdf";
import hindiPDF from "../../../assets/pdf/CBSE/10/10th-hindi.pdf";
import Maths from "../../../assets/pdf/CBSE/10/10th-maths.pdf";
// import ict from '../../../assets/pdf/CBSE/9/9th-ict.pdf';
import physicaleducation from "../../../assets/pdf/CBSE/10/10th-phy-edu.pdf";
import sanskrit from "../../../assets/pdf/CBSE/10/10th-sanskrit.pdf";
import science from "../../../assets/pdf/CBSE/10/10th-science.pdf";
import social from "../../../assets/pdf/CBSE/10/10th-social.pdf";
import urudu from "../../../assets/pdf/CBSE/10/10th-urudu.pdf";
import { Link, Navigate, useNavigate } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

function PdfComponentten() {
  const [selectedSubject, setSelectedSubject] = useState(englishPDF);

  const Navigate = useNavigate();

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  };

  return (
    <>   
         <button onClick={(e) => Navigate("/Studymaterials")}>  <KeyboardDoubleArrowLeftIcon />
            Back</button>
     <div className="pdf-viewer d-flex">
      
      <div className="subjects">
      

        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(englishPDF)}
        >
          English
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(hindiPDF)}
        >
          Hindi
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(Maths)}
        >
          Maths
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(physicaleducation)}
        >
          PE
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(sanskrit)}
        >
          Sanskrit
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(science)}
        >
          Science
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(social)}
        >
          Social
        </button>
        <button
          className="subject-btn"
          onClick={() => handleSubjectClick(urudu)}
        >
          Urudu
        </button>
      </div>

      <div className="pdf-container">
        {selectedSubject && (
          <iframe
            src={selectedSubject}
            title="PDF Viewer"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    </div>
    </>

  );
}

export default PdfComponentten;
