import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ChartComponent = () => {
  const chartRef = useRef(null);
  const [studentid, setStudentId] = useState("");
  const [chartData, setChartData] = useState([]);

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    getsubjectwiseresult();
    const userId = sessionStorage.getItem("userId");
    setStudentId(userId);
    if (userId) {
      setStudentId(userId);
    }
  }, [studentid]);
  useEffect(() => {
    // Create the chart once the chartData state is updated with the API response
    if (chartData.length > 0) {
      const spacing = 20; // Adjust this value to add spacing between the bars
      // const chartHeight = (chartData.length * 80) + (spacing * (chartData.length - 1)); // Adjust the height with spacing
      const chartHeight = Math.max((chartData.length * 80) + (spacing * (chartData.length - 1)), 150); // Minimum height is 200 pixels

      const options = {
        series: [
          {
            data: chartData.map((item) => item.percentage),
            
          },
        ],
        chart: {
          type: "bar",
          height: chartHeight,
          horizontal: true,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
            columnWidth: "70%", // Adjust the column width to control spacing
          },
        },
        colors: [
          // Rest of the colors...
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["black"],
          },
          formatter: function (val, opt) {
            // Display the subject name when hovering
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          // Rest of the stroke...
        },
        xaxis: {
          categories: chartData.map((item) => item.subjectName), // Set subject names here
        },
        yaxis: {
          labels: {
            show: false, 
          },
        },
        title: {
          // text: "SubjectWise Results",
          align: "center",
          floating: true,
        },
        tooltip: {
          // Rest of the tooltip...
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Clean up the chart when the component is unmounted
      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  
  const getsubjectwiseresult = async () => {
    const h = {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    // Calculate the date range for the last 10 days
    const today = new Date();
    const toDate = today.toISOString().split("T")[0]; // Get today's date
    today.setDate(today.getDate() - 9); // Subtract 9 days to get the 10th day back from today
    const fromDate = today.toISOString().split("T")[0]; // Get the date for 10 days ago

    const d = {
      StudentId: parseInt(studentid),
      FromDate: fromDate,
      ToDate: toDate,
    };

    try {
      const { data } = await axios.post(
        `${window.EXAMURL}${window.STUDENTPICHART}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data) {
        // Update the chartData state with the API response
        setChartData(
          data.map((item) => ({
            id: item.id,
            percentage: item.percentage,
            subjectName: item.subjectName,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return  <div  className="container add-listings-box educational"  style={{height:"100vh"}}>
      <h3>{translation("Exams.ExamSubject")}</h3>
  <div id="chart" ref={chartRef} />
  </div>
};

export default ChartComponent;
