import React from "react";
import useimg from "../../../assets/Images/Icons/userprofile.png";
import "./Dashboard.css";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingScreen from "../../pages/Loading/Loadingscreen";
import { useTranslation } from "react-i18next";
import exmicon from "../../../assets/Images/Icons/exam.png";
import percentage from "../../../assets/Images/Icons/percentage.png";
import questions from "../../../assets/Images/Icons/questions.png";
import CircleIcon from "@mui/icons-material/Circle";
import AD from "../../../assets/Images/Banner/AD1.png";
import AD2 from "../../../assets/Images/Banner/AD2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PercentIcon from "@mui/icons-material/Percent";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import userimg from "../../../assets/Images/Icons/User.png";
import { Loading } from "react-fullscreen-loading";



function Home() {
  const [performance, setPerformance] = useState("");
  const [token, setToken] = useState();
  const [studentid, setStudentId] = useState("");
  const [isloading, setloading] = useState(false);
  const [gradeid, SetgRadeid] = useState();
  const [boardid, Setboardid] = useState();
  const [stname, setstname] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const [activateplan, setActivateplan] = useState("");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Auto-play the slider
    autoplaySpeed: 3000, // Time between slides (in milliseconds)
  };

  useEffect(() => {
    const tok = sessionStorage.getItem("token");

    const userId = sessionStorage.getItem("userId");
    console.log(userId);
    if (token && studentid) {
      getPlaninfo(token, studentid);
    }
    if (token != null && userId != null) {
      getStudentInfo(token, userId);
      getStudentInfo2(token, userId);
      // displayImageFromBackend();
    }
    if (tok !== null || tok !== undefined) {
      setToken(tok);
      setStudentId(userId);
      getperformance(tok);
      //  setSubjectId()
      //  setFormDate()
      //  setToDate()
    }
  }, [token, studentid]);

  useEffect(() => {
    // const storedStName = localStorage.getItem("stname");
    // if (storedStName) {
    //   setstname(storedStName);
    // }
  }, []);

  const getPlaninfo = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      // console.log(subjectId);
      console.log(window.CHAPTER);
      const { data } = await axios.get(
        `${window.BASEURL}${window.PLANINFO}/${studentid}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setActivateplan(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudentInfo = async (token, StudentId) => {
    console.log(StudentId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.AUTOFILL2}${StudentId}`
      );
      console.log(data);
      // setName(data.stName);
      SetgRadeid(data.grade);
      Setboardid(data.boardName);
      // setSelectedImage(data.studentPhoto)
    } catch (error) {
      console.log(error);
    }
  };
  const getStudentInfo2 = async (token, StudentId) => {
    console.log(StudentId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.AUTOFILL}${StudentId}`
      );
      console.log(data);
      setName(data.stName);
      // SetgRadeid(data.grade)
      // Setboardid(data.boardName)
      setSelectedImage(data.studentPhoto);
      setSchoolName(data.othersSchoolName);
    } catch (error) {
      console.log(error);
    }
  };

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  // my performance API
  const getperformance = async () => {
    console.log(isloading);
    setloading(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentid),
    };
    console.log(d);
    try {
      
      const { data } = await axios.post(
        `${window.EXAMURL}${window.MYPERFORMANCE}`,
        d,
        { headers: h }
      );
      console.log(data);
      if (data) {
        console.log(data);
        setPerformance(data);
       
      }
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(isloading);
  return (
    <div>
      {isloading ? (
        <LoadingScreen />
      ) : (
        <div className="d-flex flex-column flex-sm-row justify-content-between col-lg-12 p-2">
          {/* First UI */}
          <div className="maincardbody1 mb-3 mb-sm-0">
            <h4>Profile</h4>
            <div className="border" style={{height:"93%"}}>
              <div className="maincard col-12">
                <div className="d-flex flex-column flex-sm-row justify-content-between">
                  <div className="userimg mb-3 mb-sm-0">
                   
                    { selectedImage ?( <img
                      src={window.BASEURL2 + selectedImage}
                      style={{ height: 157 }}
                      alt="User"
                    />) :(
                      <img id="usimg" src={userimg} alt="Image" />
                    )}
                     {/* {selectedImage ? (
                    <img id="image" key={imageKey} src={imageUrl} alt="Image" />
                  ) : (
                    <img id="usimg" src={userimg} alt="Image" />
                  )} */}
                    <p className="d-block ">{name}</p>
                    <p className="d-block ">{schoolname}</p>
                  </div>
                  <div className="countforstmain">
                    <div className="countforst">
                      <p style={{ fontWeight: 600 }}>
                        Grade
                        <br />
                      </p>
                      <p>{gradeid}</p>
                    </div>
                    <div className="countforst">
                      <p style={{ fontWeight: 600 }}>
                        Board
                        <br />
                      </p>
                      <p> {boardid}</p>
                    </div>
                    {/* <div className="countfors">
                      <p style={{ fontWeight: 600 }}>
                        Plan
                        <br />
                      </p>
                      {activateplan && (
                        <div>
                          <p>{activateplan.planName}</p>
                        </div>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div>
                <div className="container" style={{ marginTop: 50 }}>
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div
                        className="card bg-c-blue order-card"
                        style={{ height: 140 }}
                      >
                        <h6
                          className="mb-0 text-center"
                          style={{ color: "#fff", fontSize: "14px" }}
                        >
                          {" "}
                          {translation("Exams.MainExamcount")}{" "}
                        </h6>

                        <div className="card-block text-center">
                          {performance &&
                            performance.map((item) => (
                              <React.Fragment key={item.totalNoOfExm}>
                                {/* <img src={exmicon} /> */}
                                <NoteAltIcon />
                                <h6
                                  className="mt-2"
                                  style={{ fontWeight: 600, fontSize: 20 }}
                                >
                                  {item.totalNoOfExm}
                                </h6>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div
                        className="card bg-c-green order-card"
                        style={{ height: 140 }}
                      >
                        <h6
                          className="mb-0 text-center"
                          style={{ color: "#fff", fontSize: "14px" }}
                        >
                          {" "}
                          {translation("Exams.MainExampercentage")}{" "}
                        </h6>
                        <div className="card-block text-center">
                          {performance &&
                            performance.map((item) => (
                              <React.Fragment key={item.percentage}>
                                <PercentIcon />
                                <h6
                                  className="mt-2"
                                  style={{ fontWeight: 600, fontSize: 20 }}
                                >
                                  {item.percentage}
                                </h6>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div
                        className="card bg-c-yellow order-card"
                        style={{ height: 140 }}
                      >
                        <h6
                          className="mb-0 text-center"
                          style={{ color: "#fff", fontSize: "14px" }}
                        >
                          {translation("Exams.MainExamnoof")}
                        </h6>
                        <div className="card-block text-center">
                          {performance &&
                            performance.map((item) => (
                              <React.Fragment key={item.totalNoOfQ}>
                                <HelpCenterIcon />

                                <h6
                                  className="mt-2"
                                  style={{ fontWeight: 600, fontSize: 20 }}
                                >
                                  {item.totalNoOfQ}
                                </h6>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div
                        className="card bg-c-pink order-card"
                        style={{ height: 140 }}
                      >
                        <h6
                          className="mb-0 text-center"
                          style={{ color: "#fff", fontSize: "14px" }}
                        >
                          {translation("Exams.ExambackCorrect1")}
                        </h6>
                        <div className="card-block text-center">
                          {performance &&
                            performance.map((item) => (
                              <React.Fragment key={item.totalCrtAns}>
                                <LibraryAddCheckIcon />

                                <h6
                                  className="mt-2"
                                  style={{ fontWeight: 600, fontSize: 20 }}
                                >
                                  {item.totalCrtAns}
                                </h6>
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second UI */}
          <div className="maincardbody2">
            <h4>Announcement</h4>
            <Slider {...settings}>
              <div>
                <img src={AD2} alt="Image 1" id="ADIMAGE" />
              </div>
              <div>
                <img src={AD2} alt="Image 2" id="ADIMAGE" />
              </div>
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
