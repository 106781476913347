import React from "react";
import "./ExamRoomResult.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useTranslation } from "react-i18next";
import Cup from "../../../assets/Images/Icons/cup2.png"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function ExamRoomResult() {
  const [allQuestion, setAllQuestion] = useState([]);
  const [correct, setCorrect] = useState();
  const [wrong, setWrong] = useState();
  const [notselectquestion, setNotselectquestion] = useState();
  const [question, setQuestion] = useState();
  const [load, setLoad] = useState();
  const [not, setNot] = useState();
  const [subject, setSubject] = useState("");
  const location = useLocation();
  const [check, setCheck] = useState(false);
  // console.log(location.state.data);
  console.log(allQuestion);
  const [count, setCount] = useState();
  const [percentage, setPercentage] = useState();

  const Navigate = useNavigate();

  function handleClick() {
    Navigate("/exam-room");
  }
  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setAllQuestion(location.state.data);
      setLoad(location.state.allQuestion);
      let notSelect =
        parseInt(location.state.data.length) -
        parseInt(location.state.TrnEQPAnswer.length);
      setNot(notSelect);
      // setSubject(location.state.subject)
      // console.log(notSelect,location.state.allQuestion.length,location.state.data.length);
      //   setQuestion(location.state.data[0].correctAnswer)
      // console.log(location.state.data[0]);
      setCorrect(location.state.data[0].correctAnswer);
      setWrong(location.state.data[0].wrongAnswer);
      //   setNotselectquestion(location.state.data[0].notselectquestion)
      setCount(location.state.data.length);
    }

    percentagefun(location.state.data.length,location.state.data[0].correctAnswer);
  }, []);

  const config = {
    loader: { load: ["input/asciimath"] },
  };

  const percentagefun = (count,correct) => {
    // Check if count is greater than 0 to avoid division by zero
    if (count > 0) {
      const result = (correct / count) * 100;
      setPercentage(result);
    } else {
      // Handle the case where count is zero
      setPercentage(0); // Or set it to an appropriate default value
    }
  };
  
  console.log(allQuestion);
  return (
    <div>
      <div class="container exam-rooms">
        <div className="Mainouter">
          <div className="exmresult ">
            <div className="exmresultpt1 ">
              <div className="heading">
                <div className="heading-text">
                <div className="heading-text"></div>
                  <h3 >
                    {translation("Exams.MainExamnoof")}:
                  </h3>
                  
                  <h3 >
                    {translation("Exams.ExambackCorrect")} :
                  </h3>
                  <h3 >
                    {translation("Exams.ExambackWrong")} :
                  </h3>
                  <h3 >
                    {translation("Exams.ExambackNot")} :
                  </h3>
                </div>
                <div className="heading-count">
                  <h4 >{count}</h4>
                  <h4 style={{ color: "green" }}>{correct}</h4>
                  <h4 style={{ color: "red" }}>{wrong}</h4>
                  <h4 style={{ color: "orange" }}>{not}</h4>
                </div>
              </div>
            </div>
            <div className="exmresultpt2">
                
              <div className="resultui">
               
                <h1>Score</h1>
               
                {/* <EmojiEventsIcon/> */}
                <h2>{percentage}%</h2>
              
              </div>
              <img id="cup" src={Cup} />
            </div>
          </div>
        </div>
        {/* <header
          class="container exam-room header"
          id="head"
          style={{
            height: "auto",
            // display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            boxShadow: " 0 0 10px #ccc",
          }}
        ></header> */}
        <button onClick={handleClick}> {translation("Exams.Examback")}</button>

        {/* <div className="exam-room-time"> */}
        {/* <h3>Subject: {subject}</h3>
                    <h3>Lesson : All</h3>
                    <h3>Time : 10:11</h3> */}
        {/* </div> */}
        <div class="row">
          <form>
            {allQuestion &&
              allQuestion?.map((item, index) => {
                return (
                  // <div className=' col-lg-12 col-md-12' id='maindiv'>
                  <div class="col-lg-12 col-md-12" key={index} id="overAll">
                    <div class="form-group" id="form">
                      <div className="question">
                        <MathJaxContext config={config}>
                          <MathJax>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${index + 1}. ${item.question}`,
                              }}
                            />
                          </MathJax>
                        </MathJaxContext>
                      </div>
                      <div className="image-web">
                        {item.displayType === "Image" && (
                          <img
                            alt="imageQ"
                            src={`${window.IMAGE}/${item.questionImageWeb}`}
                          />
                        )}
                      </div>
                      <div className="image-tab">
                        {item.displayType === "Image" && (
                          <img
                            alt="imageQ"
                            src={`${window.IMAGE}/${item.questionImageTab}`}
                          />
                        )}
                      </div>
                      <div className="image-mobile">
                        {item.displayType === "Image" && (
                          <img
                            alt="imageQ"
                            src={`${window.IMAGE}/${item.questionImageMobile}`}
                          />
                        )}
                      </div>
                      <div className="question-body">
                        <div className="row" id="row">
                          <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                            <div
                              className="d-flex align-items-baseline radio-cursor option"
                              // id="option"
                              id={
                                "a" == item.result
                                  ? "easy"
                                  : item.answer == "a"
                                  ? "noeasy"
                                  : ""
                              } 
                            >
                              <input
                                className="large"
                                type="radio"
                                id={item.answerA}
                                name={item.id}
                                value={"A"}
                                style={{ marginLeft: "5px", borderColor: item.answer === item.result ? "correct-answer" : "wrong-answer" }}
                                checked={item.answer === "A"}
                              />
                              (A){" "}
                              <label
                                for={item.answerA}
                                // id={
                                //   "a" == item.result
                                //     ? "easy"
                                //     : item.answer == "a"
                                //     ? "noeasy"
                                //     : ""
                                // }
                              >
                                <MathJaxContext config={config}>
                                  <MathJax>{item.answerA}</MathJax>
                                </MathJaxContext>
                              </label>
                            </div>
                            <div
                              className="d-flex align-items-baseline radio-cursor option"
                              // id="option"
                              id={
                                "b" == item.result
                                  ? "easy"
                                  : item.answer == "b"
                                  ? "noeasy"
                                  : ""
                              }
                            >
                              <input
                                 className="large"
                                 type="radio"
                                 id={item.answerB}
                                 name={item.id}
                                 value={"B"}
                                 style={{ marginLeft: "5px", borderColor: item.answer === item.result ? "correct-answer" : "wrong-answer" }}
                                 checked={item.answer === "B"}
                              />
                              (B){" "}
                              <label
                                for={item.answerB}
                                // id={
                                //   "b" == item.result
                                //     ? "easy"
                                //     : item.answer == "b"
                                //     ? "noeasy"
                                //     : ""
                                // }
                              >
                                <MathJaxContext config={config}>
                                  <MathJax>{item.answerB}</MathJax>
                                </MathJaxContext>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                            <div
                              className="d-flex align-items-baseline radio-cursor option"
                              // id="option"
                              id={
                                "c" == item.result
                                  ? "easy"
                                  : item.answer == "c"
                                  ? "noeasy"
                                  : ""
                              }
                            >
                              <input
                                  className="large"
                                  type="radio"
                                  id={item.answerB}
                                  name={item.id}
                                  value={"C"}
                                  style={{ marginLeft: "5px", borderColor: item.answer === item.result ? "correct-answer" : "wrong-answer" }}
                                  checked={item.answer === "C"}
                              />
                              (C){" "}
                              <label
                                for={item.answerC}
                                // id={
                                //   "c" == item.result
                                //     ? "easy"
                                //     : item.answer == "c"
                                //     ? "noeasy"
                                //     : ""
                                // }
                              >
                                <MathJaxContext config={config}>
                                  <MathJax>{item.answerC}</MathJax>
                                </MathJaxContext>
                              </label>
                            </div>
                            <div
                              className="d-flex align-items-baseline radio-cursor option"
                              // id="option"
                              id={
                                "d" == item.result
                                  ? "easy"
                                  : item.answer == "d"
                                  ? "noeasy"
                                  : ""
                              }
                            >
                              <input
                                  className="large"
                                  type="radio"
                                  id={item.answerB}
                                  name={item.id}
                                  value={"D"}
                                  style={{ marginLeft: "5px", borderColor: item.answer === item.result ? "correct-answer" : "wrong-answer" }}
                                  checked={item.answer === "D"}
                              />
                              (D){" "}
                              <label
                                for={item.answerD}
                                // id={
                                //   "d" == item.result
                                //     ? "easy"
                                //     : item.answer == "d"
                                //     ? "noeasy"
                                //     : ""
                                // }
                              >
                                <MathJaxContext config={config}>
                                  <MathJax>{item.answerD}</MathJax>
                                </MathJaxContext>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  // </div>
                );
              })}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ExamRoomResult;
