import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import "../../../theme/css/Contactus.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
import "aos/dist/aos.css";
import OurPricingData from "../HomePage/OurPricingData";
import { useTranslation } from "react-i18next";
import { useTokenNavigation } from "../Usetoken/useTokenNavigation";

export default function AboutUsMenu() {


  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleNavigation = useTokenNavigation();

  return (
    <>
      <div className="otherPageBanner contactBanners">
        <div className="text-center title">
          <h2>Pricing</h2>
          {/* <h6>We are specialize in startups</h6> */}
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu ">
          <Row>
            <Col xl={6} lg={6} md={6} sm={7}>
              <Slider {...settings}>
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  src={aboutus}
                />
                {/* <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg1}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg3}
                />
                <img
                  className="img-fluid mx-auto"
                  alt="AboutMenu"
                  data-aos="flip-left"
                  src={aboutusImg4}
                /> */}
              </Slider>
            </Col>
            <Col xl={6} lg={6} md={6} sm={5} className="colForResponsive">
              <div className="titleFont" style={{ textAlign: "center" }}>
                <h3> {translation("Pricing.PricingTitle")}</h3>
                {/* <h2> examate</h2> */}
              </div>
              <p style={{ textAlign: "justify" }}>
              {translation("Pricing.pricingpara")}
              </p>
            
            </Col>
          </Row>
        </div>

        {/* <AboutUsVideo /> */}
      </div>
      <div className="container">
        <div className="spacing">
          <div className="titleFont">
            {/* <h3>Our Pricing</h3> */}
            <h2>Check Our Pricing</h2>
          </div>
          <div className="ourPricingCard ">
            <Row>
              {OurPricingData.map((pricing) => (
                <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                  <div className="pricingCard" data-aos="flip-leftss">
                    <div
                      className="pricingColorBox text-center"
                      key={pricing.id}
                    >
                      <h5>{pricing.title}</h5>
                      <h3>
                        {pricing.newPrice}
                        <span>{pricing.oldPrice}</span>
                      </h3>
                      <h2>{pricing.subTitle}</h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain1} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain2} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain3} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain4} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {pricing.contain5} </span>
                        </li>
                        <li>
                        </li>
                      </ul>
                      <div className="text-center">
                        <button className="button"  onClick={() => handleNavigation("/home")}>
                          <Link> Pay Now </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        {/* <Brandlogo/> */}
      </div>
      <div className="spacing">
        <Brandlogo />
      </div>
    </>
  );
}
