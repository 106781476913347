import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { color, style } from "@mui/system";
import "./ChangePassword.css";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import LoadingScreen from "../Loading/Loadingscreen";
import { useTranslation } from "react-i18next";

// password images
import img3 from "../../../assets/Images/password/accept.png";
import img2 from "../../../assets/Images/password/locked.png";
import img from "../../../assets/Images/password/reset-password.png";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [allfield, setAllField] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");

  const [passworderror, setPasswordError] = useState("");
  const [newpassworderror, setNewPasswordError] = useState("");
  const [confirmpassworderror, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const toastSuccess = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const toastError = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  // Nav Refresh
  const relode = () => {
    window.location.reload(true);
  };

  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (token != null && token != undefined) {
      setToken(token);
    }

    if (!sessionStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  // const loginPasswordVisibilityClick = () => {
  //     setShowPassword(!showPassword)
  // }

  const changePassword = async (e) => {
    e.preventDefault();
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      password: password,
      NewPassword: newpassword,
      ConfirmPassword: confirmpassword,
    };

    // Change Password Validation //
    const regexPassword =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (password == "") {
      setPasswordError("Please Enter Old Password");
      if (newpassword == "") {
        setNewPasswordError("Please Enter New Password");
      }
      if (confirmpassword == "") {
        setConfirmPasswordError("Please Enter Confirm Password");
      }
    } else {
      setAllField("");
    }

    // New Password Validation //
    const regex = /^\S*$/;
    if (!regex.test(newpassword)) {
      setNewPasswordError("White Spaces Not Allow");
    } else if (newpassword == "") {
      setNewPasswordError("Please Enter New Password");
    } else if (newpassword.length < 6) {
      setNewPasswordError("Password mininum length 6");
    }
    // else if (!regexPassword.test(newpassword)) {
    //   setNewPasswordError(
    //     "Password should have 1 uppercase 1 lowercase 1 spl character min 8"
    //   );
    // }

    // confirmpassword Space Validation //
    else if (!regex.test(confirmpassword)) {
      setConfirmPasswordError("White Spaces Not Allow");
    } else {
      setAllField("");

      try {
        setIsLoading(true);
        console.log(window.CHANGEPASSWORD);
        console.log(window.BASEURL);
        const { data } = await axios.post(
          `${window.BASEURL}${window.CHANGEPASSWORD}`,
          d,
          { headers: h }
        );
        console.log(data);
        setAllField(data.errorMsgs);
        if (data.result === "Successfully Changed") {
          Swal.fire({
            icon: "success",
            title: "Your Password Changed successfully",
            showConfirmButton: false,
            timer: 3500,
          });

          toastSuccess("Successfully Submitted");

          sessionStorage.removeItem("token");
          logOutProfile()
          // window.reload()
          setTimeout(() => {
            navigate("/login")
            window.location.reload()
        }, 3000);
          // relode();
          
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const logOutProfile = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("otp");
    sessionStorage.removeItem("f");
    sessionStorage.removeItem("userId");
    localStorage.removeItem("ll");
    localStorage.removeItem("tok");
    localStorage.removeItem("userId");
    localStorage.removeItem("sub");
    localStorage.removeItem("stname");
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {/* <section className="mt-5" style={{ backgroundColor: "#fff5f1" }} > */}
          <div className="banimg2">
            <section id="hero" className="d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="  col-lg-6 col-md-4  d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
                    <h1></h1>
                    <h2></h2>
                    <div className="d-flex justify-content-center justify-content-lg-start">
                      <a className="glightbox btn-watch-video">
                        {/* <i className="bi bi-play-circle" /> */}
                        <span></span>
                      </a>
                    </div>
                  </div>
                  <div className="  col-lg-6 order-1 order-lg-2 hero-img">
                    <div className="mainDiv">
                      <div className="cardStyle">
                        <form onSubmit={changePassword}>
                          <div className="Logo">
                            <img
                              src={BlackThemeLogo}
                              style={{ maxHeight: 85 }}
                            />
                          </div>
                          <h2 className="formTitle" style={{ color: "#fff" }}>
                            {translation("Form.Formchangepas")}
                          </h2>
                          <div className="inputDiv">
                            <label
                              className="form-label"
                              for="form3Example1c"
                              style={{ marginTop: 15 }}
                            ></label>
                            <div className="" style={{ display: "flex" }}>
                              <img src={img} className="foricon" />{" "}
                              <input
                                type="password"
                                placeholder="Old Password"
                                id="form3Example1c ChangePass"
                                className="form-control"
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                            <span style={{ color: "red" }}>
                              {passworderror}
                            </span>
                          </div>
                          <div className="inputDiv">
                            <label
                              className="form-label"
                              for="form3Example4c"
                              style={{ marginTop: 15 }}
                            ></label>
                            <div style={{ display: "flex" }}>
                              <img src={img2} className="foricon" />
                              <input
                                type={showPassword ? "text" : "password"}
                                placeholder="New Password"
                                id="form3Example4c ChangeNewpass"
                                className="form-control"
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                            </div>
                            <span style={{ color: "red" }}>
                              {newpassworderror}
                            </span>
                          </div>
                          <div className="inputDiv">
                            <label
                              className="form-label"
                              for="form3Example4c"
                              style={{ marginTop: 15 }}
                            ></label>
                            <div style={{ display: "flex" }}>
                              <img src={img3} className="foricon" />
                              <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                id="form3Example4c  ChangeConfirmpass"
                                className="form-control"
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                            <span style={{ color: "red" }}>
                              {confirmpassworderror}
                            </span>
                          </div>
                          <div className="form-outline flex-fill mb-0">
                            {allfield.length > 0 && (
                              <span style={{ color: "red" }}>{allfield}</span>
                            )}
                          </div>
                          <div
                            className="d-flex justify-content-center mx-4 mb-3 mb-lg-4"
                            style={{ marginTop: 10 }}
                          >
                            <button
                              type="submit"
                              name="changepass"
                              className="changepass"
                            >
                              {translation("Form.Formchangepas")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* <div className="container change-password"> */}
                    {/* <h3>Change Password</h3>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-lg-12 col-xl-11">
                                <div className="card-body p-md-1">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                                            <form className="mx-1 mx-md-4" onSubmit={changePassword}>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label className="form-label" for="form3Example1c">Old Password</label>
                                                        <input type="password" placeholder="Old Password" id="form3Example1c" className="form-control"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <span style={{color:"red"}}>{passworderror}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                    <div className="form-outline password-Eyes flex-fill mb-0">
                                                        <label className="form-label" for="form3Example4c">New Password</label>
                                                        <input type={showPassword ? "text" : "password"} placeholder="New Password" id="form3Example4c" className="form-control"
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                        />
                                                     
                                                      */}

                    {/* <span style={{color:"red"}}>{newpassworderror}</span>
                                                        {showPassword ? (<VisibilityIcon
                                                            className="password-icon"
                                                            onClick={loginPasswordVisibilityClick} />)
                                                            : (<VisibilityOffIcon fill="grey"
                                                                className="password-icon"
                                                                onClick={loginPasswordVisibilityClick}
                                                            />)} */}

                    {/* <span style={{color:"red"}}>{newpassworderror}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                    <div className="form-outline password-Eyes flex-fill mb-0">
                                                        <label className="form-label" for="form3Example4c">Confirm Password</label>
                                                        <input type={showPassword ? "text" : "password"} placeholder="Confirm Password" id="form3Example4c" className="form-control"
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                        /> */}

                    {/* <span style={{color:"red"}}>{confirmpassworderror}</span>
                                                        {showPassword ? (<VisibilityIcon
                                                            className="password-icon"
                                                            onClick={loginPasswordVisibilityClick} />)
                                                            : (<VisibilityOffIcon fill="grey"
                                                                className="password-icon"
                                                                onClick={loginPasswordVisibilityClick}
                                                            />)} */}

                    {/* 
                                                            <span style={{color:"red"}}>{confirmpassworderror}</span>
                                                    </div>
                                                </div>
                                                <div className="form-outline flex-fill mb-0">{allfield.length > 0 && (<span style={{color:"red"}}>{allfield}</span>)}</div>
                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div> */}
                    {/* </div>  */}
                    {/* </section> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default ChangePassword;
