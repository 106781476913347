import React, { useEffect, useState } from "react";
import * as bootstrap from "bootstrap"; // Import Bootstrap JS
import "quill/dist/quill.snow.css"; // Import Quill styles
import "simple-datatables/dist/style.css";
import { useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useNavigate } from "react-router-dom";
import { Link, Routes, Route } from "react-router-dom";
import "./Dashboard.css";
import Dashboard from "./Home";
import StudentInformation from "../MyProfile/StudentInformation";
import EducationalInformation from "../MyProfile/EducationalInformation";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import ExamRoom from "../ExamRoom/ExamRoom";
import MainExam from "../ExamRoomResult/MainExam";
import Subscription from "../MyProfile/Subscription";
import ExamRoom2 from "../ExamRoom2/ExamRoom2";
import ExamRoomResult from "../ExamRoomResult/ExamRoomResult";
import ExamResult from "../MyPerformance/ExamResult/ExamResultTable";
import Performance from "../MyPerformance/ExamResult/Performance";
import QaCharts from "../MyPerformance/ExamResult/QaCharts";
import Resultchart from "../MyPerformance/ExamResult/Resultchart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Studymaterial from "../PdfVIewer/Studymaterial";
import PdfComponent from "../PdfVIewer/PdfComponent";
import PdfComponentten from "../PdfVIewer/PdfComponentten";
import PdfComponentele from "../PdfVIewer/PdfComponentele";
import PdfComponenttwel from "../PdfVIewer/PdfComponenttwel";
import FAQ from "../FAQ/FAQ";
import { Contact } from "../Contact/Contact";
import OurPricing from "../HomePage/OurPricing";
import OurPricing2 from "../HomePage/OurPricing2";
import AboutUsMenu from "../AboutUs/AboutUsMenu";
import ContactUs from "../Contact/ContactUs";
import { WelcomeScreen } from "../../../Welcome/Home";
import Home from "./Home";
import Comingsoon from "./Comingsoon";
import ChangePassword from "../My Account/ChangePassword";
import SelfQuestion from "../Selfassesment/SelfQuestion";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";
import CreateIcon from "@mui/icons-material/Create";
import BookIcon from "@mui/icons-material/Book";
import SpeedIcon from "@mui/icons-material/Speed";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import CallIcon from "@mui/icons-material/Call";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";
import KeyIcon from "@mui/icons-material/Key";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Quill from "quill";

const Layout = (props) => {
  // const { name } = props;
  const navigate = useNavigate();

  const location = useLocation();

  const [name, setName] = useState();
  const [token, setToken] = useState();
  const [studentid, setStudentId] = useState("");
  const [gradeid, SetgRadeid] = useState();
  const [boardid, Setboardid] = useState();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const isExamResultPage = location.pathname === "/examresult";

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const hideMenu = () => {
    setIsMenuVisible(false);
  };

  const getPathName = (path) => {
    const parts = path.split("/");
    return parts[parts.length - 1];
  };

  useEffect(() => {
    const tok = sessionStorage.getItem("token");

    const userId = sessionStorage.getItem("userId");
    console.log(userId);
    if (token != null && userId != null) {
      getStudentInfo(token, userId);
      // displayImageFromBackend();
    }
    if (tok !== null || tok !== undefined) {
      setToken(tok);
      setStudentId(userId);
      // getperformance(tok);
      //  setSubjectId()
      //  setFormDate()
      //  setToDate()
    }
  }, [token, studentid]);

  const logOutProfile = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("otp");
    sessionStorage.removeItem("f");
    sessionStorage.removeItem("userId");
    localStorage.removeItem("ll");
    localStorage.removeItem("tok");
    localStorage.removeItem("userId");
    localStorage.removeItem("sub");
    localStorage.removeItem("stname");
    navigate("/");
    reload();
  };
  // Nav Refresh
  const reload = () => {
    window.location.reload(true);
  };

  // studnet data APi
  const getStudentInfo = async (token, StudentId) => {
    console.log(StudentId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.AUTOFILL}${StudentId}`
      );
      console.log(data);
      setName(data.stName);
      SetgRadeid(data.grade);
      Setboardid(data.boardName);
      // setSelectedImage(data.studentPhoto)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (function () {
      "use strict";

      const select = (el, all = false) => {
        el = el.trim();
        if (all) {
          return [...document.querySelectorAll(el)];
        } else {
          return document.querySelector(el);
        }
      };

      /**
       * Easy event listener function
       */
      const on = (type, el, listener, all = false) => {
        if (all) {
          select(el, all).forEach((e) => e.addEventListener(type, listener));
        } else {
          select(el, all).addEventListener(type, listener);
        }
      };

      /**
       * Easy on scroll event listener
       */
      const onscroll = (el, listener) => {
        el.addEventListener("scroll", listener);
      };

      /**
       * Sidebar toggle
       */
      if (select(".toggle-sidebar-btn")) {
        on("click", ".toggle-sidebar-btn", function (e) {
          select("body").classList.toggle("toggle-sidebar");
        });
      }

      /**
       * Search bar toggle
       */
      if (select(".search-bar-toggle")) {
        on("click", ".search-bar-toggle", function (e) {
          select(".search-bar").classList.toggle("search-bar-show");
        });
      }

      /**
       * Navbar links active state on scroll
       */
      let navbarlinks = select("#navbar .scrollto", true);
      const navbarlinksActive = () => {
        let position = window.scrollY + 200;
        navbarlinks.forEach((navbarlink) => {
          if (!navbarlink.hash) return;
          let section = select(navbarlink.hash);
          if (!section) return;
          if (
            position >= section.offsetTop &&
            position <= section.offsetTop + section.offsetHeight
          ) {
            navbarlink.classList.add("active");
          } else {
            navbarlink.classList.remove("active");
          }
        });
      };
      window.addEventListener("load", navbarlinksActive);
      onscroll(document, navbarlinksActive);

      /**
       * Toggle .header-scrolled class to #header when page is scrolled
       */
      let selectHeader = select("#header");
      if (selectHeader) {
        const headerScrolled = () => {
          if (window.scrollY > 100) {
            selectHeader.classList.add("header-scrolled");
          } else {
            selectHeader.classList.remove("header-scrolled");
          }
        };
        window.addEventListener("load", headerScrolled);
        onscroll(document, headerScrolled);
      }

      /**
       * Back to top button
       */
      let backtotop = select(".back-to-top");
      if (backtotop) {
        const toggleBacktotop = () => {
          if (window.scrollY > 100) {
            backtotop.classList.add("active");
          } else {
            backtotop.classList.remove("active");
          }
        };
        window.addEventListener("load", toggleBacktotop);
        onscroll(document, toggleBacktotop);
      }

      /**
       * Initiate tooltips
       */
      var tooltipTriggerList = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
      });

      /**
       * Initiate quill editors
       */
      if (select(".quill-editor-default")) {
        new Quill(".quill-editor-default", {
          theme: "snow",
        });
      }

      if (select(".quill-editor-bubble")) {
        new Quill(".quill-editor-bubble", {
          theme: "bubble",
        });
      }

      if (select(".quill-editor-full")) {
        new Quill(".quill-editor-full", {
          modules: {
            toolbar: [
              [
                {
                  font: [],
                },
                {
                  size: [],
                },
              ],
              ["bold", "italic", "underline", "strike"],
              [
                {
                  color: [],
                },
                {
                  background: [],
                },
              ],
              [
                {
                  script: "super",
                },
                {
                  script: "sub",
                },
              ],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
                {
                  indent: "-1",
                },
                {
                  indent: "+1",
                },
              ],
              [
                "direction",
                {
                  align: [],
                },
              ],
              ["link", "image", "video"],
              ["clean"],
            ],
          },
          theme: "snow",
        });
      }

      /**
       * Initiate TinyMCE Editor
       */
      const useDarkMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      const isSmallScreen = window.matchMedia("(max-width: 1023.5px)").matches;

      /**
       * Initiate Bootstrap validation check
       */
      var needsValidation = document.querySelectorAll(".needs-validation");

      Array.prototype.slice.call(needsValidation).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });

      // const datatables = select(".datatable", true);
      // datatables.forEach((datatable) => {
      //   new simpleDatatables.DataTable(datatable);
      // });
      // const mainContainer = select("#main");
      // if (mainContainer) {
      //   setTimeout(() => {
      //     new ResizeObserver(function () {
      //       select(".echart", true).forEach((getEchart) => {
      //         echarts.getInstanceByDom(getEchart).resize();
      //       });
      //     }).observe(mainContainer);
      //   }, 200);
      // }
    })();
  }, []);

  return (
    <>
      <div>
        {!isExamResultPage && (
          <header
            id="header"
            className={`headers fixed-top d-flex align-items-center ${
              isMenuVisible ? "toggle-sidebar" : ""
            }`}
          >
            <div className="d-flex align-items-center justify-content-between">
              <MenuIcon
                onClick={toggleMenu}
                style={{ fontSize: "30px", margin: "10px" }}
                className="menu-icon"
              />

              <a href="" className="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="" />
                <span
                  className="d-none d-lg-block"
                  onClick={(e) => navigate("/homepage")}
                >
                  <img
                    style={{ height: 57 }}
                    src={BlackThemeLogo}
                    alt="themeLogo"
                    id="logomain"
                  />
                </span>
              </a>
              {/* <DehazeIcon/> */}
            </div>
            {/* End Logo */}
            <div className="search-bar">
              {/* <form
              className="search-form d-flex align-items-center"
              method="POST"
              action="#"
            >
              <input
                type="text"
                name="query"
                placeholder="Search"
                title="Enter search keyword"
              />
              <button type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </form> */}
            </div>
            {/* End Search Bar */}
            <div className="headers-navbar ms-auto">
              <ul className="d-flex align-items-center">
                <li className="nav-item d-block d-lg-none">
                  <a className="nav-links nav-icon search-bar-toggle " href="#">
                    <img
                      style={{ height: 57 }}
                      src={BlackThemeLogo}
                      alt="themeLogo"
                      id="logomain"
                    />
                  </a>
                </li>
                {/* End Search Icon*/}
                <div className="d-none d-md-block">
                  <p style={{ marginBottom: 0, marginLeft: 21 }}>{name}</p>
                  <div className="d-flex ">
                    <p style={{ marginBottom: 0 }}>{gradeid}</p>
                    <p
                      id="p222"
                      style={{ marginBottom: 0, marginRightRight: "10px" }}
                    >
                      {boardid}
                    </p>
                  </div>
                </div>

                {/* <li className="nav-item dropdown">
                <a
                  className="nav-links nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                  id="bell"
                >
                  <i className="bi bi-bell" />
                  <span className="badge bg-primary badge-number">2</span>
                </a>
              </li> */}

                {/* End Messages Nav */}
                <li className="nav-item dropdown pe-3">
                  <a
                    className="nav-links nav-profile d-flex align-items-center pe-0"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    <AccountCircleIcon />
                  </a>
                  {/* End Profile Iamge Icon */}
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                    <li className="dropdown-header">
                      <h6>{name}</h6>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li onClick={hideMenu}>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                        onClick={(e) => navigate("/change-password")}
                      >
                        <KeyIcon style={{ margin: "5px" }} />
                        <span style={{ color: "#666" }}>Change Password</span>
                      </a>
                    </li>
                    <li onClick={hideMenu}>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                        onClick={(e) => navigate("/student-information")}
                      >
                        <AccountCircleIcon style={{ margin: "5px" }} />
                        <span style={{ color: "#666" }}>
                          Student Information
                        </span>
                      </a>
                    </li>
{/*                 
                    <li onClick={hideMenu}>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                        onClick={(e) => navigate("/PlanUpdate")}
                      >
                        <UpgradeIcon style={{ margin: "5px" }} />
                        <span style={{ color: "#666" }}>
                         Upgrade Plan
                        </span>
                      </a>
                    </li> */}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                        onClick={logOutProfile}
                      >
                        <ExitToAppIcon style={{ margin: "5px" }} />
                        <span>Sign Out</span>
                      </a>
                    </li>
                  </ul>
                  {/* End Profile Dropdown Items */}
                </li>
                {/* End Profile Nav */}
              </ul>
            </div>
            {/* End Icons Navigation */}
          </header>
        )}
        {/* End Header */}
        {/* ======= Sidebar ======= */}
        <div
          className={`layout ${isMenuVisible ? "toggle-sidebar" : ""}`}
          onClick={hideMenu} // Close menu when clicking outside
        >
          {!isExamResultPage && (
            <aside
              id="sidebar"
              className={`sidebar ${isMenuVisible ? "toggle-sidebar" : ""}`}
              onClick={(e) => e.stopPropagation()} // Prevent menu from closing when clicking inside
            >
              <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/Homepage">
                    <HomeIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Home</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/exam-room">
                    <CreateIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Exam</span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-links" to="/self-Question">
                    <BookIcon  style={{  margin: "5px" }}/>
                    <span style={{ color: "#666" }}>
                      SelfAssesment Training
                    </span>
                  </Link>
                </li> */}
                <li class="nav-item" >
                  <a
                    class="nav-links collapsed"
                    data-bs-target="#icons-nav"
                    data-bs-toggle="collapse"
                    href="#"
                  >
                    <Link to="/Performance">
                      <SpeedIcon style={{ margin: "5px" }} />
                      <span style={{ color: "#666" }}>My Performance</span>
                    </Link>
                    <KeyboardArrowDownIcon style={{ margin: "5px" }} />
                  </a>
                  <ul
                    id="icons-nav"
                    class="nav-content collapse "
                    data-bs-parent="#sidebar-nav"
                  >
                    <Link to="/Performance" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>My Performance</span>
                    </Link>
                    <Link to="/exam-result" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>Exam Result</span>
                    </Link>
                    <Link to="/Result" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>Monthly Report</span>
                    </Link>
                    <Link to="/Result-chart" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>Subject Report</span>
                    </Link>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-links collapsed"
                    data-bs-target="#icons-nav-materials"
                    data-bs-toggle="collapse"
                    href="#"
                  >
                    <Link to="/Studymaterials">
                      <AutoStoriesIcon style={{ margin: "5px" }} />

                      <span style={{ color: "#666" }}>Study Materials</span>
                    </Link>
                    <KeyboardArrowDownIcon style={{ margin: "5px" }} />
                  </a>
                  <ul
                    id="icons-nav-materials"
                    class="nav-content collapse "
                    data-bs-parent="#sidebar-nav"
                  >
                    <Link to="/Studymaterials" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>Text Books</span>
                    </Link>
                    <Link to="/Comingsoon" onClick={hideMenu}>
                      <CircleIcon
                        style={{
                          margin: "5px",
                          padding: "0px",
                          fontSize: "10px",
                        }}
                      />
                      <span style={{ color: "#666" }}>
                        Previous Year Questions
                      </span>
                    </Link>
                  </ul>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-links" to="/student-information">
                    <i class="bi bi-person-circle"></i>
                    <span style={{ color: "#666" }}>Student Information</span>
                  </Link>
                </li> */}

                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/Comingsoon">
                    <EmojiEventsIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Awards</span>
                  </Link>
                </li>
                {/* 
                <li className="nav-item">
                  <Link className="nav-links" to="/Comingsoon">
                    <i class="bi bi-people-fill"></i>
                    <span style={{ color: "#666" }}>Group Exam</span>
                  </Link>
                </li> */}
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/Comingsoon">
                    <PersonAddIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Referral</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/support">
                    <SupportAgentIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Support</span>
                  </Link>
                </li>
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/faq">
                    <ContactSupportIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>FAQ</span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link className="nav-links" to="/aboutus">
                  <i class="bi bi-info-circle-fill"></i>
                  <span style={{ color: "#666" }}>About Us</span>
                </Link>
              </li> */}
                <li className="nav-item" onClick={hideMenu}>
                  <Link className="nav-links" to="/contact">
                    <CallIcon style={{ margin: "5px" }} />
                    <span style={{ color: "#666" }}>Contact Us</span>
                  </Link>
                </li>

                {/* Add more sidebar links here */}
              </ul>
            </aside>
          )}
        </div>
        {/* End Sidebar*/}
        <main
          id={isExamResultPage ? "maniss" : "main"}
          className={`main ${isExamResultPage ? "maniss" : ""}`}
        >
          <div className="pagetitle">
            {/* <h1>Dashboard</h1> */}
            {/* <nav> */}
            {/* <ol className=" add-listings-boxsess ">
              <h3>
                {getPathName(location.pathname)}
              </h3>
            </ol> */}
            {/* </nav> */}
          </div>
          {/* End Page Title */}
          <section className="section dashboard">
            <Routes>
              {/* Add more routes for other content */}
              <Route
                path="/student-information"
                element={<StudentInformation />}
              />
              <Route
                path="/educational-information"
                element={<EducationalInformation />}
              />
              <Route path="/exam-room" element={<ExamRoom />} />
              <Route path="/examresult" element={<MainExam />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/examroom-result" element={<ExamRoomResult />} />
              <Route path="/exam-room2" element={<ExamRoom2 />} />
              <Route path="/Performance" element={<Performance />} />
              <Route path="/Result" element={<QaCharts />} />
              <Route path="/exam-result" element={<ExamResult />} />
              <Route path="/Result-chart" element={<Resultchart />} />
              <Route path="/Studymaterials" element={<Studymaterial />} />
              <Route path="/SUbjectpdf" element={<PdfComponent />} />
              <Route path="/SUbjectpdf10" element={<PdfComponentten />} />
              <Route path="/SUbjectpdf11" element={<PdfComponentele />} />
              <Route path="/SUbjectpdf12" element={<PdfComponenttwel />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/ourpricing" element={<OurPricing />} />
              <Route path="/PlanUpdate" element={<OurPricing2 />} />
              <Route path="/aboutus" element={<AboutUsMenu />} />
              <Route path="/support" element={<ContactUs />} />
              <Route path="/TheExamMate" element={<WelcomeScreen />} />
              <Route path="/Homepage" element={<Home />} />
              <Route path="/Comingsoon" element={<Comingsoon />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/self-Question" element={<SelfQuestion />} />
              {/*  */}
            </Routes>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        {/* <footer id="footer" className="footer">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>TheExamMate</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits"></div>
        </footer> */}
      </div>
    </>
  );
};

export default Layout;
