import blog1 from "../../../assets/Images/Blog/blog1.png";
import blog2 from "../../../assets/Images/Blog/blog2.png";
import blog3 from "../../../assets/Images/Blog/blog3.png";
import blog4 from "../../../assets/Images/Blog/blog4.png";
import blog5 from "../../../assets/Images/Blog/blog5.png";
export const OurBlogData= [
    {
        id: 1,
        image: blog1,
        date: "17 June 2023",
        profile:"By Admin",
        title: "Strategies for Multiple Choice Exams Tips and Tricks",              
        contain: "When it comes to multiple-choice exams, having a plan can significantly enhance your chances of achieving success. Here are some tips and tricks to help you tackle multiple-choice exams effectively Review the instructions" 
           },
      {
        id: 2,
        image: blog2,
        date: "10 March 2023",
        profile:"By Admin",
        title: "Time management during exams",              
        contain: "Time management during exams is crucial for several reasons. Here are some key points emphasising the importance of effective time management during exams:Optimal Resource Allocation: Time management helps you allocate your limited time resources effectively. With a clear plan and schedule,"   
      },
      {
        id: 3,
        image: blog3,
        date: "3 April 2023",
        profile:"By Admin",
        title: "How to Overcome Exam Anxiety and Perform Your Best",              
        contain: "Exam anxiety is a common occurrence among students, but there are ways to manage it and perform at your best. Because feeling unprepared can make you anxious, preparation is crucial. Set aside time for each subject, break the material down into manageable chunks,  "   
      },
      {
        id: 4,
        image: blog4,
        date: "14 March 2022",
        profile:"By Admin",
        title: "Effective Study Techniques",              
        contain: "Achieving success in exams requires more than just attending classes and reading textbooks. It demands effective study techniques that help optimize learning and retention. In this essay, we will explore various strategies that can significantly enhance exam preparation and increase the chances of success. "   
      },
      // {
      //   id: 5,
      //   image: blog5,
      //   date: "14 March 2022",
      //   profile:"By Admin",
      //   title: "Secure active living depend son repair day ladies now.",              
      //   contain: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since to 00s, when an unknown printer."   
      // },
 
];
export default OurBlogData;