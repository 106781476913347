import { PhotoSizeSelectLargeTwoTone, Subject } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { createSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Examroom2.css";
import Swal from "sweetalert2";
import Logo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const ExamRoom2 = () => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const isTabletView = useMediaQuery("(min-width:768px)");
  const isTabletViewEnd = useMediaQuery("(min-width:1024px)");
  const isMobileview = useMediaQuery("(min-width:320px)");

  const [allQuestion, setAllQuestion] = useState([]);
  const [allChapter, setAllchapter] = useState([]);
  const [token, setToken] = useState("");
  const [EQPId, setEQPId] = useState(null);
  const [QuestionId, setQuestionId] = useState();
  const dataCreate = {};
  const [EQBDId, setEQBDId] = useState(null);
  const [Answer, setAnswer] = useState(null);
  const [Result, setResult] = useState(null);
  const [find, setFind] = useState([
    { Answer: "A", Result: "A" },
    { Answer: "A", Result: "B" },
  ]);
  const [ch, setCh] = useState(false);
  const [subject, setSubject] = useState();
  const [chapter, setChapter] = useState();
  const location = useLocation();
  const [TrnEQPAnswer, setTT] = useState([]);
  const [qq, setQQ] = useState("");
  const [ob, setOb] = useState([]);
  const [isSubmitConfirmed, setIsSubmitConfirmed] = useState(false);
  let what;
  const styleOne = {};

  const Navigate = useNavigate();
  const scrollRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;

  // const buttons = ;

  useEffect(() => {
    const tok = sessionStorage.getItem("token");
    if (tok != null || tok != undefined) {
      setToken(tok);
    }
    if (location.state) {
      setAllQuestion(location.state.data);
      setSubject(location.state.show);
      setChapter(location.state.chap);
      setQQ(location.state.noOfQuestion);
      console.log(location.state.d);
    }
    setIsSubmitConfirmed(false);
  }, []);

  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds + 1);
      if (seconds === 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  // code testing
  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const filterDuplicateAnswers = (answers) => {
    const filteredAnswers = [];
    const eqbdIds = new Set();

    for (const answer of answers) {
      if (!eqbdIds.has(answer.EQBDId)) {
        filteredAnswers.push(answer);
        eqbdIds.add(answer.EQBDId);
      }
    }

    return filteredAnswers;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isSubmitConfirmed) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to submit this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes,  Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsSubmitConfirmed(true);
          submitAnswers();
        }
      });
    } else {
      submitAnswers();
    }
  };

  const submitAnswers = async () => {
    // const TrnEQPAnswers = {
    //   TrnEQPAnswer: filterDuplicateAnswers(TrnEQPAnswer),
    // };
   // Access the EQPId value from the first object in the TrnEQPAnswer array
   const selectedEQPId = TrnEQPAnswer[0].EQPId;

   const TrnEQPAnswers = {
    EQPId: selectedEQPId,
   };
 


    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    setCh(true);

    try {
      const { data } = await axios.post(
        `${window.EXAMURL}${window.EXAMSUBMIT}`,
        TrnEQPAnswers,
        { headers: h }
      );
      console.log(data);

      if (data) {
        Swal.fire({
          icon: "success",
          title: "Submitted!",
          text: "Your data has been submitted.",
        });

        // Navigate to the result page here if needed
        Navigate("/examroom-result", {
          state: { data, TrnEQPAnswer, subject },
        });

        setTT([]);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "No answers selected",
        text: "please select at least one answer",
      });
    }
  };

  // code

  const timerContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const checkedFind = (e, item, index) => {
    const selectedOption = e.target.value;
    setSelectedQuestionIndex(index);

    // Update the selected buttons
    const selectedIndex = selectedButtons.indexOf(index);
    if (selectedIndex !== -1) {
      setSelectedButtons(
        selectedButtons.filter((btnIndex) => btnIndex !== index)
      );
    } else {
      setSelectedButtons([...selectedButtons, index]);
    }

    // Update the TrnEQPAnswer state
    setTT((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      const answerIndex = updatedAnswers.findIndex(
        (answer) => answer.EQBDId === item.id
      );
      if (answerIndex !== -1) {
        // If the answer exists, update it
        updatedAnswers[answerIndex].Answer = selectedOption;

      } else {
        // If the answer doesn't exist, add it to the state
        updatedAnswers.push({
          EQBDId: item.id,
          EQPId: item.eqpId,
          QuestionId: item.questionId,
          Answer: selectedOption,
          Result: "",
        });
      }
      return updatedAnswers;
    });
 let a = {
  EQBDId: item.id,
  EQPId: item.eqpId,
  QuestionId: item.questionId,
  Answer: selectedOption,
  Result: "",
 }
    gobac(a)
  };

  const gobac = async (a,token) =>{
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
   
    let d= {
      TrnEQPAnswer:[a]
    }
    console.log(d)
    try {
      const { data } = await axios.post(
        `${window.EXAMURL}${window.EXAMROOMSINGLESUBMIT}`,
        d,
        { headers: h }
      );
      console.log(data);
  
    } catch (error) {
      console.log(error);
    }
  }

  const goToQuestion = (questionIndex) => {
    if (questionIndex >= 0 && questionIndex < allQuestion.length) {
      const question = allQuestion[questionIndex];
      const element = document.getElementById(question.questionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      setSelectedQuestionIndex(questionIndex);
      setCurrentPage(Math.ceil((questionIndex + 1) / questionsPerPage));
    } else {
      setSelectedQuestionIndex(null); // Deselect the current question if index is out of bounds
    }
  };

  const config = {
    loader: { load: ["input/asciimath"] },
  };

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light rjs-nav"
        id="examnav"
      >
        <div className="container-fluid">
          <div>
            <a className="navbar-brand" href="#">
              <img src={Logo} height={60} />
            </a>
          </div>

          <div style={timerContainerStyle}>
            <h1 className="timerStyle">
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </h1>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="selectbuttondiv d-none d-md-block">
            <button
              className="arrowButton"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <KeyboardArrowLeftIcon />
            </button>
            {allQuestion.map((item, index) => {
              const questionNumber = index + 1;
              const currentPageNumber = Math.ceil(
                (index + 1) / questionsPerPage
              );
              const isSelected = selectedButtons.includes(index);

              if (currentPageNumber === currentPage) {
                const buttonClassName = `selectButton ${
                  isSelected ? "selected" : ""
                }`;

                return (
                  <button
                    className={buttonClassName}
                    key={index}
                    onClick={() => goToQuestion(index)}
                  >
                    {questionNumber}
                  </button>
                );
              }
              return null;
            })}

            <button
              className="arrowButton"
              disabled={
                currentPage === Math.ceil(allQuestion.length / questionsPerPage)
              }
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <KeyboardArrowRightIcon />
            </button>
          </div>
        </div>
      </nav>
      <div ref={scrollRef}>
        <div class="container exam-room rjs-p-top">
          <div class="row">
            <form onSubmit={gobac}>
              {allQuestion?.map((item, index) => {
                return (
                  <div key={index} id={item.questionId} ref={scrollRef}>
                    <div
                      class="col-lg-12 col-md-12 p-2"
                      key={index}
                      id="overAll"
                    >
                      <div class="form-group">
                        <div className="question">
                          <div className="question">
                            {item.displayType === "HTML" && (
                              <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: `${index + 1}. ${item.question}`,
                                }}
                              />
                            )}
                          </div>
                          <div className="question">
                            {item.displayType === "TEXT" && (
                              <MathJaxContext config={config}>
                                <MathJax>
                                  {" "}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${index + 1}. ${item.question}`,
                                    }}
                                  />
                                </MathJax>
                              </MathJaxContext>
                            )}
                          </div>
                        </div>
                        {item.displayType === "IMAGE" && (
                          <>
                            <div className="image-web">
                              {/* Display the question index */}
                              <p>{index + 1}.</p>
                              {/* Display the image */}
                              <img alt="imageQ" src={item.questionImageWeb} />
                            </div>
                            <div className="image-tab">
                              {/* Display the question index */}
                              <p>{index + 1}.</p>
                              {/* Display the image */}
                              <img alt="imageQ" src={item.questionImageTab} />
                            </div>
                            <div className="image-mobile">
                              {/* Display the question index */}
                              <p>{index + 1}.</p>
                              {/* Display the image */}
                              <img
                                alt="imageQ"
                                src={item.questionImageMobile}
                              />
                            </div>
                          </>
                        )}

                        {/*                      
                        <div className="image-web">
                          {item.displayType === "IMAGE" && (
                           
                            <img
                              alt="imageQ"
                              src={item.questionImageWeb}
                            />
                          )}
                        </div>
                        <div className="image-tab">
                          {item.displayType === "IMAGE" && (
                            <img
                              alt="imageQ"
                              src={item.questionImageTab}/>
                          )}
                        </div>
                        <div className="image-mobile">
                          {item.displayType === "IMAGE" && (
                            <img
                              alt="imageQ"
                              src={item.questionImageMobile}
                            />
                          )}
                        </div> */}
                        <div className="question-body">
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="option"
                              >
                                <input
                                  className="large"
                                  type="radio"
                                  id={item.answerA}
                                  name={item.id}
                                  value={"a"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => checkedFind(e, item, index)}
                                />
                                (A)
                                <label for={item.answerA}>
                                  <MathJaxContext config={config}>
                                    <MathJax>{item.answerA}</MathJax>
                                  </MathJaxContext>
                                </label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="option"
                              >
                                <input
                                  className="large"
                                  type="radio"
                                  id={item.answerB}
                                  name={item.id}
                                  value={"b"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => checkedFind(e, item, index)}
                                  label="B"
                                />
                                (B)
                                <label for={item.answerB}>
                                  <MathJaxContext config={config}>
                                    <MathJax>{item.answerB}</MathJax>
                                  </MathJaxContext>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 col-md-12 question-ans">
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="option"
                              >
                                <input
                                  className="large"
                                  type="radio"
                                  id={item.answerC}
                                  name={item.id}
                                  value={"c"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => checkedFind(e, item, index)}
                                />
                                (C)
                                <label for={item.answerC}>
                                  <MathJaxContext config={config}>
                                    <MathJax>{item.answerC}</MathJax>
                                  </MathJaxContext>
                                </label>
                              </div>
                              <div
                                className="d-flex align-items-baseline radio-cursor"
                                id="option"
                              >
                                <input
                                  className="large"
                                  type="radio"
                                  id={item.answerD}
                                  name={item.id}
                                  value={"d"}
                                  style={{ marginLeft: "5px" }}
                                  onChange={(e) => checkedFind(e, item, index)}
                                />
                                (D)
                                <label for={item.answerD}>
                                  <MathJaxContext config={config}>
                                    <MathJax>{item.answerD}</MathJax>
                                  </MathJaxContext>
                                </label>
                              </div>
                            </div>
                            {/* className={ch ? "A" == find[index].Result ? "easy" :find[index].Answer == 'A'? "noeasy" :"" :""} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div class="col-lg-12 col-md-12">
                <div
                  class="form-group"
                  id="flexbuttons"
                  style={{ display: "flex" }}
                >
                  <div>
                    {" "}
                    <button onClick={() => Navigate("/exam-room")} name="back">
                      {translation("Exams.Examback")}
                    </button>
                  </div>
                  <div>
                    {" "}
                    <button className="loginBtn" type="submit" onClick={handleFormSubmit} name="Exambtn2">
                      {translation("Exams.ExamSubmit")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamRoom2;
