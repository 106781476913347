import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TextsmsIcon from '@mui/icons-material/Textsms';
import LoadingScreen from "../Loading/Loadingscreen";


function SelfQuestion() {
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [noOfQuestion, setNoOfQuestion] = useState("");
  const [error, setError] = useState("");
  const [allSubject, setAllSubject] = useState([]);
  const [allChapter, setAllChapter] = useState([]);
  const [token, setToken] = useState("");
  const [studentId, setStudentId] = useState("");
  const [name, setName] = useState();
  const [isLoading,setIsLoading] = useState(false);

  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  // Validation Error
  const [subjecterror, setSubjectError] = useState("");
  const [chaptererror, setChapterError] = useState("");
  const [NoofQerror, setNoofQeError] = useState("");

  //   accordion

  useEffect(() => {
    const tok = sessionStorage.getItem("token");
    console.log(tok);

    const userId = sessionStorage.getItem("userId");
    if (tok != null || tok != undefined) {
      console.log(tok);
      getSubject(tok);
      setToken(tok);
      setStudentId(userId);
      // getNoOfQuestion(tok)
    }
  }, []);

  const getSubject = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      console.log(window.SUBJECT);
      const { data } = await axios.get(`${window.BASEURL}${window.SUBJECT}`);
      console.log(data);
      if (data) {
        setAllSubject(data);
        setCheck(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChapter = async (subjectId) => {
    console.log(subjectId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      console.log(subjectId);
      console.log(window.CHAPTER);
      const { data } = await axios.get(`${window.BASEURL}${window.CHAPTER}/${subjectId}`, {
        headers: h,
      });
      console.log(data);
      setAllChapter(data);
    } catch (error) {
      console.log(error);
    }
  };

  const createExamRoom = async (e) => {
    e.preventDefault();
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      StudentId: parseInt(studentId),
      SubjectId: parseInt(subject),
      ChapterId: parseInt(chapter),
      NoofQ: parseInt(noOfQuestion),
    };
    if (subject == "") {
      setSubjectError("Please select Subject");
    }
    if (chapter == "") {
      setChapterError("Please Select Chapter");
    }
    if (noOfQuestion == "") {
      setNoofQeError("Please Select No Of Questions");
    } else {
      setIsLoading(true)
      setError("");
      try {
        console.log(window.CREATE);
        const { data } = await axios.post(`${window.EXAMURL}${window.CREATE}`, d, {
          headers: h,
        });
        console.log(data);
        if (data) {
          setIsLoading(false)
          let index = allSubject.findIndex((item) => item.subjectId == subject);
          let inde = allChapter.findIndex((item) => item.id == chapter);
          console.log(index);
          let show = allSubject[index].subjectName;
          console.log(show);
          let chap = allChapter[inde].chapterName;
          console.log(chap);
          navigate("/self-assesement", { state: { data, show, chap } });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ):(      
      <div class="container add-listings-box">
        <h3>Self Assesement Training</h3>
        <form onSubmit={createExamRoom}>
          <div class="row">
            <div className="col-lg-4 col-md-6 col-sm-6"></div>
            <div class="col-lg-2 col-sm-6 col-md-4">
              <div class="form-group">
                <h4>
                  Subject :<span style={{ color: "red" }}>*</span>
                </h4>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="form-group">
                <select
                id="SelfQuestionSub"
                  class="dashbaord-category-select"
                  onChange={(e) => {
                    getChapter(e.target.value);
                    setSubject(e.target.value);
                    setName(e.target.name);
                    console.log(e.target.name);
                  }}
                >
                  <option value="">Select Subject</option>
                  {console.log({ allSubject })}
                  {allSubject?.map((item, index) => {
                    return (
                      <option value={item.subjectId} key={index}>
                        {item.subjectName}
                      </option>
                    );
                  })}
                </select>
                <span style={{ color: "red" }}>{subjecterror}</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6"></div>
            <div className="col-lg-4 col-md-6 col-sm-6"></div>
            <div class="col-lg-2 col-sm-6 col-md-4">
              <div class="form-group">
                <h4>
                  Chapter :<span style={{ color: "red" }}>*</span>
                </h4>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="form-group">
                <select
                id="SelfQuestionChap"
                  class="dashbaord-category-select"
                  onChange={(e) => setChapter(e.target.value)}
                >
                  <option value="">Select Chapter</option>
                  {allChapter?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.chapterName}
                      </option>
                    );
                  })}
                </select>
                <span style={{ color: "red" }}>{chaptererror}</span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6"></div>
            <div className="col-lg-4 col-md-6 col-sm-6"></div>
            <div class="col-lg-2 col-sm-6 col-md-4">
              <div class="form-group">
                <h4>
                  No.of Question:<span style={{ color: "red" }}>*</span>
                </h4>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
              <div class="form-group">
                <select
                id="SelfQuestionNo"
                  class="dashbaord-category-select"
                  onChange={(e) => setNoOfQuestion(e.target.value)}
                  
                >
                  <option value="">Select No.of Question</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  {/* <option value="40">40</option> */}
                  {/* {noOfQuestion&&noOfQuestion.map((item, index) => {
                                        return <option value={item} key={index}>{item.noOfQuestion}</option>
                                    })}   */}
                </select>
                <span style={{ color: "red" }}>{NoofQerror}</span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div
                class="form-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {error.length > 0 && <span className="error">{error}</span>}
              </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
              <div
                class="form-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button className="loginBtn" type="submit" name="selfquestion">
                  Create  Exam 
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>)}
    </div>
  );
}

export default SelfQuestion;