import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { color, fontSize } from "@mui/system";
import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useLocation } from "react-router-dom";
import MyProfile from "../My Account/MyProfile";
import "./EducationalInformation.css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import LoadingScreen from "../Loading/Loadingscreen";
import { useTranslation } from "react-i18next";
import Home from "../Dashboard/Home";

import Swal from "sweetalert2";

// components
import StudentInfo from "./StudentInfo";
import { logDOM } from "@testing-library/react";

function EducationalInformation() {
  const [allSchool, setAllSchool] = useState([]);
  const [allBoard, setAllBoard] = useState([]);
  const [allMedium, setAllMedium] = useState([]);
  const [allGrade, setAllGrade] = useState([]);
  const [allSubjectGroup, setAllSubjectGroup] = useState([]);
  const [allregno, setAllRegno] = useState([]);
  const [token, setToken] = useState("");
  const [bid, setBId] = useState("");
  const [countGrade, setCountGrade] = useState();

  const [allLanguage, setAllLanguage] = useState([]);

  const [error, setError] = useState("");

  // validation
  const [userId, setUserId] = useState("");
  const [boardid, setBoardId] = useState("");
  const [gradeid, setGradeId] = useState("");
  const [mediumid, setMediumId] = useState("");
  const [subjectgroupid, setSubjectGroupId] = useState("");
  const [schoolid, setSchoolId] = useState("");
  const [schoolname, setSchoolname] = useState("");
  const [regno, setRegNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [majorError, setMajorError] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const location = useLocation();

  // validation error
  const [boarderror, setBoardError] = useState("");
  const [mediumerror, setMediumError] = useState("");
  const [gradeerror, setGradeError] = useState("");
  const [sbjgrouperror, setSbjGroupError] = useState("");
  const [schoolerror, setSchoolError] = useState("");
  const [regnoerror, setRegNoError] = useState("");
  const [r, setR] = useState();
  const [c, setC] = useState(0);
  const [select, setSelect] = useState([]);
  const [sd, SSSS] = useState(true);
  const [modalShow, setModalShow] = useState(true);
  const [vc, setCHHH] = useState(false);
  const [lang, setLang] = useState([]);
  const [checkboxerror, setCheckboxerror] = useState();
  const [count, setCount] = useState(0);
  const [read, setRead] = useState({
    g: null,
    b: null,
    s: null, // Add the 's' property for the 'School' field
    r: null,
  });

  const Navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const user_Id = sessionStorage.getItem("userId");
    let ll = localStorage.getItem("ll");
    if (ll) {
      setCHHH(true);
    }
    if (token != null && user_Id != null) {
      setToken(token);
      if (c === 0) {
        getStudentInfo(token, user_Id);
        setUserId(user_Id);
      }

      // if(gradeid){
      console.log(gradeid);

      if (gradeid) {
        RR();
      }
      // }
    }
  }, [boardid, r, gradeid]);
  console.log(r);

  const RR = (v) => {
    console.log(v);
    setR(v);
  };
  const navigate = useNavigate();
  const [translation, i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  // console.log(r);
  const [man, setMan] = useState([]);
  console.log(man.length)
  // for checkbox

  const cha = (e) => {
    setMan(e);
  };

  const langN = (value) => {
    console.log("DNDC", value);
    console.log("----------------------Value----------", value);
    // setLang(value);
    setLang(value);
  };
  // useEffect(() => {
  //   setLang("")
  // }, [langN])

  console.log(lang,man);

  // console.log(boardid);/
  // const handleSave = () => {
  //   // Your save logic here
  //   // You can also check majorError here and show an error message
  //   if (majorError) {
  //     // Display an error message or take appropriate action
  //     console.log("Major selection is empty");
  //   } else {
  //     // Perform the save operation
  //     console.log("Save button clicked");
  //   }
  // };
  const getStudentInfo = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.EDUSTUDENTINFO}${id}`
      );
      console.log(data);
      setStudentData(data)
      getSchool(token, data.talukId);
      // setAllBoard(data.boardId)
      setBoardId(data.boardId);
      setRead((prev) => {
        return {
          ...prev,
          b: data.boardId === 0 ? null : data.boardId,
          m: data.mediumId === 0 ? null : data.mediumId,
          g: data.gradeId === 0 ? null : data.gradeId,
          s: data.OthersSchoolName === 0 ? null : data.OthersSchoolName,
          r: data.regNo === "" ? null : data.regNo,
        };
      });
      setMediumId(data.mediumId);
      getAllMedium(data.boardId);
      localStorage.setItem('boardId', data.boardId);
      setGradeId(data.gradeId);
      localStorage.setItem('gradeId', data.gradeId);

      if (data.gradeId != 0) {
        RR(data.gradeId);
      }
      getAllGrade(data.mediumId);
      setSchoolId(data.schoolId);
      setSchoolname(data.othersSchoolName);
      setRegNo(data.regNo);
      setC(1);
    } catch (error) {
      console.log(error);
    }
  };

  const getLanguage = async (token, gradeId) => {
    // console.log(gradeId);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.LANGUAGE}${gradeId}`
      );
      console.log(data);
      setAllLanguage(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSchool = (e) => {
    // setSchool(e.target.value)
    // getAllBoard(e.target.value)
  };
  // const onChangeBoard = (e) => {
  //   getAllMedium(e.target.value);
  //   setBoardId(e.target.value);
  // };


  const getSchool = async (token, id) => {
    console.log(id);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.EDUSCHOOL}${id}`
      );
      console.log(data);
      setAllSchool(data);
      getAllBoard(token);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBoard = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASEURL}${window.EDUBOARD}`);
      console.log(data);
      setAllBoard(data);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeBoard = (e) => {
    getAllMedium(e.target.value);
    setBoardId(e.target.value);
  };

  const getAllMedium = async (id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.EDUMEDIUM}${id}`
      );
      console.log(data);
      setAllMedium(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeMedium = (e) => {
    getAllGrade(e.target.value);
  };

  const getAllGrade = async (id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.EDUGRADE}${id}`
      );
      console.log(data);
      setAllGrade(data);
      // Navigate('/educationinfo')
    } catch (error) {
      console.log(error);
    }
  };

  const getvalid = async (id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASEURL}${window.CHECKBOX}${"/" + id}`
      );
      console.log(id)
      console.log(data);
      setCountGrade(data[0]);
      // setAllGrade(data);
      // Navigate('/educationinfo')
    } catch (error) {
      console.log(error);
    }
  };

  const educationalSubmitForm = async (e) => {
    if (read.g === null) {
      e.preventDefault();
      console.log(
        man.length,
        countGrade?.majSub,
        "-----------------",
        lang.length,
        countGrade?.langSub
      );




      if (boardid == "") {
        setBoardError("Please Select Board");
      } else if (mediumid == "") {
        setMediumError("Please Select Medium");
      } else if (gradeid == "") {
        setGradeError("Please Select Grade");
      } else {
        setError("");
        setBoardError("");
        setMediumError("");
        setGradeError("");
        setSbjGroupError("");
        setRegNoError("");

        if (
          man.length <= countGrade?.majSub && man.length > 0 && lang.length > 0 &&
          lang.length <= countGrade?.langSub
        ) {
          let a = [];
          man.map((item) => {
            let obj = {
              subjectId: item.id,
            };

            a.push(obj);
            console.log(obj, a);
          });
          lang.map((item) => {
            let obj = {
              subjectId: item.id,
            };

            a.push(obj);
            console.log(obj, a);
          });
          const h = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
          const d = {
            Id: parseInt(userId),
            BoardId: parseInt(boardid),
            GradeId: parseInt(gradeid),
            MediumId: parseInt(mediumid),
            SchoolId: 0,
            OthersSchoolName: schoolname,
            RegNo: regno,
            SubjectModel: a,
          };
          try {
            const { data } = await axios.post(
              `${window.BASEURL}${window.EDUCATIONALSUBMITFORM}`,
              d,
              { headers: h }
            );
            console.log(data);
            if (data.result == "Success") {
              Swal.fire({
                icon: "success",
                text: "successfully Submitted",
                title: "Your information was successfully submitted",
                showConfirmButton: false,
                timer: 2500,
              });

              setError("Successfully Submited!", { state: true });
              console.log(gradeid);
              SSSS(false);
              let ll = localStorage.setItem("ll", false);
              setTimeout(() => {
                Navigate("/exam-room", { state: gradeid });
              }, 1000);

            } else {
              setError(data.errorMsgs);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          // if (man.length < countGrade?.majSub + 1)
          if (man.length <= countGrade?.majSub) {
            console.log("DKd", man.length, countGrade.majSub);

            setCheckboxerror(
              `Please choose at least 1 major and 1 language from the provided list`
            );
          } else {
            console.log("DKd", man.length, countGrade.majSub);

            setCheckboxerror(
              `Please choose up to 2 Languages and up to 4 Major  from the provided List`
            );
          }

        }

      }


    } else {
      // Navigate("/subscription");
      Navigate("/exam-room");

    }
  }

  return (
    <div>
      <div className="container add-listings-box educational">
        <MyProfile value={1} />
        <h3>{translation("Exams.ExamTItle")}</h3>
        <form onSubmit={educationalSubmitForm}>
          <div className="row">
            <div class="col-sm col-lg-6" id="educationcard">
              <div className="col-lg-12 col-md-6">
                <div className="col-lg-12 " id="Board">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {translation("Exams.ExamBoard")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={boardid}
                      onChange={onChangeBoard}
                      label=" Board"
                      style={{ marginBottom: 30 }}
                      inputProps={{ readOnly: read.b != null ? true : false }}

                    // readOnly
                    >
                      <MenuItem value="">
                        <em> {translation("Exams.ExamBoardselect")}</em>
                      </MenuItem>
                      {allBoard &&
                        allBoard.map((board) => {
                          console.log(board, boardid);
                          return (
                            <MenuItem key={board.id} value={board.id}>
                              {board.boardName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{boarderror}</span>
                </div>

                <div className="col-lg-12 " id="Board">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {translation("Exams.ExamMedium")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={mediumid}
                      onChange={(e) => {
                        onChangeMedium(e);
                        setMediumId(e.target.value);
                      }}
                      label=" Board"
                      style={{ marginBottom: 30 }}
                      inputProps={{ readOnly: read.m != null ? true : false }}

                    // readOnly
                    >
                      <MenuItem value="">
                        <em> {translation("Exams.ExamMediumselect")}</em>
                      </MenuItem>
                      {allMedium &&
                        allMedium.map((medium) => {
                          return (
                            <MenuItem key={medium.id} value={medium.id}>
                              {medium.mediumName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{mediumerror}</span>
                </div>

                <div className="col-lg-12 " id="Board">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {translation("Exams.ExamGrade")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={gradeid}
                      onChange={(e) => {
                        setGradeId(e.target.value);
                        RR(e.target.value);
                        console.log(e);
                        getvalid(e.target.value);
                        // console.log(e.target.options[e.target.selectedIndex].text);
                        // if(ii){
                        //   setCount(5)
                        // }else{
                        //   setCount(4)
                        // }
                      }}
                      label=" Grade"
                      style={{ marginBottom: 30 }}
                      inputProps={{ readOnly: read.g != null ? true : false }}
                    // readOnly
                    >
                      <MenuItem value="">
                        <em> {translation("Exams.ExamGradeselect")}</em>
                      </MenuItem>
                      {allGrade &&
                        allGrade.map((grade) => {
                          return (
                            <MenuItem key={grade.id} value={grade.id}>
                              {grade.grade}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <span style={{ color: "red" }}>{gradeerror}</span>
                </div>

                <div className="col-lg-12 " id="Board">
                  <TextField
                    id="outlined-required"
                    label={translation("Exams.Examschool")}
                    inputProps={{
                      maxLength: 40,
                      minLength: 2,
                      border: "1px solid green",
                      readOnly: read.s != null ? true : false,
                    }}
                    value={schoolname}
                    onChange={(e) => setSchoolname(e.target.value)}
                    style={{ marginBottom: 30 }}
                    sx={{ width: "100%" }}
                  // readOnly
                  />
                  {/* <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {translation("Exams.Examschool")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={schoolid}
                      onChange={(e) => {
                        onChangeSchool(e);
                        setSchoolId(e.target.value);
                      }}
                      label=" School"
                      style={{ marginBottom: 30 }}
                      inputProps={{ readOnly: read.s != null ? true : false }}
                      // inputProps={{ readOnly: vc?true:false }}
                      // readOnlynpm
                    >
                      <MenuItem value="">
                        <em>Select School</em>
                      </MenuItem>
                      {allSchool &&
                        allSchool.map((school) => {
                          return (
                            <MenuItem key={school.id} value={school.id}>
                              {school.schoolName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl> */}
                  <span style={{ color: "red" }}>{schoolerror}</span>
                </div>

                <div className="col-lg-12 " id="Board">
                  <TextField
                    id="outlined-required"
                    label={translation("Exams.Examregnno")}
                    inputProps={{
                      maxLength: 40,
                      minLength: 2,
                      border: "1px solid green",
                      readOnly: read.r != null ? true : false,
                    }}
                    value={regno}
                    onChange={(e) => setRegNo(e.target.value)}
                    style={{ marginBottom: 30 }}
                    sx={{ width: "100%" }}
                  // readOnly
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    {error}
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="col-sm col-lg-6" id="studentinfo">
              <StudentInfo r={r} cha={cha} ss={sd}/>
            </div> */}
            <div className="col-sm col-lg-6" id="studentinfo">
              {loading ? (
                // Render a loading spinner when loading is true
                // <div style={{ display: "flex", justifyContent: "center" }}>
                //   <CircularProgress />
                // </div>
                <LoadingScreen />
              ) : (
                // Render the StudentInfo component when loading is false
                <StudentInfo r={r} cha={cha} ss={sd} langN={langN} majorError={majorError}
                  setMajorError={setMajorError} />
              )}
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {checkboxerror}
              </span>
            </div>
            <div className="col-lg-12 col-md-12">
              <div
                className="form-group"
                style={{ display: "flex", justifyContent: "end" }}
              >

                <button onClick={(e) => navigate("/student-information")} className="loginBtn">
                  Back
                </button>
                {
                  read.g === null ? (<button type="submit" className="loginBtn">{translation("Studentinfo.stuedubtn")}</button>) : (<button type="submit" className="loginBtn">{translation("Studentinfo.stuedubtn2")}</button>)
                }
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EducationalInformation;