import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import ThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import BlackThemeLogo from "../../../assets/Images/BrandLogo/TheExamMateLogo.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
// for creating buttons

export default function PrimarySearchAppBar() {
  var [date, setDate] = useState(new Date());

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds + 1);
      if (seconds === 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);

    return () => clearInterval(timer);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light  p-3" id="mainnav">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <Navbar.Brand href="/home">
            <img
              // style={{width:170}}
              src={ThemeLogo}
              alt="themeLogo"
              className="img-fluid mx-auto WLogo"
              id="logomain"
            />
            <img
              //  style={{width:170}}
              src={BlackThemeLogo}
              alt="themeLogo"
              className="img-fluid mx-auto BLogo"
              id="logomain"
            />
          </Navbar.Brand>
        </a>

        <div className=" collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto">

          
          </ul>
          <ul className="navbar-nav ms-auto d-none d-lg-inline-flex">
            <Box sx={{ display: { md: "flex" } }}>
              <h1 style={{ color: "black", fontSize: 18 }}>
                Timer {minutes}:{seconds}{" "}
              </h1>
            </Box>
          </ul>
        </div>
      </div>
    </nav>

    //              <Navbar.Brand href="/home">
    //                 <img
    //                 // style={{width:170}}
    //                   src={ThemeLogo}
    //                   alt="themeLogo"
    //                   className="img-fluid mx-auto WLogo"
    //                   id="logomain"
    //                 />
    //                  <img
    //                 //  style={{width:170}}
    //                   src={BlackThemeLogo}
    //                   alt="themeLogo"
    //                   className="img-fluid mx-auto BLogo"
    //                   id="logomain"
    //                 />
    //               </Navbar.Brand>
    //               {/* <Box sx={{ flexGrow: 1 }} /> */}

    //      <Box sx={{}}>
    //          <Stack spacing={2} sx={{display: 'flex', alignItems: 'center' }}>
    //   <Pagination count={10} variant="outlined" shape="rounded"  size='large' sx={{ display: { xs: 'none', md: 'flex' } }} />
    // </Stack>
    // </Box>
    //       {/* <Box sx={{ flexGrow: 1 }} /> */}
    //       <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

    //         <h1 style={{color:'black',fontSize:18}}>Timer  {minutes}:{seconds}  </h1>
    //       </Box>
    //       {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
    //         <IconButton
    //           size="large"
    //           aria-label="show more"
    //           aria-controls={mobileMenuId}
    //           aria-haspopup="true"
    //           onClick={handleMobileMenuOpen}
    //           color="inherit"
    //         >
    //           <MoreIcon />
    //         </IconButton>
    //       </Box> */}
    //     </Toolbar>
    //   </AppBar>
    //   {renderMobileMenu}
    //   {renderMenu}
    // </Box>
  );
}
