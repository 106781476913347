import React, { useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/AboutUs.css";
import "../../../theme/css/Contactus.css";
import AboutUsVideo from "../AboutUs/AboutUsVideo";
import aboutus from "../../../assets/Images/AboutUs/aboutus.jpg";
import aboutusImg1 from "../../../assets/Images/AboutUs/aboutusImg1.jpg";
import aboutusImg3 from "../../../assets/Images/AboutUs/aboutusImg3.jpg";
import aboutusImg4 from "../../../assets/Images/AboutUs/aboutusImg4.jpg";
import OurTeam from "../HomePage/OurTeam";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import Brandlogo from "../HomePage/Brandlogo";
import "aos/dist/aos.css";
import OurPricingData from "../HomePage/OurPricingData";
import { useTranslation } from "react-i18next";
import { useTokenNavigation } from "../Usetoken/useTokenNavigation";

function SubsPricing() {


  const navigate = useNavigate()
    
  const [translation,i18n] = useTranslation("global");
  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const settings = {
    dots: false,
    aroow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
    <div className="ourPricingCard ">
            <Row>
           
                <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                  <div className="pricingCard d-none" data-aos="flip-left">
                    <div
                      className="pricingColorBox text-center"
                    //   key={pricing.id}
                    >
                      <h5> Free Plan</h5>
                      <h2>
                      ₹0
                        <span></span>
                      </h2>
                      <h2></h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>Self Asesement </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> contain2 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>contain3 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> contain4 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>contain5 </span>
                        </li>
                        <li>
                        </li>
                      </ul>
                      <div className="text-center">
                        <button className="button"  onClick={() => navigate("/home")}>
                          <Link> Get Started </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                  <div className="pricingCard" data-aos="flip-left">
                    <div
                      className="pricingColorBox text-center pricingColorBox-color"
                    //   key={pricing.id}
                    >
                      <h5>   {translation("HomePricing.subpricetitle")}</h5>
                      <h3>
                      ₹0
                        <span></span>
                      </h3>
                      <h2></h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {translation("HomePricing.subpricecontain1")}</span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>  {translation("HomePricing.subpricecontain2")} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {translation("HomePricing.subpricecontain3")} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>  {translation("HomePricing.subpricecontain4")} </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> {translation("HomePricing.subpricecontain5")} </span>
                        </li>
                        <li>
                        </li>
                      </ul>
                      <div className="text-center">
                        {/* <button className="button"  onClick={() => handleNavigation("/home")}> */}
                        <button className="button"   onClick={() =>navigate("/exam-room")}>
                       
                          Get Started 
                        </button>
                      </div>
                    </div>
                  </div>
                </Col> <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                  <div className="pricingCard d-none" data-aos="flip-left">
                    <div
                      className="pricingColorBox text-center"
                    //   key={pricing.id}
                    >
                      <h5>title</h5>
                      <h3>
                      newPrice
                        <span>oldPrice</span>
                      </h3>
                      <h2>subTitle</h2>
                    </div>
                    <div className="pricingSpacingBox">
                      <ul className="list-unstyled pricingBoxList">
                        <li>
                          <i className="fa fa-check"></i>
                          <span>contain1 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> contain2 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>contain3 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span> contain4 </span>
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          <span>contain5 </span>
                        </li>
                        <li>
                        </li>
                      </ul>
                      <div className="text-center">
                        {/* <button className="button"  onClick={() => handleNavigation("/home")}> */}
                        <button className="button"  onClick={() =>navigate("/exam-room")}>
                          {/* <Link to="/exam-room"> Get Started </Link> */}
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
            
            </Row>
          </div>
    </div>
  )
}

export default SubsPricing