import axios from "axios";

const axiosInstance2 = axios.create({
    baseURL: window.BASE2,
});

axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token');
        // console.log(token)
        if(token){
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance2;