import  { useEffect } from 'react';

const BeforeUnloadComponent = () => {

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // This line is required for Chrome compatibility
    };

    const handlePageNavigation = () => {
      // You can use window.location to navigate to another page.
      // For example, navigate to '/another-page':
    
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handlePageNavigation(); // Navigate to another page when the component is unmounted.
    };
  }, []);

  return null;
};

export default BeforeUnloadComponent;
