
import * as yup from "yup";

const pattern = /^[0]?[6789]\d{9}$/;
const regexPassword =
  /^(?=.?[A-Z])(?=.?[a-z])(?=.?[0-9])(?=.?[#?!@$%^&*-]).{8,}$/;
const Emailpattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const letters = /^[A-Za-z]+$/;

const mobile = /^[0]?[6789]\d{9}$/;
const numbersOnlyRegex = /^[0-9]+$/;
const tenDigitNumberRegex = /^\d{10}$/;
const alphabetOnlyRegex =/^[A-Za-z\s]+$/;
const passwordNoSpacesRegex = /^\S+$/;

let pinregex = /^(\d{4}|\d{6})$/;

export const Login = yup.object().shape({
  username: yup
    .string()
    .required("Mobile is Required").matches(numbersOnlyRegex,"Please Enter Valid Number")
    .matches(pattern, "Please enter 10 digit mobile number"),
  password: yup.string().required("Password is Required"),
});

export const SignUp = yup.object().shape({
  Name: yup
    .string()
    .transform((value) => value.trim()) // Add trim() here to remove white spaces
    .required("Username is Required")
    .matches(letters, "Please Enter alphabet characters only")
    .min(3)
    .max(40),
  MobileNo: yup
    .string()
    .required("Mobile is is Required")
    .matches(pattern, "Please enter 10 digit mobile number "),
  password: yup
    .string()
    .required("Password is Required")
    .min(6, "minimum 6 characters required"),
  EmailId: yup.string().notRequired().matches(Emailpattern, "Invalid email"),
});
export const ProfileValidation = yup.object().shape({
  Name: yup
    .string()
    .required("Username is Required")
    .matches(letters, "Please Enter alphabet characters only")
    .min(3)
    .max(40),
  EmailId: yup.string().matches(Emailpattern, "Invalid email"),
  MobileNo: yup
    .string()
    .matches(pattern, "Please enter a 10-digit mobile number "),
  parentname: yup
    .string()
    .required("Parent's name is a required field.")
    .min(3, "Parent's name should be at least 3 characters."),
  selectstate: yup.string().required("Please select a state."),
  selectdistrict: yup.string().required("Please select a district."),
  selecttaluk: yup.string().required("Please select a taluk."),
  selectaddress: yup.string().required("Please enter an address."),
  selectcity: yup.string().required("Please enter a city name."),
  pincode: yup
    .string()
    .required("Please enter a pincode.")
    .required(pinregex, "Invalid pincode"),
});


// changes 
export const registerValidation = yup.object().shape({
  name: yup
    .string()
    .required("Username is Required")
    .matches(alphabetOnlyRegex, "Please Enter alphabet characters only")
    .min(3,"Username Minimum 3 characters Required")
    .max(40,"Username Maximum 40 characters Required"),
  email: yup.string().email("Invalid email"),
  mobile: yup
    .string().required("Mobile Number is Required")
    .matches(mobile, "Mobile Number is Invalid").min(10, "Please enter a 10-digit mobile number "),
  password: yup
    .string()
    .required("Please Enter Password").matches(passwordNoSpacesRegex,"Password should not contain empty spaces")
    .min(6, "Password Minimum 6 characters Required"),

});
